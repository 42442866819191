.CardTransferencia {
  position: relative;
  padding: 30px;
  margin: 15px 15px;
  border-radius: 20px;
  background: var(--azul-st);
  background-image: url("../../../img/Transferencia.png");
  background-repeat: no-repeat;
  background-size: auto 50%;
  background-position: right bottom;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 5px 60px 0px;
  z-index: 1;
}

.CardTransferencia span {
  color: #fff;
  font: 1.2rem var(--fontSt);
  font-weight: 500;
}

.CardTransferencia p {
  color: #fff;
  font: 2rem var(--fontSt);
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.CardTransferencia div {
  margin: 0 0 10px 0;
}

.CardTransferencia div:last-child {
  margin: 0;
}

.NTransfer {
  color: var(--amarillo) !important;
  font: 2.6rem var(--fontSt) !important;
  font-weight: 700 !important;
  text-transform: uppercase;
}

.FTransfer {
  text-transform: capitalize;
}

.CardBody {
  position: relative;
  padding: 30px;
  margin: 15px 15px;
  border-radius: 20px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 5px 60px 0px;
  z-index: 1;
}

.CardInput {
  padding: 30px 15px !important;
}

.CardInput h3 {
  padding: 0 0 0 15px !important;
}

.CardBody h3 {
  color: var(--azul-st);
  font: 2rem var(--fontSt) !important;
  font-weight: 700 !important;
  margin: 0 0 15px 0;
  padding: 0;
}

.DatosTrans {
  margin-top: 30px;
}

.DatosTrans a {
  color: var(--negro);
  font: 1.7rem var(--fontSt) !important;
  font-weight: 700 !important;
}

.DatosTrans a:hover {
  color: var(--azul-lg);
}

.DatosTrans div {
  margin-bottom: 20px;
}

.DatosTrans label {
  display: block;
  font: 1.3rem var(--fontSt) !important;
  font-weight: 500 !important;
  margin-bottom: 3px;
  color: var(--gris);
}

.DatosTrans p {
  color: var(--negro);
  font: 1.6rem var(--fontSt);
  font-weight: 400;
}

.Observaciones p {
  color: var(--negro);
  font: 1.6rem var(--fontSt);
  font-weight: 400;
}

.especialEmail {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.TransDocuments {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}

.TransDocuments a {
  width: calc(50% - 20px);
  height: 100px;
  padding: 20px;
  margin: 5px 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: var(--gris-lg);
  color: var(--gris);
  font: 1.4rem var(--fontSt);
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.TransDocuments a:hover {
  background: var(--azul-cl);
  color: var(--azul-lg);
}

.TransDocuments a:hover img {
  filter: invert(14%) sepia(66%) saturate(7491%) hue-rotate(216deg)
    brightness(106%) contrast(98%);
  opacity: 1;
}

.TransDocuments a img {
  width: 30px;
  margin: 5px 15px 0 15px;
  opacity: 0.5;
}

.ResumenTrans {
  margin-top: 30px;
}

.ResumenTrans div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.ResumenTrans div label {
  font: 1.3rem var(--fontSt) !important;
  font-weight: 500 !important;
  margin-bottom: 3px;
  color: var(--gris);
}
.ResumenTrans div span {
  font: 1.6rem var(--fontSt) !important;
  font-weight: 500 !important;
  margin-bottom: 3px;
  color: var(--negro);
}

.Registrada {
  color: var(--azul-st) !important;
  margin-right: 5px;
}

.Revision{
  color: #ffc400 !important;
  margin-right: 5px;
}

.Proceso {
  color: var(--azul-lg) !important;
  margin-right: 5px;
}

.Procesada {
  color: #0e9b2c !important;
  margin-right: 5px;
}
.Cancelada {
  color: #ff3131 !important;
  margin-right: 5px;
}

.LinkTransfer {
  padding: 10px;
  margin: 5px 0;
  border-radius: 10px;
  border-top: 1px solid var(--gris-lg);
  border-left: 1px solid var(--gris-lg);
  border-right: 1px solid var(--gris-lg);
  border-bottom: 1px solid var(--gris-lg);
  font: 1.6rem var(--fontSt);
  font-weight: 400;
  color: var(--negro);
}

.LinkTransfer label {
  font: 1.3rem var(--fontSt) !important;
  font-weight: 500 !important;
  color: var(--gris);
  margin-right: 5px;
}

.textoTrans {
  color: var(--negro);
  font: 1.4rem var(--fontSt);
  font-weight: 400;
  text-align: left;
  margin-bottom: 15px !important;
}

@media (min-width: 544px) {
  .TransDocuments a img {
    margin: 0px 0px 0 20px;
  }

  .CardTransferencia {
    background-size: auto 100%;
  }
}

@media (min-width: 768px) {
  .TransDocuments a {
    width: calc(50% - 20px);
    height: 100%;
    padding: 20px;
    margin: 5px 10px;
    font: 1.4rem var(--fontSt);
    font-weight: 500;
  }

  .TransDocuments a img {
    width: 40px;
    margin: 10px 40px 0 40px;
  }
}

@media (min-width: 992px) {
  .TransDocuments a {
    width: calc(25% - 20px);
    padding: 20px;
    margin: 5px 10px;
    font: 1.4rem var(--fontSt);
    font-weight: 500;
  }

  .TransDocuments a img {
    width: 40px;
    margin: 10px 20px 0 20px;
  }
}

@media (min-width: 1200px) {
}

@media (min-width: 1370px) {
  .TransDocuments a {
    width: calc(25% - 20px);
    height: 100%;
    margin: 0 10px;
    font: 1.4rem var(--fontSt);
    font-weight: 500;
  }

  .TransDocuments a:first-child {
    margin: 0 10px 0 0 !important;
  }

  .TransDocuments a img {
    margin: 10px 25px 0 25px;
  }
}
