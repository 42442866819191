.ContDash {
  position: relative;
  width: 100%;
  min-height: 70vh;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.ContentDash {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  min-height: 70vh;
  padding: 80px 5px 50px 5px;
}

.ContPaginas {
  width: 100%;
  padding: 60px 0px 0px 0px;
}

.BlockScroll {
  pointer-events: none;
}

.MenuDash {
  position: fixed;
  background: #fff;
  height: 60px;
  width: 100%;
  z-index: 999;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.MenuDashCont {
  max-width: 1200px;
  padding: 0 15px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*------SALUDO----*/

.Saludo {
  padding-left: 20px;
  margin-bottom: 20px;
}

.Saludo h1 {
  margin: 0px;
  color: var(--negro);
  font: 2.8rem var(--fontSt);
  font-weight: 700;
  line-height: 2rem;
}

.Saludo span {
  font: 1.6rem var(--fontSt);
}

.Saludo p {
  margin: 20px 0px 10px 0px;
  color: var(--gris);
  font: 1.4rem var(--fontSt);
}

.dashboardCard {
  position: relative;
  padding: 20px;
  margin: 15px 15px;
  border-radius: 20px;
  background: var(--blanco);
  box-shadow: rgba(0, 0, 0, 0.14) 0px 5px 60px 0px;
  z-index: 1;
}

.CardTitulo {
  font: 1.6rem var(--fontSt);
  font-weight: 700;
  color: var(--gris);
  margin-bottom: 15px;
}

.TituloPag {
  margin: 15px 15px 30px 15px;
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.TituloPag p {
  font: 2.8rem var(--fontSt);
  color: var(--azul-st);
  font-weight: 700;
  line-height: 0;
}

.TituloPag a {
  background-color: transparent;
  height: 100%;
  align-items: center;
  margin-right: 10px;
}

.TituloPag a img {
  height: 30px;
}

/*REGISTROS VACIOS */

.itemTransferEmpty {
  margin: 0 auto;
  width: 100%;
  padding: 30px 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: var(--gris-lg);
  border: 2px dotted var(--gris);
  border-radius: 8px;
}

.itemTransferEmpty p {
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
  font: 1.6rem var(--fontSt);
  font-weight: 700;
}

.itemTransferEmpty span {
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
  font: 1.4rem var(--fontSt);
  font-weight: 400;
}

@media (min-width: 576px) {
  .ContentDash {
    padding: 80px 15px 20px 15px;
  }
}
@media (min-width: 768px) {
  .ContentDash {
    padding: 90px 20px 80px 20px;
  }
}
@media (min-width: 992px) {
  .MenuDash {
    height: 80px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .ContentDash {
    padding: 140px 30px 80px 30px;
  }

  .Saludo {
    padding-left: 10px;
  }

  .ContPaginas {
    width: 100%;
    padding: 80px 0px 0px 0px;
  }
}

@media (min-width: 1200px) {
  .MenuDash {
    height: 90px;
  }
  .ContentDash {
    padding: 150px 5px 50px 5px;
  }

  .ContPaginas {
    padding: 90px 0px 0px 0px;
  }
}

@media (min-width: 1370px) {
  .MenuDash {
    height: 100px;
  }

  .ContentDash {
    padding: 160px 0px 50px 0px;
  }

  .ContPaginas {
    padding: 100px 0px 0px 0px;
  }
}
