:root {
  --azul-st: #0018b4;
  --azul-lg: #035af4;
  --azul-cl: #e8f7ff;
  --blanco: #fff;
  --amarillo: #ffec00;
  --negro: #141414;
  --gris: #929bac;
  --gris-lg: #ececec;
  --gris-m: #b4b4b4;
  --fontSt: "Barlow", sans-serif;
  --red: #d63830;
  --red-cl: #fdeeee;
  --verde: #1bb63f;
}

/* ------ BOTONES ---------*/
.boton-general {
  padding: 22px 20px;
  margin: 10px 0px;
  border-radius: 8px;
  background: var(--azul-st);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 6px 30px -10px rgba(64, 95, 244, 1);
  color: #fff;
  font: 1.5rem var(--fontSt), sans-serif;
  font-weight: 400;
  line-height: 0;
  white-space: nowrap;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

#BtnGray {
  background: var(--gris-lg) !important;
  color: var(--gris);
  box-shadow: none !important;
  cursor: not-allowed;
}

#BtnGray img {
  filter: invert(0.8);
}

.BtnLarge {
  width: 100% !important;
}

.boton-general:hover {
  background: var(--azul-lg);
}

.boton-general img {
  filter: invert(1);
  width: 18px;
  height: 18px;
  margin: -10px -5px;
}

.imgButton-left img {
  margin-right: 3px !important;
}

.imgButton-rigth img {
  margin-left: 3px !important;
}

.boton-agregar {
  width: calc(100% - 20px);
  padding: 10px 25px;
  margin: 10px 10px;
  border-radius: 30px;
  background-color: #000629;
  color: #fff;
  font: 1.5rem var(--fontSt);
  font-weight: 300;
}

.botones-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.text-buton {
  text-decoration: none;
  color: var(--azul-st);
  background-color: transparent;
  padding: 15px 15px;
  display: block;
  font: 1.8rem var(--fontSt);
}

.btn-text {
  margin: 0 auto;
  width: 100%;
  padding: 10px;
  background: transparent;
  color: var(--gris);
  text-align: center;
  font: 1.4rem var(--fontSt);
  font-weight: 500;
}

.btn-text:hover {
  color: var(--negro);
}

.BtnText {
  text-decoration: none;
  color: var(--azul-st);
  background-color: transparent;
  padding: 15px 15px;
  display: block;
  font: 1.8rem var(--fontSt);
}

/*INPUTS GENERALES*/

/*DISABLED*/
.input-disabled input,
.input-disabled textarea {
  height: 40px;
  width: 100%;
  padding: 6px 12px 6px 12px;
  border-radius: 5px;
  background: #f7f7f7;
  color: #1a1a1a !important;
  border: solid 1px var(--gris-lg);
  box-shadow: none;
  font: 1.6rem var(--fontSt);
  font-weight: 400;
}

.input-disabled label {
  position: absolute;
  top: 20px;
  left: -3px;
  margin-left: 10px;
  padding: 0px 6px;
  background: #f7f7f7;
  border-radius: 3px 3px 0px 0px;
  -webkit-transform: translate3d(0, -18px, 0);
  transform: translate3d(0, -18px, 0);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: var(--gris);
  font: 1.3rem var(--fontSt);
  font-weight: 600;
  pointer-events: none;
  z-index: 2;
}

.input-disabled input:not(:placeholder-shown) + label,
.input-disabled textarea:not(:placeholder-shown) + label {
  color: var(--azul-lg);
}

.inpDisNegative input{
  background: var(--red-cl) !important;
  border: solid 1px var(--red) !important;
  color: var(--red) !important;
}

.inpDisNegative label{
  color: var(--red) !important;
  background: var(--red-cl) !important;
}

.input-select {
  position: relative;
  padding: 10px 0px;
  margin: 5px 0px;
}

.input-disabled,
.input-general,
.input-date {
  position: relative;
  padding: 10px 0px;
  margin: 5px 0px;
  overflow: hidden;
}

.input-fix {
  position: relative;
  padding: 10px 0px;
  margin: 5px 0px;
}

.input-general input,
.input-date input,
.input-general textarea {
  height: 40px;
  width: 100%;
  padding: 6px 12px 6px 12px;
  border-radius: 5px;
  background: #ffffff;
  color: var(--negro);
  border: solid 1px var(--gris-lg);
  box-shadow: none;
  font: 1.6rem var(--fontSt);
  font-weight: 400;
}

.input-general textarea {
  padding: 10px 12px 6px 12px !important;
  resize: none;
}

.input-general:hover input,
.input-date:hover input,
.input-general:hover textarea {
  border: solid 1px var(--azul-lg) !important;
}

.input-disabled input:focus,
.input-general input:focus,
.input-date input:focus,
.input-general textarea:focus {
  outline: none;
  border: solid 1px var(--azul-lg);
  box-shadow: none;
}

.input-date:hover label,
.input-date input:focus + label {
  color: var(--azul-lg) !important;
}

.input-general label {
  position: absolute;
  top: 20px;
  left: 5px;
  margin-left: 10px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #909090;
  font: 1.6rem var(--fontSt);
  font-weight: 400;
  pointer-events: none;
  z-index: 2;
}

.input-general input:focus + label,
.input-general textarea:focus + label {
  left: -3px;
  padding: 0px 6px;
  -webkit-transform: translate3d(0, -18px, 0);
  transform: translate3d(0, -18px, 0);
  background: #fff;
  color: var(--azul-lg);
  font: 1.3rem var(--fontSt);
  font-weight: 600;
}

.input-img {
  position: absolute;
  height: 20px;
  width: 20px;
  top: 20px;
  right: 15px;
  opacity: 0.3;
  pointer-events: none;
  z-index: 1;
}

.input-date::after {
  content: "";
  background: url("../img/icons/calendar.svg");
  background-position: center center;
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  height: 20px;
  width: 20px;
  top: 20px;
  right: 15px;
  opacity: 0.3;
  cursor: pointer;
  pointer-events: none;
  z-index: 1;
}

.input-date::before {
  content: "";
  position: absolute;
  top: 18px;
  right: 12px;
  height: 25px;
  width: 25px;
  background-color: #fff;
  pointer-events: none;
}

.input-date label {
  position: absolute;
  top: 20px;
  left: -3px;
  margin-left: 10px;
  padding: 0px 6px;
  -webkit-transform: translate3d(0, -18px, 0);
  transform: translate3d(0, -18px, 0);
  background: #fff;
  color: #c7c7c7;
  font: 1.3rem var(--fontSt);
  font-weight: 600;
  pointer-events: none;
  z-index: 2;
}

.ligthBlue {
  color: var(--azul-lg) !important;
}

.BorderBlue {
  border: solid 1px var(--azul-lg) !important;
}

.input-general input:not(:placeholder-shown) + label,
.input-general textarea:not(:placeholder-shown) + label {
  left: -3px;
  padding: 0px 6px;
  -webkit-transform: translate3d(0, -18px, 0);
  transform: translate3d(0, -18px, 0);
  background: #fff;
  color: var(--azul-lg);
  font: 1.3rem var(--fontSt);
  font-weight: 600;
}

.input-general input:not(:placeholder-shown),
.input-general textarea:not(:placeholder-shown) {
  outline: none;
  box-shadow: none !important;
  border: solid 1px var(--azul-lg) !important;
  background: transparent !important;
}

.input-general-disabled {
  position: relative;
  padding: 10px 10px;
  overflow: hidden;
}

.input-general-disabled input:disabled {
  height: 40px;
  width: 100%;
  padding: 6px 12px 6px 12px;
  border-radius: 5px;
  box-shadow: none;
  background: #f4f4f4;
  border: solid 1px #f4f4f4;
  color: #777777;
  font: 1.5rem "Inter";
  font-weight: 400;
}

.input-button {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}
.input-button input {
  height: 40px;
  width: 100%;
  padding: 15px 10px;
  border-radius: 5px 0px 0px 5px;
  border: solid 1px #dedede;
  box-shadow: none;
  color: #1a1a1a;
  font: 14px var(--fontSt);
  font-weight: 400;
}

.input-button input:focus {
  outline: none;
  box-shadow: none;
  border: solid 1px var(--azul-lg);
}

.input-button button {
  width: 100px;
  padding: 10px 10px;
  border-radius: 0px 5px 5px 0px;
  background: #bababa;
  color: #ffffff;
  font: 15px var(--fontSt);
}

.btn-desactivado {
  background: #bababa !important;
}

.validacionA {
  position: absolute;
  bottom: -6px;
  left: 10px;
  padding: 0px 5px;
  border-radius: 5px;
  background: #ea2a34;
  color: #fff;
  font: 11px var(--fontSt);
  display: none;
}
.validacionB {
  position: absolute;
  bottom: -6px;
  left: 10px;
  padding: 0px 5px;
  border-radius: 5px;
  background: #62b043;
  color: #fff;
  font: 11px var(--fontSt);
  display: none;
}

.input-label:disabled {
  height: 40px;
  width: 100%;
  padding: 6px 12px 6px 12px;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: none;
  background: #f4f4f4;
  border: solid 1px #f4f4f4;
  color: #777777;
  font: 15px var(--fontSt);
  font-weight: 400;
}

/*----CHECK BOX DE FORMULARIOS */

.CheckBoxs {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.NewCheckbox {
  position: relative;
  width: 50%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.NewCheckbox:last-child {
  margin-right: 0;
}

.NewCheckbox label {
  font: 1.4rem var(--fontSt);
}

.NewCheckbox input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin: 0px 10px 0px 10px !important;
  opacity: 0;
  cursor: pointer;
}

.NewCheckbox input + label::before {
  position: absolute;
  top: 0;
  left: 0;
  content: url(../img/icons/check.svg);
  width: 20px;
  height: 20px;
  border: 2px solid #ececec;
  background: #fff;
  border-radius: 20%;
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
}

.NewCheckbox input:hover + label::before {
  position: absolute;
  top: 0;
  left: 0;
  content: url(../img/icons/check.svg);
  width: 20px;
  height: 20px;
  border: 2px solid var(--azul-lg);
  background: #fff;
  border-radius: 20%;
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.NewCheckbox input[type="checkbox"]:checked + label::before {
  position: absolute;
  top: 0;
  left: 0;
  content: url(../img/icons/check.svg);
  width: 20px;
  height: 20px;
  background: var(--azul-lg);
  border: 2px solid var(--azul-lg);
  border-radius: 20%;
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
  transform: scale(1);
  cursor: pointer;
}

.checkboxContent{
  display: flex;
  width: 100px;
}

.divPep{
  position: relative;
  font: 1.4rem var(--fontSt);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border: solid 1px var(--gris-lg);
  border-radius: 5px;
  padding: 10px;
}

.divPepWarn{
  position: relative;
  font: 1.4rem var(--fontSt);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border: solid 1px var(--red);
  border-radius: 5px;
  padding: 10px;
}

.divPep span, .divPepWarn span{
  left: 50% !important;
  transform: translateX(-50%);
  bottom: -5px !important;
}

.checkbox {
  position: relative;
  width: 100%;
  margin: 15px 0px;
  font: 1.4rem var(--fontSt);
}

.checkbox input[type="checkbox"], 
.checkbox input[type="radio"]  {
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  margin: 0px 10px 0px 10px !important;
  opacity: 0;
  cursor: pointer;
}

.checkboxlabel {
  padding: 0 10px 0 40px;
  margin-bottom: 30px;
}

.checkboxBtm{
  margin-bottom: 0 !important;
}

.checkbox label {
  position: relative;
  pointer-events: none;
}

.checkbox input + label::before {
  position: absolute;
  top: 0;
  left: 0;
  content: url(../img/icons/check.svg);
  width: 20px;
  height: 20px;
  border: 2px solid #ececec;
  background: #fff;
  border-radius: 20%;
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
}

.checkbox input:hover + label::before {
  position: absolute;
  top: 0;
  left: 0;
  content: url(../img/icons/check.svg);
  width: 20px;
  height: 20px;
  border: 2px solid var(--azul-lg);
  background: #fff;
  border-radius: 20%;
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
}

.checkbox input[type="checkbox"]:checked + label::before, 
.checkbox input[type="radio"]:checked + label::before{
  position: absolute;
  top: 0;
  left: 0;
  content: url(../img/icons/check.svg);
  width: 20px;
  height: 20px;
  background: var(--azul-lg);
  border: 2px solid var(--azul-lg);
  border-radius: 20%;
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
  transform: scale(1);
  pointer-events: none;
}

.checkbox a {
  color: var(--azul-lg);
  background: transparent;
  font: 1.4rem var(--fontSt);
  font-weight: 600;
  padding: 0 !important;
  cursor: pointer;
}

.spanAlert {
  position: absolute;
  bottom: 0px;
  left: 10px;
  padding: 2px 5px;
  border-radius: 3px;
  background: #fdeeee;
  color: #d63830;
  font: 1.1rem var(--fontSt);
  font-weight: 500;
  z-index: 1;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.spanAlertCard {
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 2px 5px;
  border-radius: 3px;
  background: #fdeeee;
  color: #d63830;
  font: 1.1rem var(--fontSt);
  font-weight: 500;
  white-space: nowrap;
  z-index: 1;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.spanAlertCheck {
  display: block;
  text-align: center;
  padding: 2px 5px;
  border-radius: 3px;
  background: #fdeeee;
  color: #d63830;
  font: 1.1rem var(--fontSt);
  font-weight: 500;
  z-index: 1;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.redWarn {
  border: solid 1px #d63830 !important;
}

.redLineDi label {
  color: #d63830 !important;
}

.redLine input,
.redLine textarea {
  border: solid 1px #d63830 !important;
}

.redLine input:not(:placeholder-shown),
.redLine textarea:not(:placeholder-shown) {
  border: solid 1px #d63830 !important;
}

.redLine input:not(:placeholder-shown) + label,
.redLine textarea:not(:placeholder-shown) + label {
  color: #d63830 !important;
}

.handClick {
  cursor: pointer;
}



@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .CheckBoxs {
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .NewCheckbox {
    position: relative;
    width: auto;
    margin-right: 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .spanAlertCard {
    bottom: -7px;
  }
}

@media (min-width: 1200px) {
}
