.newHeader {
  height: 60px;
  display: flex;
  align-items: center;
}

.header-login {
  position: absolute;
  right: 0;
  height: 100px;
  z-index: 99;
  background: transparent;
}

.navbarVi {
  margin-top: 0px;
  padding: 0 0px;
  height: 60px;
  display: flex;
  align-items: center;
}

.navbarVi a {
  font: 1.4rem var(--fontSt);
  font-weight: 400;
  color: #fff;
  margin: 0px 20px;
  cursor: pointer;
}

.navbarVi a:hover {
  color: var(--azul-st);
}

.MainMenu {
  display: none;
}

.homeActive {
  font-weight: 900;
  color: var(--azul-st) !important;
}

.logoIndex {
  margin: 0px !important;
}

.logoIndex img {
  height: 40px;
  width: 40px;
}

.a-button {
  width: 100%;
  padding: 15px 25px;
  margin: 10px 0px;
  border-radius: 5px;
  background: var(--azul-st);
  box-shadow: 0 6px 30px -10px rgba(64, 95, 244, 1);
  color: #fff !important;
  font: 1.5rem var(--fontSt);
  font-weight: 500 !important;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.a-button:hover {
  background: var(--azul-lg);
}

.buttonIngresar {
  font: 1.6rem var(--fontSt);
  font-weight: 400;
  color: var(--negro);
  margin: 0px 20px;
}

.buttonIngresar:hover {
  color: var(--azul-st) !important;
}

.singFlex {
  display: flex;
  align-items: center;
}

#desktop-plus {
  display: none;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .newHeader {
    position: relative;
    height: 100px;
  }

  .header-login {
    position: absolute;
    right: 0;
    height: 100px;
    z-index: 99;
    background: transparent;
  }

  .navbarVi {
    height: 80px;
  }

  .MainMenu {
    display: block;
  }

  .MainMenu a {
    font: 1.6rem var(--fontSt);
    font-weight: 400;
    color: var(--negro);
    margin: 0px 20px;
  }

  .MainMenu a:hover {
    color: var(--azul-st);
  }

  .homeActive {
    font: 1.6rem var(--fontSt);
    font-weight: 700 !important;
    color: var(--azul-st) !important;
  }

  #desktop-plus {
    display: block;
  }

  .logoIndex {
    margin: 0px !important;
  }

  .logoIndex img {
    height: 60px;
    width: 60px;
  }
}
@media (min-width: 1200px) {
}
