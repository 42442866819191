@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@200;400;500;700;900&display=swap);
.input-divisaDi,
.input-divisa {
  position: relative;
  padding: 10px 0px;
  margin: 5px 0px;
  overflow: hidden;
}

.input-divisa input {
  height: 40px;
  width: 100%;
  padding: 6px 12px 6px 12px;
  border-radius: 5px;
  background: #ffffff;
  color: var(--negro);
  border: solid 1px var(--gris-lg);
  box-shadow: none;
  font: 1.6rem var(--fontSt);
  font-weight: 400;
}

.input-divisa:hover input {
  border: solid 1px var(--azul-lg) !important;
}

.input-divisa label {
  position: absolute;
  top: 20px;
  left: -3px;
  margin-left: 10px;
  padding: 0px 6px;
  transform: translate3d(0, -18px, 0);
  transition: all 0.2s ease-in-out;
  background: #fff;
  color: var(--azul-lg);
  font: 1.3rem var(--fontSt);
  font-weight: 600;
  pointer-events: none;
  z-index: 2;
}

.input-divisa input:not(:-ms-input-placeholder) {
  outline: none;
  box-shadow: none !important;
  border: solid 1px var(--azul-lg) !important;
  background: transparent !important;
}

.input-divisa input:not(:placeholder-shown) {
  outline: none;
  box-shadow: none !important;
  border: solid 1px var(--azul-lg) !important;
  background: transparent !important;
}

.input-divisaDi input {
  height: 40px;
  width: 100%;
  padding: 6px 12px 6px 12px;
  border-radius: 5px;
  background: #f7f7f7;
  color: #4d4d4d;
  border: solid 1px var(--gris-lg);
  box-shadow: none;
  font: 1.6rem var(--fontSt);
  font-weight: 400;
}

.input-divisaDi label {
  position: absolute;
  top: 20px;
  left: -3px;
  margin-left: 10px;
  padding: 0px 6px;
  background: #f7f7f7;
  border-radius: 3px 3px 0px 0px;
  transform: translate3d(0, -18px, 0);
  transition: all 0.2s ease-in-out;
  color: var(--azul-lg);
  font: 1.3rem var(--fontSt);
  font-weight: 600;
  pointer-events: none;
  z-index: 2;
}

.input-divisaDi input:focus,
.input-divisa input:focus {
  outline: none;
  border: solid 1px var(--azul-lg);
  box-shadow: none;
}

.valorCambio {
  margin: 10px 0px 20px;
  color: var(--negro);
  font: 1.6rem var(--fontSt), sans-serif;
  font-weight: 400;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.valorCambio img {
  height: 20px;
  margin-right: 10px;
}

.CountryImg {
  position: absolute;
  top: 15px;
  right: 15px;
  height: 30px;
}

.cambio-divisas {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.cambioDivisas {
  width: 100%;
  max-width: 1000px;
  margin-right: 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.cambioReverse {
  width: 100%;
  max-width: 1000px;
  margin-right: 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column-reverse;
}

.marginZero {
  margin-right: 0px !important;
}

.reverseButton {
  width: 100%;
  margin: 0 0 5px 0;
}

.reverseButton button {
  background: var(--azul-cl);
  width: 100%;
  height: 40px;
  border: 1px solid var(--azul-lg);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reverseButton img {
  -webkit-filter: invert(14%) sepia(66%) saturate(7491%) hue-rotate(216deg)
    brightness(106%) contrast(98%);
          filter: invert(14%) sepia(66%) saturate(7491%) hue-rotate(216deg)
    brightness(106%) contrast(98%);
  transform: rotate(90deg);
}

.DivsaCalc {
  width: 100%;
}

.divisonWidth {
  width: 40px;
}

.DivisaDiv {
  width: 100%;
}

.noticeCovert {
  padding: 10px;
  border-radius: 8px;
  color: var(--azul-lg);
  background: var(--azul-cl);
  font: 1.3rem var(--fontSt), sans-serif;
  font-weight: 400;
  text-align: center;
}

.warning1{
  margin-top: 10px;
}

@media (min-width: 544px) {
  .cambioDivisas {
    flex-wrap: nowrap;
  }

  .cambioReverse {
    flex-wrap: nowrap;
    flex-direction: row-reverse;
  }

  .reverseButton {
    width: 40px;
    margin: 0 10px;
  }

  .reverseButton button {
    width: 40px;
  }

  .reverseButton img {
    transform: rotate(0deg);
  }
}

@media (min-width: 576px) {
  .valorCambio {
    margin: 10px 0px 20px;
    font: 1.6rem var(--fontSt), sans-serif;
    font-weight: 400;
  }
}

@media (min-width: 768px) {
  .cambio-divisas {
    align-items: center;
    flex-wrap: nowrap;
  }
  .cambioDivisas,
  .cambioReverse {
    margin-right: 15px;
  }

  .DivsaCalc {
    width: auto;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

.Selec {
  position: relative;
  padding: 0px;
  margin: 0 !important;
}

.Selec_select {
  position: relative;
  height: 40px;
  width: 100%;
  padding: 6px 12px 6px 12px;
  margin: 0 !important;
  border-radius: 5px;
  background: var(--blanco);
  border: solid 1px var(--gris-lg);
  font: 1.5rem var(--fontSt), sans-serif;
  font-weight: 400;
  line-height: 25px;
  cursor: pointer;
}

.Selec_select:hover {
  border: solid 1px var(--azul-lg);
}

.Selec_Open {
  border-radius: 5px 5px 0px 0px !important;
}

.Selec_blue {
  border: solid 1px var(--azul-lg);
}

.Label_in {
  position: absolute;
  top: 10px;
  left: 5px;
  margin-left: 10px;
  color: #909090;
  font: 1.5rem var(--fontSt), sans-serif;
  font-weight: 400;
  pointer-events: none;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease-in-out;
}

.Label_out {
  position: absolute;
  top: 9px;
  left: 6px;
  padding: 0px 6px;
  margin: 0 !important;
  transform: translate3d(0, -18px, 0);
  transition: all 0.2s ease-in-out;
  background: #fff;
  color: var(--azul-lg);
  font: 1.3rem var(--fontSt), sans-serif;
  font-weight: 600;
  z-index: 9;
}

.Selec_opciones {
  position: absolute;
  left: 0px;
  top: 39px;
  width: 100%;
  height: auto;
  max-height: 160px;
  margin: 0 !important;
  border-radius: 0px 0px 5px 5px;
  background-color: #fff;
  border-top: solid 1px #dedede;
  border-left: solid 1px var(--azul-lg);
  border-right: solid 1px var(--azul-lg);
  border-bottom: solid 1px var(--azul-lg);
  box-sizing: border-box;
  overflow-y: auto;
  z-index: 999;
}

.Selec_opciones::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
} 

.Selec_opciones::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(to left, #fff, #e4e4e4);
  border: 1px solid #aaa;
}

.Selec_opciones::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.Selec_opciones::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0,0,0,0.1);
  border: 1px solid #ccc;
}

.Selec_opciones::-webkit-scrollbar-thumb:active {
  background: linear-gradient(to left, #22ADD4, #1E98BA);
}

.Select_opcion {
  padding: 10px 12px;
  margin: 0;
  margin: 0 !important;
  border-bottom: solid 1px #dedede;
  font: 1.5rem var(--fontSt), sans-serif;
  box-sizing: border-box;
  cursor: pointer;
}

.Select_opcion:last-child {
  border-bottom: none;
}

.Select_opcion:hover {
  background-color: var(--azul-lg);
  color: #fff;
}

.arrowSelect {
  position: absolute;
  top: 10px;
  right: 14px;
  width: 20px;
  opacity: 0.3;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
}
.arrowRotate {
  transform: rotate(180deg);
}

.selectButton {
  padding: 5px 12px;
}

.selectButton p {
  font: 1.5rem var(--fontSt), sans-serif;
  text-align: center;
  margin-top: 5px;
}

.DobleSelect {
  position: relative;
  width: 100%;
  display: flex;
}

.DobleSelect .Selec {
  width: 40%;
}

.DobleSelect .Selec:last-child {
  width: 60%;
}

.DobleSelect .Selec .Selec_select {
  border-radius: 5px 0 0 5px !important;
  border-left: solid 1px var(--azul-lg) !important;
  border-top: solid 1px var(--azul-lg) !important;
  border-bottom: solid 1px var(--azul-lg) !important;
  border-right: none;
  font: 1.4rem var(--fontSt), sans-serif !important;
  line-height: 25px !important;
}

.DobleSelect .arrowSelect {
  top: 12px !important;
  right: 6px !important;
  width: 18px !important;
}

.DobleSelect .Selec .Selec_Open {
  border-radius: 5px 0 0 0px !important;
  border-right: solid 1px var(--gris) !important;
}

.DobleSelect .Selec:last-child .Selec_select {
  border-radius: 0 5px 5px 0 !important;
  border-right: solid 1px var(--azul-lg) !important;
  border-top: solid 1px var(--azul-lg) !important;
  border-bottom: solid 1px var(--azul-lg) !important;
}

.DobleSelect .Selec:last-child .Selec_Open {
  border-radius: 0 5px 0px 0 !important;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
  .DobleSelect .Selec .Selec_select {
    font: 1.5rem var(--fontSt), sans-serif !important;
    line-height: 25px !important;
  }
}

.newHeader {
  height: 60px;
  display: flex;
  align-items: center;
}

.header-login {
  position: absolute;
  right: 0;
  height: 100px;
  z-index: 99;
  background: transparent;
}

.navbarVi {
  margin-top: 0px;
  padding: 0 0px;
  height: 60px;
  display: flex;
  align-items: center;
}

.navbarVi a {
  font: 1.4rem var(--fontSt);
  font-weight: 400;
  color: #fff;
  margin: 0px 20px;
  cursor: pointer;
}

.navbarVi a:hover {
  color: var(--azul-st);
}

.MainMenu {
  display: none;
}

.homeActive {
  font-weight: 900;
  color: var(--azul-st) !important;
}

.logoIndex {
  margin: 0px !important;
}

.logoIndex img {
  height: 40px;
  width: 40px;
}

.a-button {
  width: 100%;
  padding: 15px 25px;
  margin: 10px 0px;
  border-radius: 5px;
  background: var(--azul-st);
  box-shadow: 0 6px 30px -10px rgba(64, 95, 244, 1);
  color: #fff !important;
  font: 1.5rem var(--fontSt);
  font-weight: 500 !important;
  transition: all 0.4s ease;
}

.a-button:hover {
  background: var(--azul-lg);
}

.buttonIngresar {
  font: 1.6rem var(--fontSt);
  font-weight: 400;
  color: var(--negro);
  margin: 0px 20px;
}

.buttonIngresar:hover {
  color: var(--azul-st) !important;
}

.singFlex {
  display: flex;
  align-items: center;
}

#desktop-plus {
  display: none;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .newHeader {
    position: relative;
    height: 100px;
  }

  .header-login {
    position: absolute;
    right: 0;
    height: 100px;
    z-index: 99;
    background: transparent;
  }

  .navbarVi {
    height: 80px;
  }

  .MainMenu {
    display: block;
  }

  .MainMenu a {
    font: 1.6rem var(--fontSt);
    font-weight: 400;
    color: var(--negro);
    margin: 0px 20px;
  }

  .MainMenu a:hover {
    color: var(--azul-st);
  }

  .homeActive {
    font: 1.6rem var(--fontSt);
    font-weight: 700 !important;
    color: var(--azul-st) !important;
  }

  #desktop-plus {
    display: block;
  }

  .logoIndex {
    margin: 0px !important;
  }

  .logoIndex img {
    height: 60px;
    width: 60px;
  }
}
@media (min-width: 1200px) {
}

.user-profile {
  position: relative;
  height: 60px;
  padding: 0 0 0 10px;
  display: flex;
  align-items: center;
}

.user-profile-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.photoName {
  display: flex;
  align-items: center;
}

.user-profile__photo {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-color: var(--gris-lg);
  overflow: hidden;
}

.user-profile__photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.user-profile-name {
  display: none;
  margin-left: 10px;
}

.user-profile-name p {
  font: 1.6rem var(--fontSt);
  font-weight: 600;
}

.user-profile-name span {
  font: 1.4rem var(--fontSt);
  font-weight: 300;
}

.boton-icono {
  display: none;
  background-color: transparent;
  padding: 10px;
}

.dropdown-perfil {
  position: absolute;
  top: 60px;
  left: 0;
  height: 0;
  display: flex;
  flex-wrap: wrap;
  background: var(--blanco);
  border-radius: 0px 0px 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 2px 7px 8px;
  overflow: hidden;
  transition: all 0.4s ease;
}

.dropdown-perfil a,
.dropdown-perfil button {
  width: 100%;
  padding: 10px 20px;
  height: 50px;
  margin: 0;
  display: flex;
  align-items: center;
  background: transparent;
  color: var(--negro);
  font: 1.5rem var(--fontSt);
  text-align: left;
}

.dropdown-perfil a img,
.dropdown-perfil button img {
  height: 20px;
  width: auto;
  margin-right: 10px;
  -webkit-filter: invert(0.6);
          filter: invert(0.6);
}

.dropdown-perfil a:hover,
.dropdown-perfil button:hover {
  color: var(--blanco);
  background: var(--azul-lg);
  transition: all 0.4s ease;
}

.dropdown-perfil a:hover img,
.dropdown-perfil button:hover img {
  -webkit-filter: invert(1) brightness(100%);
          filter: invert(1) brightness(100%);
  transition: all 0.4s ease;
}

.dropdown-active {
  height: auto !important;
  transition: all 0.4s ease;
  z-index: 999;
}

.dropLiA{
  color: var(--blanco) !important;
  background: var(--azul-lg) !important;
}

.dropLiA img{
  -webkit-filter: invert(1) brightness(100%) !important;
          filter: invert(1) brightness(100%) !important;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .user-profile-name {
    display: block;
  }

  .boton-icono {
    display: block;
  }

  .user-profile__photo {
    height: 45px;
    width: 45px;
  }

  .user-profile {
    height: 80px;
  }

  .dropdown-perfil {
    top: 80px;
  }
}

@media (min-width: 1200px) {
  .user-profile {
    height: 90px;
  }
  .dropdown-perfil {
    top: 90px;
  }
}

@media (min-width: 1370px) {
  .user-profile {
    height: 100px;
  }

  .dropdown-perfil {
    top: 100px;
  }
}

.MenuLateral {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.botonMenu {
  width: 50px;
  padding: 10px 0px 10px 15px;
  height: 100%;
}

.botonMenu img {
  height: 100%;
  width: 100%;
  opacity: 0.5;
}

.NoneMenu {
  display: none;
}

.MenuLatCont {
  position: absolute;
  left: 0;
  top: 0;
  width: 280px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  z-index: 2;
}

.cardProfile {
  padding: 30px 0;
  border-radius: 0 0 15px 0;
  background-color: var(--azul-st);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.cardProfile p {
  margin: 10px 0 0px 0;
  color: #fff;
  font: 1.6rem var(--fontSt);
  font-weight: 500;
}

.cardProfile span {
  color: #fff;
  font: 1.2rem var(--fontSt);
  font-weight: 400;
}

.cardProfileImg {
  height: 80px;
  width: 80px;
  border-radius: 100%;
  border: 2px solid #fff;
  overflow: hidden;
  background: #fff;
}

.cardLogo {
  padding: 10px;
}

.cardProfileImg img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.cardMenus {
  overflow-y: auto;
}

#lineTop {
  border-top: 1px solid var(--gris-lg);
}

.cardMenu {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 20px 25px;
}

.cardMenus li, 
.cardMenu ul li{
  list-style: none !important;
}

.cardMenu li{
  padding-left: 0 !important;
  margin: 0px 0;
  padding: 10px 0;
  background: transparent;
  color: var(--negro);
  font: 1.5rem var(--fontSt);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.cardMenu li img{
  height: 28px;
  margin-right: 10px;
  opacity: 0.8;
}

.cardMenu li ul{
  height: auto;
  width: 100%;
  padding: 0 0 0 25px;
  margin: 10px 0 0 0;
}

.menuLArrow{
  width: 15px;
  margin: 0 0 0 5px !important;
}

.cardMenu a,
.cardMenu button {
  margin: 0px 0;
  display: flex;
  align-items: center;
  background: transparent;
  color: var(--negro);
  font: 1.5rem var(--fontSt);
}

.cardMenu button {
  padding: 10px 0;
}

.cardMenu a img,
.cardMenu button img {
  height: 28px;
  margin-right: 10px;
  opacity: 0.8;
  -webkit-filter: none;
          filter: none;
}

.cardMenuAustrac a{
  padding: 20px 20px 20px 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  text-align: center;
  text-transform: uppercase;
  color:#1a1a1a;
  font: 1.2rem var(--fontSt);
  font-weight: 500;
}

.cardMenuAustrac img{
  height: 40px;
}

.backgroundBlack {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.homeLActive {
  font-weight: 700 !important;
  color: var(--azul-lg) !important;
}

.homeLActive img{
  -webkit-filter: invert(11%) sepia(100%) saturate(7331%) hue-rotate(217deg) brightness(98%) contrast(98%)!important;
          filter: invert(11%) sepia(100%) saturate(7331%) hue-rotate(217deg) brightness(98%) contrast(98%)!important;
}

.subLUn img{
  -webkit-filter: none !important;
          filter: none !important;
}

@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
  .botonMenu {
    display: none;
  }
}
@media (min-width: 1200px) {
}
@media (min-width: 1370px) {
}

.modal-general {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 40px);
  margin: 0 auto;
  max-width: 600px;
  max-height: 500px;
  padding: 20px;
  border-radius: 20px;
  background: #fff;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.4s ease;
}

.modal-nuevo {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  max-width: 800px;
  height: 100%;
  background: #fff;
  z-index: 9999;
  opacity: 0;
  transition: all 0.4s ease;
}

.modal-activo {
  opacity: 1 !important;
  visibility: visible !important;
  transition: all 0.4s ease;
}

.fonder {
  position: fixed;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  z-index: 9998;
  transition: all 0.4s ease;
}

.fonder-activo {
  opacity: 0.75 !important;
  visibility: visible !important;
  transition: all 0.4s ease;
}

.cerrar-modal {
  position: absolute;
  right: 0;
  top: -25px;
  color: #fff;
  font: 1.4rem var(--fontSt);
}

.scroll-modify {
  height: 100%;
  width: 100%;
  overflow-x: auto;
}

.InnerModal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.InnerModal p {
  font: 1.6rem var(--fontSt);
  margin: 5px 0 10px 0;
  text-align: center;
}

.InnerModal h2 {
  margin: 15px 0 0 0;
  font: 2.3rem var(--fontSt);
  font-weight: 700;
  text-align: center;
  color: var(--azul-lg);
}

.InnerWrong h2 {
  color: var(--red);
}

.IconModal {
  background: var(--azul-cl);
  border-radius: 100%;
  width: 80px;
  height: 80px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.IconModal img {
  width: 100%;
  -webkit-filter: invert(11%) sepia(100%) saturate(7331%) hue-rotate(217deg)
    brightness(98%) contrast(98%) !important;
          filter: invert(11%) sepia(100%) saturate(7331%) hue-rotate(217deg)
    brightness(98%) contrast(98%) !important;
}

.IconWrong {
  background: var(--red-cl);
}
.IconWrong img {
  -webkit-filter: invert(30%) sepia(23%) saturate(6153%) hue-rotate(344deg)
    brightness(99%) contrast(86%) !important;
          filter: invert(30%) sepia(23%) saturate(6153%) hue-rotate(344deg)
    brightness(99%) contrast(86%) !important;
}

.modalNotice{
  opacity: 0;
}

.modalNoticeAct{
  position: fixed;
  width: 100%;
  height: 100vh;
  padding: 0 15px;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.8);
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
}

.modalNoticeCont img{
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
}

.modalNoticeCont div{
  height: 0;
  display: flex;
  justify-content: flex-end;
}

.modalNoticeCont div img{
  margin: 15px;
  -webkit-filter: invert(1) brightness(100);
          filter: invert(1) brightness(100);
  width: 25px;
  height: 25px;
  cursor: pointer;
  border-radius: 0px;
}

.modalContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  padding: 15px 20px;
  z-index: 9999;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
}

.modalNuevo{
  z-index: 9999;
}

.modalX{
  border-radius: 20px;
  background: #fff;
  padding: 30px;
  max-width: 400px;
  transition: all 0.4s ease;
}

.modalFondo {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.75;
  z-index: 9998;
  transition: all 0.4s ease;
}

.validarButton{
  display: flex;
}

.BtnCancelar{
  color: var(--gris) !important;
  background: transparent;
  border: 1px solid var(--gris);
  padding: 22px 20px;
  margin: 10px 5px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 1.5rem var(--fontSt), sans-serif;
  font-weight: 400;
  line-height: 0;
  white-space: nowrap;
  transition: all 0.4s ease;
}

.BtnCancelar:hover{
  color: var(--blanco) !important;
  background: var(--azul-st);
  border: 1px solid var(--azul-st);
}

.ButtonGreen, .ButtonBlue{
  color: var(--azul-st);
  background: var(--azul-cl);
  border: 1px solid var(--azul-st);
  padding: 22px 20px;
  margin: 10px 5px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 1.5rem var(--fontSt), sans-serif;
  font-weight: 400;
  line-height: 0;
  white-space: nowrap;
}

.ButtonGreen:hover, 
.ButtonBlue:hover{
  color: var(--blanco);
  background: var(--azul-st);
  border: 1px solid var(--azul-st);
}

.ButtonRed{
  color: var(--red);
  background: var(--red-cl);
  border: 1px solid var(--red);
  padding: 22px 20px;
  margin: 10px 5px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 1.5rem var(--fontSt), sans-serif;
  font-weight: 400;
  line-height: 0;
  white-space: nowrap;
}

.ButtonRed:hover{
  color: var(--blanco);
  background: var(--red);
  border: 1px solid var(--red);
}

@media (min-width: 576px) {
  .modal-general {
    padding: 25px;
  }

  .modalX {
    padding: 30px 25px;
  }
}

@media (min-width: 768px) {
  .modal-general {
    width: 100%;
    min-width: 300px;
    padding: 28px;
  }

  .modalX {
    width: 100%;
    min-width: 300px;
    max-width: 400px;
    padding: 30px;
  }
}

@media (min-width: 992px) {
  .modal-general {
    width: 100%;
    max-height: 600px;
    padding: 30px;
  }

  .modalX {
    width: 100%;
    padding: 30px;
  }
}

@media (min-width: 1200px) {
  .modal-general {
    padding: 35px;
  }

  .modalX {
    padding: 35px;
  }
}

.Footer {
  width: 100%;
  background-color: var(--azul-st);
  padding-bottom: 60px;
}

.info-footer {
  padding: 20px 0 20px 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.info-footer a {
  height: 30px;
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--blanco);
  font: 1.4rem var(--fontSt);
  font-weight: 400;
}

.info-footer a img {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  -webkit-filter: invert(1) brightness(10);
          filter: invert(1) brightness(10);
}

.icons-footer {
  display: none;
}

.linkPoliticas,
.linkPoliticas a, 
.linkPoliticas a:visited{
  color: #808080;
  font: 1.4rem var(--fontSt);
  cursor: pointer;
}

.copy-footer {
  width: 100%;
  padding: 15px 35px 15px 35px;
  background-color: #262626;
  color: var(--blanco);
  font: 1.3rem var(--fontSt);
  font-weight: 400;
  text-align: center;
}

.copy-footer span {
  color: #808080;
  font: 1.1rem var(--fontSt);
  font-weight: 300;
}

.footerAustrac{
  width: 100%;
  margin-bottom: 20px;
  -webkit-filter: invert(1);
          filter: invert(1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerAustrac img{
  width: 100%;
  max-width: 250px;
}

@media (min-width: 576px) {
  .Footer {
    padding-bottom: 0px;
  }
}

@media (min-width: 768px) {
  .Footer {
    display: block;
  }

  .info-footer {
    padding-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info-footer a {
    height: 30px;
    padding: 0px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--blanco);
    font: 1.4rem var(--fontSt);
    font-weight: 400;
  }

  .info-footer a img {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    -webkit-filter: invert(1) brightness(10);
            filter: invert(1) brightness(10);
  }

  .icons-footer {
    padding: 20px 0px 40px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icons-footer div {
    height: 30px;
    padding: 0px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid var(--blanco);
    color: var(--amarillo);
    font: 1.6rem var(--fontSt);
    font-weight: 600;
  }

  .icons-footer div:last-child {
    border-right: none;
  }

  .icons-footer div img {
    height: 40px;
    margin-right: 10px;
  }

  .linkPoliticas,
  .linkPoliticas a, 
  .linkPoliticas a:visited{
    color: #808080;
    font: 1.6rem var(--fontSt);
    cursor: pointer;
  }


  .copy-footer {
    padding: 30px 0px;
    background-color: #262626;
    color: var(--blanco);
    font: 1.6rem var(--fontSt);
    font-weight: 400;
    text-align: center;
  }

  .copy-footer span {
    color: #808080;
    font: 1.4rem var(--fontSt);
    font-weight: 300;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

.flexContainer {
  position: relative;
  padding: 60px 20px 40px 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  background: var(--gris-lg);
}

.divAuth {
  min-height: 600px;
  display: flex;
  flex-direction: column-reverse;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 60px 0px;
}

.divPublish {
  position: relative;
  background: var(--azul-st);
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.DivMesage {
  padding: 40px;
  z-index: 2;
}

.DivMesage p {
  text-align: center;
  color: #fff;
  font: 2rem var(--fontSt);
  font-weight: 400;
}

.DivMesage span {
  color: var(--amarillo);
  font-weight: 700;
}

.ImgLogin {
  width: 100%;
}

.fondoLogin {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.fondoLogin img {
  object-fit: cover;
  object-position: center center;
  height: 100%;
  width: 100%;
}

.inputLogin {
  width: 100%;
  padding: 40px 35px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.loginContainer {
  position: relative;
  width: 100%;
  background: var(--blanco);
}

.InnerLogin {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.InnerLogin form {
  width: 100%;
}

.text-buton {
  width: 100%;
  text-align: center;
  font: 1.5rem var(--fontSt);
  font-weight: 400;
}

.text-buton span {
  color: var(--azul-st);
  cursor: pointer;
  font-weight: 600;
}

.label-control {
  width: 100%;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: var(--blanco);
  font: 1.4rem var(--fontSt);
  font-weight: 400;
  text-align: center;
}

.error {
  color: red;
}

.TextLink {
  margin-top: 20px;
  font: 1.6rem var(--fontSt);
  font-weight: 400;
  text-align: center;
}

.TextLink a {
  color: var(--azul-lg);
  font-weight: 700;
}

.textForgot {
  text-decoration: none;
  display: block;
  padding: 10px 0 10px 0;
  text-align: center;
  color: var(--gris);
  font: 1.6rem var(--fontSt);
  font-weight: 500;
}

.IconLogin {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 60px;
  height: 60px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 25px 0px;
  z-index: 5;
}

.IconLogin a img {
  width: 100%;
}

@media (min-width: 576px) {
  .divPublish {
    width: 450px;
    padding: 20px;
  }

  .DivMesage {
    padding: 50px;
  }

  .inputLogin {
    width: 450px;
    padding: 60px 70px;
  }
}

@media (min-width: 768px) {
  .divPublish {
    width: 500px;
    padding: 20px;
  }

  .inputLogin {
    width: 500px;
    padding: 60px 70px;
  }
}

@media (min-width: 992px) {
  .flexContainer {
    padding: 60px 20px 40px 20px;
    align-items: center;
  }

  .divAuth {
    flex-direction: row;
  }

  .divPublish {
    width: 400px;
    padding: 20px;
  }

  .inputLogin {
    width: 500px;
    padding: 20px 80px;
  }
}

@media (min-width: 1200px) {
  .divPublish {
    width: 500px;
    padding: 20px;
  }

  .inputLogin {
    width: 600px;
    padding: 20px 100px;
  }

  .DivMesage {
    padding: 60px;
  }
}

.contError404 {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 24, 180, 1);
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(0, 24, 180, 1)),
    color-stop(100%, rgba(3, 71, 201, 1))
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 24, 180, 1) 0%,
    rgba(3, 71, 201, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0018b4', endColorstr='#0347c9', GradientType=0 );
}

.Error404 {
  text-align: center;
  padding: 0 20px;
}

.Error404 h1 {
  margin: 0 0 10px 0;
  color: var(--amarillo);
  font: 6rem var(--fontSt);
  font-weight: 700;
  line-height: 6rem;
}

.Error404 h3 {
  margin: 0;
  color: var(--amarillo);
  font: 2rem var(--fontSt);
  font-weight: 700;
}

.Error404 a {
  padding: 12px 20px;
  background-color: #fff;
  color: var(--azul-lg);
  font: 1.4rem var(--fontSt);
  font-weight: 700;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}

.Error404 a:hover {
  background-color: var(--azul-st);
  color: #fff;
}

.Error404 p {
  color: #fff;
  font: 1.6rem var(--fontSt);
  font-weight: 400;
  margin: 0 auto 50px auto;
  max-width: 300px;
}

@media (min-width: 768px) {
  .Error404 h1 {
    font: 8rem var(--fontSt);
    font-weight: 700;
  }

  .Error404 h3 {
    font: 3rem var(--fontSt);
    font-weight: 700;
  }

  .Error404 p {
    font: 1.8rem var(--fontSt);
    font-weight: 400;
    max-width: inherit;
  }

  .Error404 a {
    padding: 18px 25px;
    font: 1.6rem var(--fontSt);
    font-weight: 700;
  }
}

.dashboard-menu_mobile {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--blanco);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  z-index: 99;
}

.dashboard-menu_mobile nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.dashboard-menu_mobile nav a {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font: 1.1rem var(--fontSt), sans-serif;
}

.dashboard-menu_mobile nav a img {
  margin-bottom: 3px;
  -webkit-filter: invert(0.75);
          filter: invert(0.75);
}

.dashboard-menu_mobile nav a span {
  width: 100%;
  text-align: center;
  color: var(--gris-m);
}

.liActivo,
.liActivo span {
  color: var(--azul-lg) !important;
}

.liActivo img {
  -webkit-filter: invert(11%) sepia(100%) saturate(7331%) hue-rotate(217deg)
    brightness(98%) contrast(98%) !important;
          filter: invert(11%) sepia(100%) saturate(7331%) hue-rotate(217deg)
    brightness(98%) contrast(98%) !important;
}

.mobile-plus {
  margin-top: -45px;
  padding: 15px;
  border-radius: 100%;
  background-color: var(--azul-st);
}

.mobile-plus img {
  margin: 0 !important;
  -webkit-filter: invert(1) brightness(100%) !important;
          filter: invert(1) brightness(100%) !important;
}

.MenuGeneral {
  display: none;
}

.MenuCont{
  display: none;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .dashboard-menu_mobile {
    display: none;
  }
}

@media (min-width: 992px) {
  .MenuGeneral {
    display: block;
    position: fixed;
    margin-top: 80px;
    width: 100%;
    background: var(--azul-st);
    z-index: 8;
  }

  .MenuCont {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    height: 50px;
    padding: 0px 35px;
    display: flex;
    align-items: center;
  }

  .MenuCont li, .MenuCont a{
    position: relative;
    height: 50px;
    padding: 0 12px 0 12px;
    display: flex;
    align-items: center;
    color: var(--blanco);
    font: 1.4rem var(--fontSt);
    list-style: none !important;
    cursor: pointer;
  }

  .MenuCont a:last-child{
    margin-right: 0px;
  }

  .MenuCont li .menuArrow{
    width: 15px;
    margin: 0 0 0 5px !important;
    -webkit-filter: invert(1) brightness(10);
            filter: invert(1) brightness(10);
  }

  .MenuCont li a, .MenuCont a {
    color: #fff;
    display: flex;
    align-items: center;
  }

  .MenuCont li img, .MenuCont a img {
    margin-right: 5px;
    -webkit-filter: invert(1) brightness(10);
            filter: invert(1) brightness(10);
  }

  .MenuCont li ul{
    display: none;
    position: absolute;
    top: 36px;
    left: 0;
    padding: 0 !important;
    border-radius: 0 0 10px 10px;
    background-color: var(--blanco);
    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.02), 0 0px 20px rgba(0, 0, 0, 0.1);
  }

  .MenuCont li ul li{
    padding: 5px 10px;
    height: auto;
    margin-right: 0px;
  }

  .MenuCont li ul li:last-child{
    border-radius: 0 0 10px 10px;
  }

  .MenuCont li:hover{
    background: var(--azul-lg);
  }

  .MenuCont li:hover ul{
    display: block;
  }

  .MenuCont li ul li:hover{
    background: var(--azul-lg);
  }

  .MenuCont li ul li a{
    color: var(--negro);
  }

  .MenuCont li ul li a img {
    margin-right: 5px;
    -webkit-filter: invert(0.75);
            filter: invert(0.75);
  }

  .MenuCont li ul li:hover a{
    color: var(--blanco) !important;
  }

  .MenuCont li ul li:hover a img{
    -webkit-filter: invert(1) brightness(10) !important;
            filter: invert(1) brightness(10) !important;
  }

  .subActivo{background: var(--azul-st);}

  .liActivo,
  .subActivo a{
    color: var(--amarillo) !important;
  }

  .liActivo img,
  .subActivo a img{
    -webkit-filter: invert(100%) sepia(44%) saturate(1354%) hue-rotate(359deg)
      brightness(103%) contrast(107%) !important;
            filter: invert(100%) sepia(44%) saturate(1354%) hue-rotate(359deg)
      brightness(103%) contrast(107%) !important;
  }

  .liImg img{
    -webkit-filter: none !important;
            filter: none !important;
  }
}

@media (min-width: 1200px) {
  .MenuGeneral {
    margin-top: 90px;
  }

  .MenuCont {
    padding: 0 5px;
  }
}

@media (min-width: 1370px) {
  .MenuGeneral {
    margin-top: 100px;
  }
}

.InicioTransferencia {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.itemTransfer {
  width: 100%;
  max-width: 340px;
  margin: 0px 0px 15px 0px;
  padding: 25px 25px;
  border-radius: 20px;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: all 0.4s ease;
}

.itemTransfer:hover {
  background-color: var(--azul-st);
}

.itemTransfer a {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.numberTransfer {
  width: 100%;
  margin: 0 0 8px 0;
  color: var(--azul-st);
  font: 2.5rem var(--fontSt), sans-serif;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
}

.itemTransfer:hover .numberTransfer {
  color: var(--amarillo);
}

.dateTransfer {
  width: 100%;
  color: var(--gris);
  font: 1.4rem var(--fontSt), sans-serif;
  font-weight: 400;
  text-align: center;
  text-transform: capitalize;
}

.destinoTransfer {
  width: 100%;
  margin-bottom: 10px;
  color: var(--gris);
  font: 1.4rem var(--fontSt), sans-serif;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
}

.coinTransfer {
  margin: 10px 0px;
  color: var(--gris);
  font: 1.3rem var(--fontSt), sans-serif;
  color: var(--negro);
  font-weight: 500;
  display: flex;
  align-items: center;
}

.coinTransfer:last-child {
  margin: 10px 0px 0px 0px;
}

.itemTransfer:hover .dateTransfer,
.itemTransfer:hover .destinoTransfer,
.itemTransfer:hover .coinTransfer {
  color: var(--blanco);
}

.coinTransfer img {
  margin: 3px 8px 0px 0px;
  height: 20px;
}

.coinTransfer label {
  color: var(--gris);
  font: 1.2rem var(--fontSt), sans-serif;
  font-weight: 400;
  display: block;
}

.itemTransfer:hover .coinTransfer img {
  -webkit-filter: invert(1) brightness(10);
          filter: invert(1) brightness(10);
}

@media (min-width: 576px) {
}

@media (min-width: 760px) {
  .itemTransfer {
    width: calc(50% - 20px);
    max-width: inherit;
    margin: 0px 10px 15px 10px;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 1300px) {
  .itemTransfer {
    width: calc(33.3% - 20px);
    max-width: inherit;
    margin: 0px 10px 15px 10px;
  }
}

.item-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
  border-radius: 8px;
}

.item-contact:last-child {
  margin: 0px 0px 0px 0px;
}

.item-contact:hover {
  background-color: #f0f0f0;
}

.item-contact__info {
  display: flex;
  align-items: center;
}

.item-contact__info p {
  margin-left: 15px;
  font: 1.6rem var(--fontSt);
  color: var(--negro);
}

.item-contact__info span {
  font: 1.4rem var(--fontSt);
  color: var(--gris);
}

.item-contact__photo {
  position: relative;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  overflow: hidden;
}

.item-contact__photo::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 100%;
  box-shadow: 0px 0px 0px 2px var(--blanco) inset;
  border: 2px solid var(--azul-st);
  bottom: 0;
  left: 0;
}

.item-contact__photo img {
  height: 100%;
  object-fit: contain;
  object-position: center;
}

@media (min-width: 576px) {
  .item-contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 20px;
    border-radius: 8px;
  }

  .item-contact:last-child {
    margin: 0px 0px 0px 0px;
  }

  .item-contact:hover {
    background-color: #f0f0f0;
  }

  .item-contact__info {
    display: flex;
    align-items: center;
  }

  .item-contact__info p {
    margin-left: 20px;
    font: 1.5rem var(--fontSt);
    color: var(--negro);
  }

  .item-contact__info span {
    font: 1.2rem var(--fontSt);
    color: #c1c9d6;
  }

  .item-contact__photo {
    position: relative;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    overflow: hidden;
  }

  .item-contact__photo::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 100%;
    box-shadow: 0px 0px 0px 2px var(--blanco) inset;
    border: 2px solid var(--azul-st);
    bottom: 0;
    left: 0;
  }

  .item-contact__photo img {
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

.header-table {
  display: none;
}

.item-table {
  width: 100%;
  padding: 20px 20px;
  margin: 15px 0px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  background-color: #f5f5f5;
  color: inherit;
  font: 1.2rem var(--fontSt);
  cursor: pointer;
}

.item-table:last-child {
  margin: 10px 0px 0px 0px !important;
}

.item-table:hover {
  background-color: #f4f8ff;
}

.item-table div {
  width: 50%;
  margin: 5px 0px;
}

.item-completo {
  width: 100% !important;
}

.label-mobile {
  display: block;
  color: #acacac;
  font: 1rem var(--fontSt);
  font-weight: 600;
}

.label-green {
  color: green;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .label-mobile {
    display: none;
  }

  .header-table {
    width: 100%;
    padding: 20px 20px;
    margin: 10px 0px 0px 0px;
    display: flex;
    border-radius: 10px 10px 0 0;
    background-color: #f2f2f2;
    color: #acacac;
    font: 1.4rem "Inter", sans-serif;
    font-weight: 300;
  }

  .item-table {
    width: 100%;
    padding: 20px 20px;
    margin: 0px 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 0px;
    border: solid #f2f2f2;
    border-width: 1px 1px 0px 1px;
    background-color: var(--blanco);
    font: 1.4rem "Inter", sans-serif;
  }

  .item-table:last-child {
    margin: 0px 0px 0px 0px !important;
    border-radius: 0px 0px 10px 10px;
    border-width: 1px 1px 1px 1px !important;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

.TableFooter {
  margin-top: 20px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.TableFooterNormal {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paginacion {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.pagNext,
.pagPrev {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e8f7ff;

  padding: 5px;
}
.pagPrev {
  border-radius: 5px 0 0 5px;
}
.pagNext {
  border-radius: 0 5px 5px 0px;
}
.pagPrev img,
.pagNext img {
  -webkit-filter: invert(14%) sepia(66%) saturate(7491%) hue-rotate(216deg)
    brightness(106%) contrast(98%);
          filter: invert(14%) sepia(66%) saturate(7491%) hue-rotate(216deg)
    brightness(106%) contrast(98%);
  width: 20px;
}

.pagNumber {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gris-lg);
  color: var(--gris);
  font: 1.4rem var(--fontSt);
  font-weight: 400;
}

.pagNumber:hover {
  background-color: #e8f7ff;
  color: var(--azul-lg);
}

.pagActive {
  background-color: #e8f7ff;
  color: var(--azul-lg);
  border-top: 1px solid var(--azul-lg) !important;
  border-left: 1px solid var(--azul-lg) !important;
  border-right: 1px solid var(--azul-lg) !important;
  border-bottom: 1px solid var(--azul-lg) !important;
}

.pagDisabled {
  background: var(--gris-lg);
  color: var(--gris);
  pointer-events: none;
}

.pagDisabled img {
  -webkit-filter: none;
          filter: none;
  opacity: 0.09;
}

.pagBlock {
  display: none;
  pointer-events: none;
}

.TableHeader {
  margin-bottom: 20px;
}

.Btns-top {
  display: flex;
  justify-content: right;
}

.Btns-Export {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.BtnExport {
  height: 40px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  color: var(--gris);
  font: 1.4rem var(--fontSt);
  font-weight: 500;
  background: var(--gris-lg);
}

.BtnExport img {
  width: 20px;
  margin-right: 10px;
  -webkit-filter: invert(64%) sepia(11%) saturate(439%) hue-rotate(181deg)
    brightness(95%) contrast(86%);
          filter: invert(64%) sepia(11%) saturate(439%) hue-rotate(181deg)
    brightness(95%) contrast(86%);
}

.BtnExport:hover img {
  -webkit-filter: invert(1) brightness(100);
          filter: invert(1) brightness(100);
}

.BtnPdf {
  margin-left: 15px;
}
.BtnExcel:hover {
  background-color: #0e9b2c;
  color: #fff;
}
.BtnPdf:hover {
  background-color: #ff3131;
  color: #fff;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .TableFooter {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .Btns-Export {
    margin-top: 0px;
  }
}

.divTableContent {
  padding-top: 5px;
}

.divTable-head {
  width: 100%;
  display: none;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--gris-lg);
  color: var(--gris);
}

.divTable-headRow {
  display: flex;
  align-items: center;
  padding: 10px 10px;
}

#tableActions2 {
  width: 100% !important;
}

.divTable-headRow div {
  width: 20%;
  font-size: 1.4rem;
  font-weight: 500;
  align-items: center;
  padding: 0px 10px;
}

.divTable-body {
  margin: 10px 0px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  border-top: 1px solid var(--gris-lg);
  border-left: 1px solid var(--gris-lg);
  border-right: 1px solid var(--gris-lg);
  border-bottom: 1px solid var(--gris-lg);
  color: inherit;
  -webkit-text-decoration-color: none;
          text-decoration-color: none;
  cursor: pointer;
}

.divTable-body:hover {
  background-color: #e8f7ff;
  color: var(--azul-lg);
  border-top: 1px solid var(--azul-lg);
  border-left: 1px solid var(--azul-lg);
  border-right: 1px solid var(--azul-lg);
  border-bottom: 1px solid var(--azul-lg);
}

.divTable-body:hover .tableActions button img {
  -webkit-filter: invert(14%) sepia(66%) saturate(7491%) hue-rotate(216deg)
    brightness(106%) contrast(98%);
          filter: invert(14%) sepia(66%) saturate(7491%) hue-rotate(216deg)
    brightness(106%) contrast(98%);
}

.divTable-body:hover .divTable-row label {
  color: var(--azul-lg);
}

.divTable-row {
  display: flex;
  align-items: flex-start;
  padding: 10px 10px;
}

.divTable-row div {
  margin: 5px 0px;
  padding: 0 10px;
  font: 1.4rem var(--fontSt);
  font-weight: 400;
  overflow: hidden;
}

#table5td {
  width: 100% !important;
  flex-wrap: wrap;
  padding: 10px 10px 0px 10px !important;
}

#table5td div {
  width: 50%;
}

#table9td {
  flex-wrap: wrap;
}

#table9td div {
  width: 50%;
}

#table6td {
  width: 100%;
  flex-wrap: wrap;
}

#table6td div {
  width: 50%;
}

.divTable-row label {
  display: block;
  font: 1.2rem var(--fontSt);
  font-weight: 700;
  color: var(--gris);
}

.tableActions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px 10px 0;
}

.tableActions button {
  background: transparent;
  height: 50px;
  width: 50px;
  padding: 15px;
}

.tableActions button img {
  width: 100%;
}

.ColTdSm {
  display: none;
}

.td100 {
  width: 100% !important;
}

.tdNone {
  display: none;
}

.tdEmail {
  width: 100% !important;
}

.tdsal p{
  display: block;
  font: 0.9rem var(--fontSt) !important;
}

/*TESTIMONIOS*/

#tableRate {
  width: 100% !important;
  flex-wrap: wrap;
  padding: 10px 10px 10px 10px !important;
}

.tdComment {
  width: 100% !important;
}

.tdStars {
  width: 100% !important;
}

.tdStars .divStars {
  margin: 0 !important;
  padding: 0 !important;
  justify-content: start;
}

.tdStars .divStars .DivStar {
  padding: 0 !important;
}

.tdInfo {
  width: 50% !important;
}

/*CUENTAS*/

#tdCuentas {
  width: 100%;
  flex-wrap: wrap;
}

#tdCuentas div {
  width: 50% !important;
}

/*METODO*/
.tdmetodo p{
  background-color: var(--azul-lg) !important;
}

.divReferencia{
  margin: 5px 0px;
  padding: 0 10px;
  overflow: visible !important;
}

.Tradicional{
  padding: 4px 6px !important;
  background: var(--azul-st);
  width: -webkit-min-content !important;
  width: -moz-min-content !important;
  width: min-content !important;
  border-radius: 5px;
  color: #fff;
  font: 1rem var(--fontSt) !important;
}

.Referencia{
  padding: 4px 6px !important;
  background: var(--red);
  width: -webkit-min-content !important;
  width: -moz-min-content !important;
  width: min-content !important;
  border-radius: 5px;
  color: #fff;
  font: 1rem var(--fontSt) !important;
}



@media (min-width: 576px) {
  #table5td div,
  #table9td div,
  #table6td div .td100 {
    width: 33.3%;
  }
  #table4td div {
    width: 33.3%;
  }
  .tdEmail {
    width: 50% !important;
  }

  /*TESTIMONIOS*/
  .tdStars,
  .tdInfo {
    width: 33.3% !important;
  }

  #tdCuentas div {
    width: 25% !important;
  }

}

@media (min-width: 768px) {
  .divTable-head {
    display: flex;
  }

  #tableActions2 {
    width: calc(100% - 100px) !important;
  }

  #tableActions2 div {
    width: 20%;
  }

  .divTable-body {
    flex-wrap: nowrap;
    border-top: 1px solid transparent;
    margin: 0px 0px;
    border-radius: 0px;
  }

  .divTable-body:last-child {
    border-radius: 0 0 10px 10px;
  }

  .divTable-row {
    align-items: center;
  }

  .divTable-row div {
    margin: 0px 0px;
  }

  .divTable-row label {
    display: none;
  }

  #table4td {
    width: calc(100% - 100px) !important;
    flex-wrap: wrap;
    padding: 10px !important;
  }

  #table4td {
    width: 20%;
  }

  #table5td {
    width: calc(100% - 100px) !important;
    flex-wrap: wrap;
    padding: 10px !important;
  }

  #table5td div {
    width: 20%;
  }

  #table9td {
    width: 100%;
  }

  #table9td div {
    width: 12.5%;
  }

  #table6td {
    width: 100%;
  }

  #table6td div {
    width: 14%;
  }

  .tdEmail {
    width: 30% !important;
  }

  .tableActions {
    width: inherit;
    padding: 0;
  }

  .tdNone {
    display: block;
  }

  /*TESTIMONIOS*/
  .tdComment {
    width: 40% !important;
  }

  .tdStars {
    width: 30% !important;
    text-align: center;
  }

  .tdStars .divStars {
    padding: 0 !important;
    justify-content: center;
  }

  .tdInfo {
    width: 15% !important;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
  .divTableContent {
    padding-top: 15px;
  }

  .tdSpecial {
    width: 12.4% !important;
  }

  .tdsal {
    text-align: right;
  }
}

.drop_zone {
  position: relative;
  width: 100%;
  padding: 25px;
  margin: 10px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px dashed;
  background: #fff;
  border-color: #d6d6d6;
  font: 1.4rem var(--fontSt);
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.drop_zone_active {
  border-color: var(--azul-lg) !important;
  background-color: var(--azul-cl);
}

.drop_zone:hover {
  border-color: var(--azul-lg);
}
.drop_zone p {
  margin: 2px 0;
  color: #c7c7c7;
}

.drop_zone_active p {
  color: var(--azul-lg) !important;
}

.drop_zone_active img {
  -webkit-filter: invert(14%) sepia(66%) saturate(7491%) hue-rotate(216deg)
    brightness(106%) contrast(98%) !important;
          filter: invert(14%) sepia(66%) saturate(7491%) hue-rotate(216deg)
    brightness(106%) contrast(98%) !important;
  opacity: 1 !important;
}

.drop_zone p:first-child {
  color: rgba(0, 0, 0, 0.65);
}
.drop_zone_list {
  width: 100%;
  padding: 0 3px;
  box-sizing: border-box;
}
.drop_zone_list div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px;
  font-size: 14px;
  padding: 0 3px;
  transition: all 0.1s ease;
  color: #3f4447;
}
.drop_zone_list div:hover {
  background: #e0f5ff;
}
.drop_zone_list div:hover a {
  display: block;
}
.drop_zone_list div p {
  margin: 5px;
}
.drop_zone_list div a {
  text-decoration: none;
  color: #3f4447;
  display: none;
  font-size: 16px;
  cursor: pointer;
}

.fileClick {
  border: 1px solid red;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.fileIcon {
  display: flex;
  font: 1.4rem var(--fontSt);
  font-weight: 600;
}

.fileIcon img {
  margin-right: 10px;
  opacity: 0.3;
}

.drop_hover:hover {
  border-color: var(--azul-lg);
}
.drop_out:hover {
  border-color: var(--gris);
}

.spanAlertDocument {
  position: absolute;
  bottom: -8px;
  padding: 2px 5px;
  border-radius: 3px;
  background: #fdeeee;
  color: #d63830;
  font: 1.2rem var(--fontSt), sans-serif;
  font-weight: 500;
  z-index: 1;
  transition: all 0.4s ease;
}

.dashedRed {
  background: #fdeeee;
  border: dashed 1px #d63830 !important;
}

.dashedRed p {
  color: #d63830 !important;
}

.dashedRed img {
  -webkit-filter: invert(29%) sepia(23%) saturate(6993%) hue-rotate(345deg)
    brightness(89%) contrast(87%) !important;
          filter: invert(29%) sepia(23%) saturate(6993%) hue-rotate(345deg)
    brightness(89%) contrast(87%) !important;
  opacity: 1 !important;
}

.countDown {
  position: relative;
  width: 100%;
  height: 35px;
  border-radius: 50px;
  background: var(--azul-st);
  font: 2.3rem var(--fontSt);
  font-weight: 700;
  overflow: hidden;
}

.countWhite {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--azul-st);
}

.countProgress {
  top: 0;
  left: 0;
  height: 100%;
  background: var(--gris-lg);
  overflow: hidden;
}

.countBlue {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  color: #fff;
  z-index: 1;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

.CardTransferencia {
  position: relative;
  padding: 30px;
  margin: 15px 15px;
  border-radius: 20px;
  background: var(--azul-st);
  background-image: url(/static/media/Transferencia.66ba1be0.png);
  background-repeat: no-repeat;
  background-size: auto 50%;
  background-position: right bottom;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 5px 60px 0px;
  z-index: 1;
}

.CardTransferencia span {
  color: #fff;
  font: 1.2rem var(--fontSt);
  font-weight: 500;
}

.CardTransferencia p {
  color: #fff;
  font: 2rem var(--fontSt);
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.CardTransferencia div {
  margin: 0 0 10px 0;
}

.CardTransferencia div:last-child {
  margin: 0;
}

.NTransfer {
  color: var(--amarillo) !important;
  font: 2.6rem var(--fontSt) !important;
  font-weight: 700 !important;
  text-transform: uppercase;
}

.FTransfer {
  text-transform: capitalize;
}

.CardBody {
  position: relative;
  padding: 30px;
  margin: 15px 15px;
  border-radius: 20px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 5px 60px 0px;
  z-index: 1;
}

.CardInput {
  padding: 30px 15px !important;
}

.CardInput h3 {
  padding: 0 0 0 15px !important;
}

.CardBody h3 {
  color: var(--azul-st);
  font: 2rem var(--fontSt) !important;
  font-weight: 700 !important;
  margin: 0 0 15px 0;
  padding: 0;
}

.DatosTrans {
  margin-top: 30px;
}

.DatosTrans a {
  color: var(--negro);
  font: 1.7rem var(--fontSt) !important;
  font-weight: 700 !important;
}

.DatosTrans a:hover {
  color: var(--azul-lg);
}

.DatosTrans div {
  margin-bottom: 20px;
}

.DatosTrans label {
  display: block;
  font: 1.3rem var(--fontSt) !important;
  font-weight: 500 !important;
  margin-bottom: 3px;
  color: var(--gris);
}

.DatosTrans p {
  color: var(--negro);
  font: 1.6rem var(--fontSt);
  font-weight: 400;
}

.Observaciones p {
  color: var(--negro);
  font: 1.6rem var(--fontSt);
  font-weight: 400;
}

.especialEmail {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.TransDocuments {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}

.TransDocuments a {
  width: calc(50% - 20px);
  height: 100px;
  padding: 20px;
  margin: 5px 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: var(--gris-lg);
  color: var(--gris);
  font: 1.4rem var(--fontSt);
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.TransDocuments a:hover {
  background: var(--azul-cl);
  color: var(--azul-lg);
}

.TransDocuments a:hover img {
  -webkit-filter: invert(14%) sepia(66%) saturate(7491%) hue-rotate(216deg)
    brightness(106%) contrast(98%);
          filter: invert(14%) sepia(66%) saturate(7491%) hue-rotate(216deg)
    brightness(106%) contrast(98%);
  opacity: 1;
}

.TransDocuments a img {
  width: 30px;
  margin: 5px 15px 0 15px;
  opacity: 0.5;
}

.ResumenTrans {
  margin-top: 30px;
}

.ResumenTrans div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.ResumenTrans div label {
  font: 1.3rem var(--fontSt) !important;
  font-weight: 500 !important;
  margin-bottom: 3px;
  color: var(--gris);
}
.ResumenTrans div span {
  font: 1.6rem var(--fontSt) !important;
  font-weight: 500 !important;
  margin-bottom: 3px;
  color: var(--negro);
}

.Registrada {
  color: var(--azul-st) !important;
  margin-right: 5px;
}

.Revision{
  color: #ffc400 !important;
  margin-right: 5px;
}

.Proceso {
  color: var(--azul-lg) !important;
  margin-right: 5px;
}

.Procesada {
  color: #0e9b2c !important;
  margin-right: 5px;
}
.Cancelada {
  color: #ff3131 !important;
  margin-right: 5px;
}

.LinkTransfer {
  padding: 10px;
  margin: 5px 0;
  border-radius: 10px;
  border-top: 1px solid var(--gris-lg);
  border-left: 1px solid var(--gris-lg);
  border-right: 1px solid var(--gris-lg);
  border-bottom: 1px solid var(--gris-lg);
  font: 1.6rem var(--fontSt);
  font-weight: 400;
  color: var(--negro);
}

.LinkTransfer label {
  font: 1.3rem var(--fontSt) !important;
  font-weight: 500 !important;
  color: var(--gris);
  margin-right: 5px;
}

.textoTrans {
  color: var(--negro);
  font: 1.4rem var(--fontSt);
  font-weight: 400;
  text-align: left;
  margin-bottom: 15px !important;
}

@media (min-width: 544px) {
  .TransDocuments a img {
    margin: 0px 0px 0 20px;
  }

  .CardTransferencia {
    background-size: auto 100%;
  }
}

@media (min-width: 768px) {
  .TransDocuments a {
    width: calc(50% - 20px);
    height: 100%;
    padding: 20px;
    margin: 5px 10px;
    font: 1.4rem var(--fontSt);
    font-weight: 500;
  }

  .TransDocuments a img {
    width: 40px;
    margin: 10px 40px 0 40px;
  }
}

@media (min-width: 992px) {
  .TransDocuments a {
    width: calc(25% - 20px);
    padding: 20px;
    margin: 5px 10px;
    font: 1.4rem var(--fontSt);
    font-weight: 500;
  }

  .TransDocuments a img {
    width: 40px;
    margin: 10px 20px 0 20px;
  }
}

@media (min-width: 1200px) {
}

@media (min-width: 1370px) {
  .TransDocuments a {
    width: calc(25% - 20px);
    height: 100%;
    margin: 0 10px;
    font: 1.4rem var(--fontSt);
    font-weight: 500;
  }

  .TransDocuments a:first-child {
    margin: 0 10px 0 0 !important;
  }

  .TransDocuments a img {
    margin: 10px 25px 0 25px;
  }
}

.rating {
  display: flex;
  flex-flow: row-reverse;
  align-items: center;
  justify-content: center;
}

.rating > label:before {
  content: url(/static/media/star-1.b4abbc9d.svg);
  height: 20px;
  width: 20px;
  margin: 5px;
  display: inline-block;
  cursor: pointer;
  -webkit-filter: invert(100%);
          filter: invert(100%);
}

.rating > input {
  display: none;
}

.rating > input:checked ~ label,
.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  -webkit-filter: invert(15%) sepia(100%) saturate(779%) hue-rotate(359deg)
    brightness(104%) contrast(105%);
          filter: invert(15%) sepia(100%) saturate(779%) hue-rotate(359deg)
    brightness(104%) contrast(105%);
}

.rating > input:checked + label:hover,
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label,
.rating > input:checked ~ label:hover ~ label {
  -webkit-filter: invert(15%) sepia(100%) saturate(779%) hue-rotate(359deg)
    brightness(104%) contrast(105%);
          filter: invert(15%) sepia(100%) saturate(779%) hue-rotate(359deg)
    brightness(104%) contrast(105%);
}

.disPlayCard {
  display: flex;
  align-items: center;
}

.displayName {
  margin-left: 15px !important;
}

.displayName p {
  font: 1.8rem var(--fontSt);
  font-weight: 700;
  color: #fff;
}

.displayName span {
  font: 1.4rem var(--fontSt);
  font-weight: 400;
  color: #fff;
}

.ImagenPerfil {
  position: relative;
  height: 80px;
  width: 80px;
  border-radius: 100%;
  overflow: hidden;
  cursor: pointer;
}

.ImagenPerfil img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.ImagenPerfil:hover .PerfilButton {
  opacity: 1;
}

.PerfilButton {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: all 0.4s ease;
}

.PerfilButton button {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.PerfilButton button img {
  height: 30px;
  width: 30px;
  -webkit-filter: invert(1) brightness(10);
          filter: invert(1) brightness(10);
}

.PerfilButton button span {
  width: 100%;
  margin-top: 5px;
  font: 1.2rem var(--fontSt);
  font-weight: 500;
  color: #fff;
}

.UserData {
  font: var(--fontSt);
}

.UserData div {
  margin: 20px 0;
}

.UserData label {
  margin-bottom: 5px;
  display: block;
  color: var(--gris);
  font-size: 1.2rem;
  font-weight: 500;
}

.UserData p {
  color: var(--negro);
  font-size: 1.8rem;
  font-weight: 400;
}

.documentDiv {
  border-radius: 30px;
  padding: 15px;
  background-color: var(--gris-lg);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 200px;
}

.documentDiv img {
  height: 50px;
  -webkit-filter: invert(0.8);
          filter: invert(0.8);
}

.documentDiv label {
  width: 100%;
  font: 1.6rem var(--fontSt);
  font-weight: 500;
  text-align: center;
}

.cropProfile {
  width: 300px;
  height: 300px;
  overflow: hidden;
}

.cropperDiv {
  height: 100%;
  margin: 0 auto;
}

.input-phone,
.SelectAndInput {
  position: relative;
  padding: 10px 0px;
  margin: 5px 0px;
}

.SelectInput input,
.inputPhone input {
  height: 40px;
  width: 100%;
  padding: 6px 12px 6px 79px;
  border-radius: 5px;
  background: #ffffff;
  color: var(--negro);
  border: solid 1px var(--gris-lg);
  box-shadow: none;
  font: 1.6rem var(--fontSt), sans-serif;
  font-weight: 400;
}

.BorderSelec {
  border-radius: 5px 5px 5px 0px !important;
}

.inputPhone input:hover,
.SelectInput input:hover {
  border: solid 1px var(--azul-lg) !important;
}

.inputPhone input:focus,
.SelectInput input:focus {
  outline: none;
  border: solid 1px var(--azul-lg);
  box-shadow: none;
}

.input-phone label,
.SelectInput label {
  position: absolute;
  top: 20px;
  left: -3px;
  margin-left: 10px;
  padding: 0px 6px;
  transform: translate3d(0, -18px, 0);
  background: #fff;
  color: #c7c7c7;
  font: 1.3rem var(--fontSt), sans-serif;
  font-weight: 600;
  pointer-events: none;
  z-index: 5;
}

.input-phone:hover label,
.SelectInput:hover label .input-phone input:focus + label,
.SelectInput input:focus + label {
  color: var(--azul-lg) !important;
}

.inputPhone input:not(:-ms-input-placeholder) + label, .SelectInput input:not(:-ms-input-placeholder) + label {
  left: -3px;
  padding: 0px 6px;
  transform: translate3d(0, -18px, 0);
  background: #fff;
  color: var(--azul-lg);
  font: 1.3rem var(--fontSt), sans-serif;
  font-weight: 600;
}

.inputPhone input:not(:placeholder-shown) + label,
.SelectInput input:not(:placeholder-shown) + label {
  left: -3px;
  padding: 0px 6px;
  transform: translate3d(0, -18px, 0);
  background: #fff;
  color: var(--azul-lg);
  font: 1.3rem var(--fontSt), sans-serif;
  font-weight: 600;
}

.inputPhone input:not(:-ms-input-placeholder), .SelectInput input:not(:-ms-input-placeholder) {
  outline: none;
  box-shadow: none !important;
  background: transparent !important;
}

.inputPhone input:not(:placeholder-shown),
.SelectInput input:not(:placeholder-shown) {
  outline: none;
  box-shadow: none !important;
  background: transparent !important;
}

.redPhoneWarn label {
  color: var(--red) !important;
}

.inputOpcion,
.inputCode {
  position: absolute;
  top: 0;
  margin-top: 10px;
  height: 40px;
  width: 67px;
  display: flex;
  align-items: center;
  border-radius: 5px 0 0 5px;
  z-index: 3;
}

.inputCode input {
  position: absolute;
  left: 1px;
  width: 67px;
  height: 40px;
  padding: 0px 12px 0px 20px;
  background: transparent;
  color: var(--negro);
  border: none;
  font: 1.6rem var(--fontSt), sans-serif;
  font-weight: 400;
}

.inputOpcionDiv {
  position: absolute;
  left: 0px;
  width: 67px;
  height: 30px;
  padding: 0px 12px 0px 12px;
  background: transparent;
  color: var(--negro);
  border: none;
  font: 1.6rem var(--fontSt), sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  border-right: solid 1px var(--gris-lg);
}

.inputArrow {
  position: absolute;
  top: 6px;
  right: 8px;
  width: 20px;
  opacity: 0.3;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
}
.inputArrowRo {
  transform: rotate(180deg);
  transition: all 0.2s ease-in-out;
}

.inputCode::before {
  content: "";
  position: absolute;
  right: 0;
  height: 30px;
  width: 1px;
  background: var(--gris-lg);
}

.inputCode button {
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font: 1.6rem var(--fontSt), sans-serif;
}

.input-phone button img {
  height: 20px;
  margin-right: 5px;
}

.selectCodes {
  position: absolute;
  top: 40px;
  left: 0;
  width: 67px;
  max-height: 120px;
  background: #fff;
  border-radius: 0px 0px 5px 5px;
  box-sizing: border-box;
  font: 1.6rem var(--fontSt), sans-serif;
  font-weight: 400;
  overflow: hidden;
  z-index: 9;
}

.selectCode:last-child {
  border-radius: 0 0 5px 5px;
}

.selectCodesnull {
  height: 0;
  width: 0;
  overflow: hidden;
  border: 0px;
}

.selectCode {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid var(--gris-lg);
  border-right: 1px solid var(--gris-lg);
  border-bottom: 1px solid var(--gris-lg);
  cursor: pointer;
}

.selectCode:hover {
  background: var(--azul-lg);
  border-left: 1px solid var(--azul-lg);
  border-right: 1px solid var(--azul-lg);
  color: #fff;
}

.selectCode img {
  height: 20px;
  margin-right: 5px;
}

.redSpan {
  color: var(--red) !important;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

.RateContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.inputRate {
  width: 100%;
}

.inputTasa {
  width: 100%;
}

.totalManual {
  width: 100%;
  background: var(--verde);
  color: var(--blanco);
  border-radius: 8px;
  padding: 10px;
  margin-top: 5px;
  text-align: center;
  font: 1.6rem var(--fontSt) !important;
  font-weight: 500 !important;
}

.RateOperation {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.RateOperation div {
  margin: 0 10px;
}

.RateOperation div span {
  font-weight: 400 !important;
}

.RateOperation div p,
.RateOperation div p span {
  font: 1.6rem var(--fontSt) !important;
  font-weight: 500 !important;
}

.totalRate {
  width: 100%;
  background: var(--azul-cl);
  color: var(--azul-lg);
  border-radius: 8px;
  padding: 10px;
  margin-top: 5px;
  text-align: center;
  font: 1.6rem var(--fontSt) !important;
  font-weight: 500 !important;
}

.igualRate {
  display: none;
}

.botonesRate,
.botonesTasa {
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
.botonesRate button,
.botonesTasa button {
  margin: 0 4px;
}

.botonesUltra{
  width: auto;
}

/*TABLA CONFIG*/

.tablaConfig{
  font: 1.4rem var(--fontSt) !important;
  font-weight: 500 !important;
}

.tablaConfigRow{
  border: 1px solid var(--gris);
  border-radius: 6px;
  overflow: hidden;
  display: flex;
}

.DesConfig{
  padding: 25px;
  border-right: 1px solid var(--gris);
}

.botonesConfig{
  display: flex;
}

.botonesConfig button{
  height: 100%;
  width: 100px;
  font: 1.4rem var(--fontSt) !important;
}

.ConfigCheck{
  background: var(--azul-st);
  color: #fff;
}

@media (min-width: 576px) {
  .RateContainer {
    align-items: center;
  }
  .inputTasa {
    margin-right: 10px;
    width: 30%;
  }
  .inputRate {
    width: 20%;
  }
  .igualRate {
    display: flex;
  }
  .RateOperation {
    width: 50%;
    margin-bottom: 0px;
  }

  .totalRate,
  .totalManual {
    width: 30%;
    margin-top: 0px;
  }

  .botonesRate {
    margin-top: 0;
  }

  .botonesTasa {
    margin-top: 0;
    width: auto;
  }

  .tablaConfigRow{
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
}

@media (min-width: 768px) {
  .inputTasa {
    width: 20%;
  }
  .inputRate {
    width: 12%;
  }
  .RateOperation {
    width: 38%;
  }

  .totalRate,
  .totalManual {
    width: 18%;
  }

  .botonesRate {
    width: 32%;
  }
}

@media (min-width: 992px) {
  .inputTasa {
    width: 150px;
  }
  .inputRate {
    width: 100px;
    margin-right: 10px;
  }
  .RateOperation,
  .totalRate,
  .totalManual {
    margin-right: 10px;
    width: auto;
  }

  .botonesRate {
    width: auto;
  }
}

@media (min-width: 1200px) {
}

.divContainer {
  position: relative;
  width: 100%;
  min-height: 80vh;
  padding: 50px 15px 50px 15px;
  background: var(--gris-lg);
}

.divAling {
  display: flex;
  align-items: center !important;
  padding: 0px 15px 0px 15px !important;
}

/* .divContainer::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60%;
  background: rgba(0, 24, 180, 1);
  background: -moz-linear-gradient(
    top,
    rgba(0, 24, 180, 1) 0%,
    rgba(3, 71, 201, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(0, 24, 180, 1)),
    color-stop(100%, rgba(3, 71, 201, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 24, 180, 1) 0%,
    rgba(3, 71, 201, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 24, 180, 1) 0%,
    rgba(3, 71, 201, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(0, 24, 180, 1) 0%,
    rgba(3, 71, 201, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 24, 180, 1) 0%,
    rgba(3, 71, 201, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0018b4', endColorstr='#0347c9', GradientType=0 );
} */

.divSubContainer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.formData {
  position: relative;
  width: 100%;
  background: #fff;
  border-radius: 30px;
  z-index: 1;
}

/*------PROGRES-----*/

.formProgress {
  width: 100%;
  height: 120px;
  border-radius: 30px 30px 0 0;
  background: var(--azul-st);
  display: flex;
  align-items: center;
}

.formProgress ul {
  position: relative;
  width: 100%;
  padding: 50px 0 0 0 !important;
  display: flex;
  justify-content: center;
}

.formProgress ul li {
  position: relative;
  list-style: none;
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: #fff;
  font: 1.3rem var(--fontSt), sans-serif;
  font-weight: 500;
}

.formProgress ul li label {
  text-align: center;
  width: 100%;
  color: rgba(255, 255, 255, 0.5);
  font: 1rem var(--fontSt), sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}

.formProgress ul li span {
  position: absolute;
  top: -50px;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgba(255, 255, 255, 0.5);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.formProgress ul li span img {
  height: 20px;
  -webkit-filter: invert(0.6);
          filter: invert(0.6);
  opacity: 0.8;
}

.formProgress ul li::before {
  content: "";
  position: absolute;
  top: -30px;
  right: calc(-50% + 22px);
  width: calc(100% - 44px);
  height: 2px;
  background: rgba(255, 255, 255, 0.5);
  transition: all 0.2s ease-in-out;
}

.formProgress ul li:last-child::before {
  content: "";
  width: 0;
}

.formProgress-active span,
.formProgress-current span {
  border-color: var(--amarillo) !important;
  transition: all 0.2s ease-in-out;
}

.formProgress-active label,
.formProgress-current label {
  color: var(--amarillo) !important;
}

.formProgress-current span img,
.formProgress-active span img {
  height: 20px !important;
  -webkit-filter: invert(100%) sepia(100%) saturate(6647%) hue-rotate(344deg)
    brightness(95%) contrast(115%) !important;
          filter: invert(100%) sepia(100%) saturate(6647%) hue-rotate(344deg)
    brightness(95%) contrast(115%) !important;
  opacity: 1 !important;
}

.formProgress-active::before {
  content: "";
  position: absolute;
  top: -30px;
  right: calc(-50% + 22px);
  width: calc(100% - 44px);
  height: 2px;
  background: var(--amarillo) !important;
  transition: all 0.2s ease-in-out;
}

.formProgress h3 {
  width: 100%;
  color: var(--amarillo);
  font: 2rem var(--fontSt);
  font-weight: 700;
  text-align: center;
}

.formProgress h3 span {
  color: #ffffff;
}

/*---CONTENIDO----*/
.formContent {
  padding: 30px 20px;
}

.formTitle {
  margin: 0 0 20px 0;
  padding: 0px 0px 0px 0px;
}

.formTitle h1 {
  margin: 0px 0 10px 0;
  color: var(--azul-st);
  font: 2rem var(--fontSt);
  font-weight: 600;
}

.formTitle p {
  max-width: 500px;
  margin: 0 auto;
  color: var(--negro);
  font: 1.4rem var(--fontSt);
  font-weight: 400;
  text-align: center;
}

/*----PASO A PASO-----*/

.step {
  width: 100%;
  padding: 0px 0px 0px 0px;
}

.habil {
  display: block !important;
}

.step1,
.step2,
.step3,
.step4 {
  display: none;
}

/*---BUTTONS--*/
.formButtons {
  margin-top: 20px;
  display: flex;
}

.BtnsRigth {
  justify-content: flex-end;
}

.BtnsSpace {
  justify-content: space-between;
}

.divSalir {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.divSalir p {
  width: 100%;
  font: 1.4rem var(--fontSt);
  color: var(--negro);
}

.boton-text {
  margin: 10px 0;
  background: transparent;
  display: flex;
  align-items: center;
  color: var(--negro);
  font: 1.6rem var(--fontSt);
  line-height: 0;
}

/* .boton-text img {
  filter: invert(1) brightness(10);
} */

.ProcesoCompleto {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.bienvenido-title {
  width: 100%;
  color: var(--amarillo);
  font: 3rem var(--fontSt);
  font-weight: 500;
  text-align: center;
}

.text-title {
  margin: 0px 0 30px 0;
  color: var(--azul-st);
  font: 2.4rem var(--fontSt);
  font-weight: 600;
  text-align: center;
}

.buttonsInput {
  position: absolute;
  top: 10px;
  right: 12px;
  display: flex;
}

.buttonsInput button {
  height: 40px;
  width: 40px;
  padding: 10px;
  background: transparent;
}

.buttonsInput button img {
  width: 100%;
  opacity: 0.3;
}

.titleSpecial {
  font: 1.4rem var(--fontSt);
  font-weight: 500;
  color: var(--gris);
}

.textNormal {
  color: var(--negro);
  font: 1.4rem var(--fontSt);
  font-weight: 400;
  margin-bottom: 20px;
}

.Completado {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.CompletadoImg {
  height: 60px;
  width: 60px;
  padding: 12px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  border-radius: 100%;
  background-color: var(--azul-cl);
}

.CompletadoImg img {
  width: 100%;
  -webkit-filter: invert(11%) sepia(100%) saturate(7331%) hue-rotate(217deg)
    brightness(98%) contrast(98%) !important;
          filter: invert(11%) sepia(100%) saturate(7331%) hue-rotate(217deg)
    brightness(98%) contrast(98%) !important;
}

.Completado p {
  width: 100%;
  margin-bottom: 15px;
  color: var(--negro);
  font: 1.4rem var(--fontSt);
  font-weight: 400;
  text-align: center;
}

/*--WELCOME--*/
.welcomeDiv {
  width: 100%;
  margin: 10px auto 10px auto;
  padding: 5px 15px 15px 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--gris-lg);
  cursor: pointer;
}

.welcomeDiv:hover {
  background: var(--azul-cl);
  border: 1px solid var(--azul-st);
}

.welcomeImg {
  width: 100px;
  height: 100px;
  padding: 10px;
}

.welcomeImg img {
  height: 100%;
  opacity: 0.8;
}

.welcomeDiv:hover .welcomeImg img {
  -webkit-filter: invert(11%) sepia(100%) saturate(7331%) hue-rotate(217deg)
    brightness(98%) contrast(98%) !important;
          filter: invert(11%) sepia(100%) saturate(7331%) hue-rotate(217deg)
    brightness(98%) contrast(98%) !important;
}

.welcomeText {
  width: 100%;
  color: var(--negro);
  font: 1.4rem var(--fontSt);
  font-weight: 400;
  text-align: center;
}

.welcomeText p {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .welcomeDiv {
    padding: 20px 15px;
  }
  .welcomeImg {
    margin-right: 15px;
  }

  .welcomeText {
    width: calc(100% - 115px);
    text-align: left;
  }
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .Completado p {
    font: 1.6rem var(--fontSt);
    font-weight: 400;
  }
}

@media (min-width: 1200px) {
  .divContainer {
    padding: 70px 0px 70px 0;
  }

  .formData {
    max-width: 800px;
    min-width: 600px;
  }

  /*------PROGRES-----*/

  .formProgress ul li {
    width: 33.3%;
  }

  .formProgress ul li::before {
    top: -30px;
    right: calc(-50% + 22px);
    width: calc(100% - 44px);
  }

  /*---CONTENIDO----*/

  .formContent {
    padding: 40px;
  }

  .formProgress h3 {
    font: 2.4rem var(--fontSt);
    font-weight: 700;
  }

  .formTitle h1 {
    font: 2.4rem var(--fontSt);
    font-weight: 600;
  }
}

.ContLog {
  position: relative;
  width: 100%;
  background: var(--gris-lg);
}

.ContLogBd {
  min-height: 100vh;
  padding: 60px 15px 40px 15px;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.ContVerify {
  position: relative;
  padding: 160px 30px 60px 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 30px;
  overflow: hidden;
}

.ContHeads {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  background: var(--azul-st);
}

.ContVerify h3 {
  width: 100%;
  margin: 0 0 20px 0;
  color: var(--negro);
  font: 2.6rem var(--fontSt);
  font-weight: 700;
  text-align: center;
}

.ContVerify p {
  width: 100%;
  margin-bottom: 20px;
  font: 1.6rem var(--fontSt);
  font-weight: 400;
  text-align: center;
}

.ContVerify p button {
  background: transparent;
  color: var(--azul-lg);
  font: 1.6rem var(--fontSt);
}

.IconContext {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 80px;
  height: 80px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 25px 0px;
  z-index: 2;
}

.IconContext img {
  width: 100%;
}

.TextGreen {
  color: #00bb2d;
}

@media (min-width: 576px) {
  .ContLogBd {
    padding: 60px 40px 40px 40px;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 768px) {
  .ContLogBd {
    padding: 15px;
    align-items: center;
    justify-content: center;
  }

  .ContVerify {
    padding: 160px 60px 50px 60px;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

.noSee {
  display: none;
}

/*----CARDS BUTTON-----*/

.CardButtonsDiv {
  margin: 0px 0px 15px 0px;
  border-radius: 5px;
  border: 1px solid var(--gris);
  overflow: hidden;
  box-sizing: unset;
}

.ErrorButton {
  border: 1px solid var(--red);
}

.titleCardButton {
  width: 100%;
  padding: 10px 0;
  background: var(--azul-cl);
  color: var(--azul-st);
  font: 1.4rem var(--fontSt);
  font-weight: 700;
  text-align: center;
}

.cards-buttons {
  display: flex;
}

.card-fee {
  width: 50%;
  padding: 20px 15px;
  background: #fff;
  cursor: pointer;
  text-align: center;
  transition: all 0.2s ease-in-out;
}

.card-fee label {
  display: block;
  color: var(--azul-st);
  font: 2rem var(--fontSt);
  font-weight: 700;
}

.card-fee span {
  margin-bottom: 10px;
  display: block;
  color: var(--negro);
  font: 1.4rem var(--fontSt);
  font-weight: 700;
}

.card-fee p {
  color: var(--gris);
  font: 1.2rem var(--fontSt);
  font-weight: 400;
}

.card-fee:hover {
  background: var(--azul-lg);
}
.card-fee:hover label {
  color: var(--amarillo);
}
.card-fee:hover p,
.card-fee:hover span {
  color: #fff;
}

.cardActive {
  background: var(--azul-st) !important;
}
.cardActive label {
  color: var(--amarillo) !important;
}
.cardActive p,
.cardActive span {
  color: #fff !important;
}

.cardsOne{
  width: 100% !important;
}

.spnTached{
  text-decoration: line-through;
}

.cardUnabled{
  position: relative;
  padding: 20px 15px !important;
  background: #f7f7f7 !important;
  cursor: not-allowed !important;
  overflow: hidden;
}

.cardUnabled::before{
  content: 'No Disponible';
  position: absolute;
  padding: 5px 0px;
  right: -36%;
  top: 20px;
  width: 100%;
  background: #4d4d4d;
  color: #fff;
  transform: rotate(45deg);
  text-align: center;
  font: 1rem var(--fontSt);
}

.cardUnabled label,
.cardUnabled span,
.cardUnabled:hover label,
.cardUnabled:hover p,
.cardUnabled:hover span {color: #4d4d4d;}

.cardUnabled p {color:#4d4d4d;}
.cardUnabled:hover {background: #f7f7f7;}
.cardUnabled:hover::before{
  background: var(--red) !important;
}

/*----RESUMEN-----*/

.containerResumen {
  position: fixed;
  width: 100%;
  height: 183px;
  left: 0px;
  bottom: 0px;
  padding: 20px 15px 5px 15px;
  background: #ffffff;
  box-shadow: 0 -10px 8px rgba(0, 0, 0, 0.07);
  z-index: 2;
  transition: all 0.2s ease-in-out;
}

.resumenActivo {
  height: auto !important;
  padding: 20px 15px;
  transition: all 0.2s ease-in-out;
}

.containerResumen::after {
  content: "";
  position: absolute;
  height: 44px;
  width: 44px;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  border-radius: 100%;
  background: #fff;
  box-shadow: 0 -10px 8px rgba(0, 0, 0, 0.07);
}

.containerResumen::before {
  content: "";
  position: absolute;
  height: 20px;
  width: 100px;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  background: #fff;
}

.botonResumen {
  position: absolute;
  top: -12px;
  height: 20px;
  left: 50%;
  transform: translateX(-50%) rotate(0deg);
  z-index: 1;
  transition: all 0.2s ease-in-out;
}

.rotateArrow {
  transform: translateX(-50%) rotate(180deg);
}

.nueva-resumen {
  width: 100%;
  margin: 0px;
  padding: 30px;
  border-radius: 10px;
  background: #f5f5f5;
  color: var(--negro);
}

.nueva-resumen p {
  font: 1.8rem var(--fontSt);
  font-weight: 700;
}

.DatosIndividuales {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  font: 1.35rem var(--fontSt);
  font-weight: 500;
}

.DatosIndividuales:last-child {
  margin: 0;
}

.DatosPromo{
  display: flex;
  width: 40%;
  flex-wrap: wrap;
  justify-content: right;
}

.DatosPromo span:first-child{
  color: red !important;
  font: 1.2rem var(--fontSt);
  text-decoration: line-through;
}

.DatosPromo span:last-child{
  font-weight:700;
  width: 100%;
  text-align: right;
}

.span-rigth {
  text-align: right;
}

.DatosIndividuales label {
  display: block;
  font: 1.2rem var(--fontSt);
  font-weight: 400;
}

.valorRecibido {
  font-weight: 700;
}

.valorNegativo {
  color: #ea2a34;
  font-weight: 700;
}

.TotalPagar {
  font-weight: 700;
}

.totales {
  position: relative;
  margin: 0px 0px 15px 0px;
  padding: 15px 20px;
  border-radius: 10px;
  background-color: var(--azul-cl);
  color: var(--azul-st);
  font: 1.6rem var(--fontSt);
  font-weight: 700;
}

.totales span {
  display: block;
}

.totales span:first-child {
  font: 1.4rem var(--fontSt);
  font-weight: 500;
}

.negativo {
  color: #ea2a34 !important;
  background-color: #fdeeee;
}

.valorMinimo{
  position: absolute;
  bottom: 5px;
  right: 15px;
  color: #ea2a34 !important;
  font: 1.2rem var(--fontSt);
  font-weight: 500;
}

.Cuentas {
  width: 100%;
  margin: 10px 0 0 0;
  padding: 30px;
  border-radius: 10px;
  background-color: var(--azul-cl);
  color: var(--azul-st);
}

.Cuentas p {
  font: 1.4rem var(--fontSt);
  font-weight: 500;
}

.Cuentas p span {
  margin-right: 5px;
  font-weight: 700;
}

.CuentasBanco {
  font: 2rem var(--fontSt) !important;
  font-weight: 700 !important;
}
.CuentasTipo {
  margin-bottom: 15px;
  font: 1.6rem var(--fontSt) !important;
  font-weight: 500 !important;
}

/*RECOMENDACIONES*/

.Recomendacion{
  width: 100%;
  margin: 0px;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 236, 0, .15);
  font: 1.4rem var(--fontSt);
  font-weight: 400;
  cursor: pointer;
}

.Recomendacion img{
  margin-right: 10px;
  width: 30px;
}

.Recomendacion span{
  font: 1.6rem var(--fontSt);
  font-weight: 700;
}

.Recomendacion p{
  margin-top: 5px;
}

.Recomendacion:hover{
  background: rgba(255, 236, 0, .6);
}

/*REFERIDO*/

.DivReferido{
  width: 100%;
  margin-top: 15px;
  padding: 30px 20px 20px 20px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  font: 1.4rem var(--fontSt);
  font-weight: 400;
}

.DivReferido p{
  margin-bottom: 10px;
}

.checkCode{
  position: absolute;
  height: 20px;
  width: 20px;
  top: 8px;
  right: 15px;
  -webkit-filter: invert(55%) sepia(93%) saturate(411%) hue-rotate(82deg) brightness(85%) contrast(97%);
          filter: invert(55%) sepia(93%) saturate(411%) hue-rotate(82deg) brightness(85%) contrast(97%);
  pointer-events: none;
  z-index: 1;
}

.inputDisabled{
  position: relative;
  height: 40px;
  width: 100%;
  padding: 6px 12px 6px 12px;
  margin: 15px 0px;
  border-radius: 5px;
  background: #f7f7f7;
  color: #0e0e0e !important;
  border: solid 1px var(--gris-lg);
  font: 1.6rem var(--fontSt);
  font-weight: 400;
  display: flex;
  align-items: center;
}

.inputDisabled label{
  position: absolute;
  top: 10px;
  left: -3px;
  margin-left: 10px;
  padding: 0px 6px;
  background: #f7f7f7;
  border-radius: 3px 3px 0px 0px;
  transform: translate3d(0, -18px, 0);
  color: var(--gris);
  font: 1.3rem var(--fontSt);
  font-weight: 600;
  pointer-events: none;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}

.listaAzul{
  color: var(--azul-lg) !important;
}


@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .containerResumen {
    position: relative;
    height: auto;
    bottom: inherit;
    padding: 0px;
    background: transparent;
    box-shadow: none;
  }

  .resumenActivo {
    padding: 0px;
  }

  .containerResumen::after,
  .containerResumen::before {
    display: none;
  }

  .botonResumen {
    display: none;
  }

  .totales {
    margin: 10px 0;
    padding: 20px 30px;
  }
}

@media (min-width: 992px) {
  .CardButtonsDiv:last-child {
    margin: 0px 0px 0px 0px;
  }
}

@media (min-width: 1200px) {
}

.input-search {
    position: relative;
    padding: 10px 0px;
    margin: 5px 0px;
}

.input-search input {
    height: 40px;
    width: 100%;
    padding: 6px 12px 6px 12px;
    border-radius: 5px;
    background: #ffffff;
    color: var(--negro);
    border: solid 1px var(--gris-lg);
    box-shadow: none;
    font: 1.6rem var(--fontSt), sans-serif;
    font-weight: 400;
}

.input-search .BorderSelec {
    border-radius: 5px 5px 0px 0px !important;
}

.input-search  input:hover{
    border: solid 1px var(--azul-lg) !important;
}

.input-search input:focus {
    outline: none;
    border: solid 1px var(--azul-lg);
    box-shadow: none;
}

.input-search label {
    position: absolute;
    top: 20px;
    left: 5px;
    margin-left: 10px;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease-in-out;
    color: #909090;
    font: 1.6rem var(--fontSt);
    font-weight: 400;
    pointer-events: none;
    z-index: 2;
}

.input-search:hover label,
.input-search input:focus + label {
    left: -3px;
    padding: 0px 6px;
    transform: translate3d(0, -18px, 0);
    background: #fff;
    color: var(--azul-lg);
    font: 1.3rem var(--fontSt), sans-serif;
    font-weight: 600;
}

.input-search input:not(:-ms-input-placeholder) + label {
    left: -3px;
    padding: 0px 6px;
    transform: translate3d(0, -18px, 0);
    background: #fff;
    color: var(--azul-lg);
    font: 1.3rem var(--fontSt), sans-serif;
    font-weight: 600;
}

.input-search input:not(:placeholder-shown) + label {
    left: -3px;
    padding: 0px 6px;
    transform: translate3d(0, -18px, 0);
    background: #fff;
    color: var(--azul-lg);
    font: 1.3rem var(--fontSt), sans-serif;
    font-weight: 600;
}

.input-search input:not(:-ms-input-placeholder) {
    outline: none;
    box-shadow: none !important;
    background: transparent !important;
}

.input-search input:not(:placeholder-shown) {
    outline: none;
    box-shadow: none !important;
    background: transparent !important;
}

.searchedDiv {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    max-height: 120px;
    overflow-y: hidden; 
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 0px 0px 5px 5px !important;
    color: var(--negro);
    background: #fff;
    font: 1.6rem var(--fontSt), sans-serif;
    font-weight: 400;
    z-index: 9;
}

.searched {
    width: 100%;
    height: 40px;
    padding: 10px 12px;
    text-align: left;
    background: #f1f1f1;
    border-left: 1px solid var(--gris-lg);
    border-right: 1px solid var(--gris-lg);
    border-bottom: 1px solid var(--gris-lg);
    cursor: pointer;
}

.searched:hover {
    background: var(--azul-lg);
    border-left: 1px solid var(--azul-lg);
    border-right: 1px solid var(--azul-lg);
    color: #fff;
}

.buttonInput {
    position: absolute;
    top: 10px;
    right: 0px;
    padding: 0px 12px 0px 10px;
    height: 40px;
    background: transparent;
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;
}

.buttonInput img{
    opacity: 0.3;
    width: 24px;
}

.inputButtonDiv{
    width: 100%;
    position: relative;
    padding: 10px 0px;
    margin: 5px 0px;
    overflow: hidden;
    display: flex;
}

.inputButton{
    position: relative;
    width: 100%;
}

.inputButton input{
    width: 100%;
    position: relative;
    height: 40px;
    padding: 6px 12px 6px 12px;
    border-radius: 5px 0px 0px 5px;
    background: #ffffff;
    color: var(--negro);
    border: solid 1px var(--gris-lg);
    box-shadow: none;
    font: 1.6rem var(--fontSt);
    font-weight: 400;
}

.inputButton:hover input{
    border: solid 1px var(--azul-lg) !important;
}

.inputButton input:focus{
    outline: none;
    border: solid 1px var(--azul-lg);
    box-shadow: none;
}

.inputButton label {
    position: absolute;
    top: 10px;
    left: 5px;
    margin-left: 10px;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease-in-out;
    color: #909090;
    font: 1.6rem var(--fontSt);
    font-weight: 400;
    pointer-events: none;
    z-index: 2;
}

.inputButton input:focus + label{
    left: -3px;
    padding: 0px 6px;
    transform: translate3d(0, -18px, 0);
    background: #fff;
    color: var(--azul-lg);
    font: 1.3rem var(--fontSt);
    font-weight: 600;
}

.inputButton input:not(:-ms-input-placeholder) + label {
    left: -3px;
    padding: 0px 6px;
    transform: translate3d(0, -18px, 0);
    background: #fff;
    color: var(--azul-lg);
    font: 1.3rem var(--fontSt);
    font-weight: 600;
}

.inputButton input:not(:placeholder-shown) + label {
    left: -3px;
    padding: 0px 6px;
    transform: translate3d(0, -18px, 0);
    background: #fff;
    color: var(--azul-lg);
    font: 1.3rem var(--fontSt);
    font-weight: 600;
}

.inputButton input:not(:-ms-input-placeholder){
    outline: none;
    box-shadow: none !important;
    border: solid 1px var(--azul-lg) !important;
    background: transparent !important;
}

.inputButton input:not(:placeholder-shown){
    outline: none;
    box-shadow: none !important;
    border: solid 1px var(--azul-lg) !important;
    background: transparent !important;
}

.inputButtonDiv button{
    width: 150px;
    padding: 6px 12px 6px 12px;
    color: var(--blanco);
    background: var(--azul-st);
    border-radius: 0px 5px 5px 0px;
    font: 1.6rem var(--fontSt);
    font-weight: 400;
}

.inputButtonDiv button:hover{
    background: var(--azul-lg);
}

.spnAlert {
    position: absolute;
    bottom: -8px;
    left: 10px;
    padding: 2px 5px;
    border-radius: 3px;
    background: #fdeeee;
    color: #d63830;
    font: 1.1rem var(--fontSt);
    font-weight: 500;
    z-index: 1;
    transition: all 0.4s ease;
  }

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

/* @font-face {
  font-family: "Barlow";
  src: url("./fonts/Barlow-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: "black";
}

@font-face {
  font-family: "Barlow";
  src: url("./fonts/Barlow-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: "bold";
}

@font-face {
  font-family: "Barlow";
  src: url("./fonts/Barlow-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: "light";
}

@font-face {
  font-family: "Barlow";
  src: url("./fonts/Barlow-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: "medium";
}

@font-face {
  font-family: "Barlow";
  src: url("./fonts/Barlow-Regular.ttf") format("truetype");
  font-weight: 400;
} */

html {
  height: 100%;
  font-size: 62.5%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: border-box !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--fontSt);
}

button {
  cursor: pointer;
  border: none;
}

button:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

a,
a:hover,
a:visited {
  text-decoration: none !important;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

p {
  padding: 0;
  margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/*--LOADING--*/
.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.pageLoader {
  height: 100vh !important;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid var(--azul-lg);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/*WHATSAPP*/

.whatsapp {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 44px;
  padding: 10px 10px 10px 16px;
  margin: 0px 0px 70px 0px;
  border-radius: 0 22px 22px 0;
  background-color: #00bb2d;
  z-index: 99;
}

.whatsapp img {
  width: 100%;
  height: 100%;
  -webkit-filter: invert(1);
          filter: invert(1);
}

/*VIDEOS*/

.video-responsive{
  height: 0;
  width: 100%;
  max-width: 1000px;
  overflow: hidden;
  padding-bottom: 280px;
  padding-top: 30px;
  margin: 5px auto 5px auto;
  position: relative;
}

.video-responsive iframe, 
.video-responsive object, 
.video-responsive embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/*AUSTRAC*/

.austrac{
  display: none;
}

@media (min-width: 768px) {
  /*WHATSAPP*/
  .whatsapp {
    left: inherit;
    right: 0;
    width: 50px;
    height: 50px;
    padding: 12px;
    margin: 0px 20px 20px 0;
    border-radius: 25px;
  }

  /*VIDEOS*/
  .video-responsive{
    padding-bottom: 300px;
  }

}


@media (min-width: 992px) {

  .video-responsive{
    margin: 24px auto 20px auto;
    padding-bottom: 500px;
  }

  .austrac{
    position: fixed;
    bottom: 140px;
    left: -110px;
    width: 270px;
    padding: 10px 20px 10px 20px;
    border-radius: 0 0px 5px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 30px 0px;
    transform: rotate(-90deg);
    background-color: #FFFFFF;
    color: #2e2e2e;
    font: 0.9rem var(--fontSt);
    font-weight: 700;
    text-transform: uppercase;
    z-index: 99;
  }

  .austrac img{
    height: 32px;
    margin-left: 20px;
  }
  
}

:root {
  --azul-st: #0018b4;
  --azul-lg: #035af4;
  --azul-cl: #e8f7ff;
  --blanco: #fff;
  --amarillo: #ffec00;
  --negro: #141414;
  --gris: #929bac;
  --gris-lg: #ececec;
  --gris-m: #b4b4b4;
  --fontSt: "Barlow", sans-serif;
  --red: #d63830;
  --red-cl: #fdeeee;
  --verde: #1bb63f;
}

/* ------ BOTONES ---------*/
.boton-general {
  padding: 22px 20px;
  margin: 10px 0px;
  border-radius: 8px;
  background: #0018b4;
  background: var(--azul-st);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 6px 30px -10px rgba(64, 95, 244, 1);
  color: #fff;
  font: 1.5rem "Barlow", sans-serif, sans-serif;
  font: 1.5rem var(--fontSt), sans-serif;
  font-weight: 400;
  line-height: 0;
  white-space: nowrap;
  transition: all 0.4s ease;
}

#BtnGray {
  background: #ececec !important;
  background: var(--gris-lg) !important;
  color: #929bac;
  color: var(--gris);
  box-shadow: none !important;
  cursor: not-allowed;
}

#BtnGray img {
  -webkit-filter: invert(0.8);
          filter: invert(0.8);
}

.BtnLarge {
  width: 100% !important;
}

.boton-general:hover {
  background: #035af4;
  background: var(--azul-lg);
}

.boton-general img {
  -webkit-filter: invert(1);
          filter: invert(1);
  width: 18px;
  height: 18px;
  margin: -10px -5px;
}

.imgButton-left img {
  margin-right: 3px !important;
}

.imgButton-rigth img {
  margin-left: 3px !important;
}

.boton-agregar {
  width: calc(100% - 20px);
  padding: 10px 25px;
  margin: 10px 10px;
  border-radius: 30px;
  background-color: #000629;
  color: #fff;
  font: 1.5rem "Barlow", sans-serif;
  font: 1.5rem var(--fontSt);
  font-weight: 300;
}

.botones-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.text-buton {
  text-decoration: none;
  color: #0018b4;
  color: var(--azul-st);
  background-color: transparent;
  padding: 15px 15px;
  display: block;
  font: 1.8rem "Barlow", sans-serif;
  font: 1.8rem var(--fontSt);
}

.btn-text {
  margin: 0 auto;
  width: 100%;
  padding: 10px;
  background: transparent;
  color: #929bac;
  color: var(--gris);
  text-align: center;
  font: 1.4rem "Barlow", sans-serif;
  font: 1.4rem var(--fontSt);
  font-weight: 500;
}

.btn-text:hover {
  color: #141414;
  color: var(--negro);
}

.BtnText {
  text-decoration: none;
  color: #0018b4;
  color: var(--azul-st);
  background-color: transparent;
  padding: 15px 15px;
  display: block;
  font: 1.8rem "Barlow", sans-serif;
  font: 1.8rem var(--fontSt);
}

/*INPUTS GENERALES*/

/*DISABLED*/
.input-disabled input,
.input-disabled textarea {
  height: 40px;
  width: 100%;
  padding: 6px 12px 6px 12px;
  border-radius: 5px;
  background: #f7f7f7;
  color: #1a1a1a !important;
  border: solid 1px #ececec;
  border: solid 1px var(--gris-lg);
  box-shadow: none;
  font: 1.6rem "Barlow", sans-serif;
  font: 1.6rem var(--fontSt);
  font-weight: 400;
}

.input-disabled label {
  position: absolute;
  top: 20px;
  left: -3px;
  margin-left: 10px;
  padding: 0px 6px;
  background: #f7f7f7;
  border-radius: 3px 3px 0px 0px;
  transform: translate3d(0, -18px, 0);
  transition: all 0.2s ease-in-out;
  color: #929bac;
  color: var(--gris);
  font: 1.3rem "Barlow", sans-serif;
  font: 1.3rem var(--fontSt);
  font-weight: 600;
  pointer-events: none;
  z-index: 2;
}

.input-disabled input:not(:-ms-input-placeholder) + label, .input-disabled textarea:not(:-ms-input-placeholder) + label {
  color: #035af4;
  color: var(--azul-lg);
}

.input-disabled input:not(:placeholder-shown) + label,
.input-disabled textarea:not(:placeholder-shown) + label {
  color: #035af4;
  color: var(--azul-lg);
}

.inpDisNegative input{
  background: #fdeeee !important;
  background: var(--red-cl) !important;
  border: solid 1px #d63830 !important;
  border: solid 1px var(--red) !important;
  color: #d63830 !important;
  color: var(--red) !important;
}

.inpDisNegative label{
  color: #d63830 !important;
  color: var(--red) !important;
  background: #fdeeee !important;
  background: var(--red-cl) !important;
}

.input-select {
  position: relative;
  padding: 10px 0px;
  margin: 5px 0px;
}

.input-disabled,
.input-general,
.input-date {
  position: relative;
  padding: 10px 0px;
  margin: 5px 0px;
  overflow: hidden;
}

.input-fix {
  position: relative;
  padding: 10px 0px;
  margin: 5px 0px;
}

.input-general input,
.input-date input,
.input-general textarea {
  height: 40px;
  width: 100%;
  padding: 6px 12px 6px 12px;
  border-radius: 5px;
  background: #ffffff;
  color: #141414;
  color: var(--negro);
  border: solid 1px #ececec;
  border: solid 1px var(--gris-lg);
  box-shadow: none;
  font: 1.6rem "Barlow", sans-serif;
  font: 1.6rem var(--fontSt);
  font-weight: 400;
}

.input-general textarea {
  padding: 10px 12px 6px 12px !important;
  resize: none;
}

.input-general:hover input,
.input-date:hover input,
.input-general:hover textarea {
  border: solid 1px #035af4 !important;
  border: solid 1px var(--azul-lg) !important;
}

.input-disabled input:focus,
.input-general input:focus,
.input-date input:focus,
.input-general textarea:focus {
  outline: none;
  border: solid 1px #035af4;
  border: solid 1px var(--azul-lg);
  box-shadow: none;
}

.input-date:hover label,
.input-date input:focus + label {
  color: #035af4 !important;
  color: var(--azul-lg) !important;
}

.input-general label {
  position: absolute;
  top: 20px;
  left: 5px;
  margin-left: 10px;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease-in-out;
  color: #909090;
  font: 1.6rem "Barlow", sans-serif;
  font: 1.6rem var(--fontSt);
  font-weight: 400;
  pointer-events: none;
  z-index: 2;
}

.input-general input:focus + label,
.input-general textarea:focus + label {
  left: -3px;
  padding: 0px 6px;
  transform: translate3d(0, -18px, 0);
  background: #fff;
  color: #035af4;
  color: var(--azul-lg);
  font: 1.3rem "Barlow", sans-serif;
  font: 1.3rem var(--fontSt);
  font-weight: 600;
}

.input-img {
  position: absolute;
  height: 20px;
  width: 20px;
  top: 20px;
  right: 15px;
  opacity: 0.3;
  pointer-events: none;
  z-index: 1;
}

.input-date::after {
  content: "";
  background: url(/static/media/calendar.1e18c936.svg);
  background-position: center center;
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  height: 20px;
  width: 20px;
  top: 20px;
  right: 15px;
  opacity: 0.3;
  cursor: pointer;
  pointer-events: none;
  z-index: 1;
}

.input-date::before {
  content: "";
  position: absolute;
  top: 18px;
  right: 12px;
  height: 25px;
  width: 25px;
  background-color: #fff;
  pointer-events: none;
}

.input-date label {
  position: absolute;
  top: 20px;
  left: -3px;
  margin-left: 10px;
  padding: 0px 6px;
  transform: translate3d(0, -18px, 0);
  background: #fff;
  color: #c7c7c7;
  font: 1.3rem "Barlow", sans-serif;
  font: 1.3rem var(--fontSt);
  font-weight: 600;
  pointer-events: none;
  z-index: 2;
}

.ligthBlue {
  color: #035af4 !important;
  color: var(--azul-lg) !important;
}

.BorderBlue {
  border: solid 1px #035af4 !important;
  border: solid 1px var(--azul-lg) !important;
}

.input-general input:not(:-ms-input-placeholder) + label, .input-general textarea:not(:-ms-input-placeholder) + label {
  left: -3px;
  padding: 0px 6px;
  transform: translate3d(0, -18px, 0);
  background: #fff;
  color: #035af4;
  color: var(--azul-lg);
  font: 1.3rem "Barlow", sans-serif;
  font: 1.3rem var(--fontSt);
  font-weight: 600;
}

.input-general input:not(:placeholder-shown) + label,
.input-general textarea:not(:placeholder-shown) + label {
  left: -3px;
  padding: 0px 6px;
  transform: translate3d(0, -18px, 0);
  background: #fff;
  color: #035af4;
  color: var(--azul-lg);
  font: 1.3rem "Barlow", sans-serif;
  font: 1.3rem var(--fontSt);
  font-weight: 600;
}

.input-general input:not(:-ms-input-placeholder), .input-general textarea:not(:-ms-input-placeholder) {
  outline: none;
  box-shadow: none !important;
  border: solid 1px #035af4 !important;
  border: solid 1px var(--azul-lg) !important;
  background: transparent !important;
}

.input-general input:not(:placeholder-shown),
.input-general textarea:not(:placeholder-shown) {
  outline: none;
  box-shadow: none !important;
  border: solid 1px #035af4 !important;
  border: solid 1px var(--azul-lg) !important;
  background: transparent !important;
}

.input-general-disabled {
  position: relative;
  padding: 10px 10px;
  overflow: hidden;
}

.input-general-disabled input:disabled {
  height: 40px;
  width: 100%;
  padding: 6px 12px 6px 12px;
  border-radius: 5px;
  box-shadow: none;
  background: #f4f4f4;
  border: solid 1px #f4f4f4;
  color: #777777;
  font: 1.5rem "Inter";
  font-weight: 400;
}

.input-button {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}
.input-button input {
  height: 40px;
  width: 100%;
  padding: 15px 10px;
  border-radius: 5px 0px 0px 5px;
  border: solid 1px #dedede;
  box-shadow: none;
  color: #1a1a1a;
  font: 14px "Barlow", sans-serif;
  font: 14px var(--fontSt);
  font-weight: 400;
}

.input-button input:focus {
  outline: none;
  box-shadow: none;
  border: solid 1px #035af4;
  border: solid 1px var(--azul-lg);
}

.input-button button {
  width: 100px;
  padding: 10px 10px;
  border-radius: 0px 5px 5px 0px;
  background: #bababa;
  color: #ffffff;
  font: 15px "Barlow", sans-serif;
  font: 15px var(--fontSt);
}

.btn-desactivado {
  background: #bababa !important;
}

.validacionA {
  position: absolute;
  bottom: -6px;
  left: 10px;
  padding: 0px 5px;
  border-radius: 5px;
  background: #ea2a34;
  color: #fff;
  font: 11px "Barlow", sans-serif;
  font: 11px var(--fontSt);
  display: none;
}
.validacionB {
  position: absolute;
  bottom: -6px;
  left: 10px;
  padding: 0px 5px;
  border-radius: 5px;
  background: #62b043;
  color: #fff;
  font: 11px "Barlow", sans-serif;
  font: 11px var(--fontSt);
  display: none;
}

.input-label:disabled {
  height: 40px;
  width: 100%;
  padding: 6px 12px 6px 12px;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: none;
  background: #f4f4f4;
  border: solid 1px #f4f4f4;
  color: #777777;
  font: 15px "Barlow", sans-serif;
  font: 15px var(--fontSt);
  font-weight: 400;
}

/*----CHECK BOX DE FORMULARIOS */

.CheckBoxs {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.NewCheckbox {
  position: relative;
  width: 50%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.NewCheckbox:last-child {
  margin-right: 0;
}

.NewCheckbox label {
  font: 1.4rem "Barlow", sans-serif;
  font: 1.4rem var(--fontSt);
}

.NewCheckbox input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin: 0px 10px 0px 10px !important;
  opacity: 0;
  cursor: pointer;
}

.NewCheckbox input + label::before {
  position: absolute;
  top: 0;
  left: 0;
  content: url(/static/media/check.d0260239.svg);
  width: 20px;
  height: 20px;
  border: 2px solid #ececec;
  background: #fff;
  border-radius: 20%;
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
}

.NewCheckbox input:hover + label::before {
  position: absolute;
  top: 0;
  left: 0;
  content: url(/static/media/check.d0260239.svg);
  width: 20px;
  height: 20px;
  border: 2px solid #035af4;
  border: 2px solid var(--azul-lg);
  background: #fff;
  border-radius: 20%;
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.NewCheckbox input[type="checkbox"]:checked + label::before {
  position: absolute;
  top: 0;
  left: 0;
  content: url(/static/media/check.d0260239.svg);
  width: 20px;
  height: 20px;
  background: #035af4;
  background: var(--azul-lg);
  border: 2px solid #035af4;
  border: 2px solid var(--azul-lg);
  border-radius: 20%;
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
  transform: scale(1);
  cursor: pointer;
}

.checkboxContent{
  display: flex;
  width: 100px;
}

.divPep{
  position: relative;
  font: 1.4rem "Barlow", sans-serif;
  font: 1.4rem var(--fontSt);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border: solid 1px #ececec;
  border: solid 1px var(--gris-lg);
  border-radius: 5px;
  padding: 10px;
}

.divPepWarn{
  position: relative;
  font: 1.4rem "Barlow", sans-serif;
  font: 1.4rem var(--fontSt);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border: solid 1px #d63830;
  border: solid 1px var(--red);
  border-radius: 5px;
  padding: 10px;
}

.divPep span, .divPepWarn span{
  left: 50% !important;
  transform: translateX(-50%);
  bottom: -5px !important;
}

.checkbox {
  position: relative;
  width: 100%;
  margin: 15px 0px;
  font: 1.4rem "Barlow", sans-serif;
  font: 1.4rem var(--fontSt);
}

.checkbox input[type="checkbox"], 
.checkbox input[type="radio"]  {
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  margin: 0px 10px 0px 10px !important;
  opacity: 0;
  cursor: pointer;
}

.checkboxlabel {
  padding: 0 10px 0 40px;
  margin-bottom: 30px;
}

.checkboxBtm{
  margin-bottom: 0 !important;
}

.checkbox label {
  position: relative;
  pointer-events: none;
}

.checkbox input + label::before {
  position: absolute;
  top: 0;
  left: 0;
  content: url(/static/media/check.d0260239.svg);
  width: 20px;
  height: 20px;
  border: 2px solid #ececec;
  background: #fff;
  border-radius: 20%;
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
}

.checkbox input:hover + label::before {
  position: absolute;
  top: 0;
  left: 0;
  content: url(/static/media/check.d0260239.svg);
  width: 20px;
  height: 20px;
  border: 2px solid #035af4;
  border: 2px solid var(--azul-lg);
  background: #fff;
  border-radius: 20%;
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
}

.checkbox input[type="checkbox"]:checked + label::before, 
.checkbox input[type="radio"]:checked + label::before{
  position: absolute;
  top: 0;
  left: 0;
  content: url(/static/media/check.d0260239.svg);
  width: 20px;
  height: 20px;
  background: #035af4;
  background: var(--azul-lg);
  border: 2px solid #035af4;
  border: 2px solid var(--azul-lg);
  border-radius: 20%;
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
  transform: scale(1);
  pointer-events: none;
}

.checkbox a {
  color: #035af4;
  color: var(--azul-lg);
  background: transparent;
  font: 1.4rem "Barlow", sans-serif;
  font: 1.4rem var(--fontSt);
  font-weight: 600;
  padding: 0 !important;
  cursor: pointer;
}

.spanAlert {
  position: absolute;
  bottom: 0px;
  left: 10px;
  padding: 2px 5px;
  border-radius: 3px;
  background: #fdeeee;
  color: #d63830;
  font: 1.1rem "Barlow", sans-serif;
  font: 1.1rem var(--fontSt);
  font-weight: 500;
  z-index: 1;
  transition: all 0.4s ease;
}

.spanAlertCard {
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 2px 5px;
  border-radius: 3px;
  background: #fdeeee;
  color: #d63830;
  font: 1.1rem "Barlow", sans-serif;
  font: 1.1rem var(--fontSt);
  font-weight: 500;
  white-space: nowrap;
  z-index: 1;
  transition: all 0.4s ease;
}

.spanAlertCheck {
  display: block;
  text-align: center;
  padding: 2px 5px;
  border-radius: 3px;
  background: #fdeeee;
  color: #d63830;
  font: 1.1rem "Barlow", sans-serif;
  font: 1.1rem var(--fontSt);
  font-weight: 500;
  z-index: 1;
  transition: all 0.4s ease;
}

.redWarn {
  border: solid 1px #d63830 !important;
}

.redLineDi label {
  color: #d63830 !important;
}

.redLine input,
.redLine textarea {
  border: solid 1px #d63830 !important;
}

.redLine input:not(:-ms-input-placeholder), .redLine textarea:not(:-ms-input-placeholder) {
  border: solid 1px #d63830 !important;
}

.redLine input:not(:placeholder-shown),
.redLine textarea:not(:placeholder-shown) {
  border: solid 1px #d63830 !important;
}

.redLine input:not(:-ms-input-placeholder) + label, .redLine textarea:not(:-ms-input-placeholder) + label {
  color: #d63830 !important;
}

.redLine input:not(:placeholder-shown) + label,
.redLine textarea:not(:placeholder-shown) + label {
  color: #d63830 !important;
}

.handClick {
  cursor: pointer;
}



@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .CheckBoxs {
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .NewCheckbox {
    position: relative;
    width: auto;
    margin-right: 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .spanAlertCard {
    bottom: -7px;
  }
}

@media (min-width: 1200px) {
}

.banner-inicio {
  padding: 50px 0;
  display: flex;
  align-items: center;
  background: rgba(0, 24, 180, 1);
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(0, 24, 180, 1)),
    color-stop(100%, rgba(3, 71, 201, 1))
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 24, 180, 1) 0%,
    rgba(3, 71, 201, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0018b4', endColorstr='#0347c9', GradientType=0 );
}

.GeneralCont {
  margin: 0 auto;
  max-width: 1200px;
}

.MainTitle {
  margin: 30px 0px 20px 0px;
  color: var(--azul-st);
  font: 2.8rem var(--fontSt);
  font-weight: 700;
}

.SubTitle {
  margin: 40px 0px 10px 0px;
  font: 1.6rem var(--fontSt);
  font-weight: 700;
}

.TextGeneral {
  margin: 0px 0px 20px 0px;
  font: 1.6rem var(--fontSt);
  font-weight: 400;
  text-align: justify;
}

.ulGeneral {
  padding: 0 0 0 20px;
  margin: 0px 0px 20px 0px;
  font: 1.6rem var(--fontSt);
  font-weight: 400;
  text-align: justify;
}

.SectionTitle {
  width: 100%;
  margin: 0px 0px 15px 0px;
  color: var(--azul-st);
  font: 2.8rem var(--fontSt);
  font-weight: 700;
}

.SectionText {
  width: 100%;
  padding: 0 15px;
  margin: 0px 0px 30px 0px;
  font: 1.6rem var(--fontSt);
  font-weight: 400;
}

.TxtCenter {
  text-align: center;
}

/*--CURRENCY CONVERTER--*/

.contGeneral {
  width: 100%;
  min-height: 65vh;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px 30px 20px;
}

.contCurrency {
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px 0px 20px;
}

.containerCurrency {
  width: 100%;
  max-width: 1000px;
  padding: 20px 25px;
  margin: 0 auto;
  border-radius: 8px;
  background-color: #fff;
}

/*--FEES--*/
.Fees {
  padding: 50px 0px;
}

.FeesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.FeesDiv {
  margin: 10px 15px;
  padding: 35px;
  max-width: 300px;
  border: 1px solid var(--gris-lg);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 30px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 5px 60px 0px;
  transition: all 0.2s ease-in-out;
}

.FeesDiv:hover {
  background: var(--azul-st);
}

.FeesDiv:hover .FeeName {
  color: var(--amarillo);
}

.FeesDiv:hover .FeeValor,
.FeesDiv:hover .FeeDesc span,
.FeesDiv:hover .FeeDesc {
  color: #fff !important;
}

.FeeName {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--azul-st);
  font: 2.8rem var(--fontSt);
  font-weight: 600;
}

.FeeName img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.FeeText {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.FeeValor {
  margin-bottom: 15px;
  color: var(--negro);
  font: 2.5rem var(--fontSt);
  font-weight: 600;
}

.FeeValor span {
  font: 1.8rem var(--fontSt);
  font-weight: 400;
}

.FeeDesc {
  max-width: 210px;
  margin-bottom: 10px;
  color: var(--negro);
  font: 1.4rem var(--fontSt);
  font-weight: 400;
}

.FeeDesc:last-child {
  margin-bottom: 0px;
}

.FeeDesc span {
  font-weight: 600;
  color: var(--negro);
}

.FeeCond {
  width: 100%;
  margin-top: 10px;
  color: #fff;
  font: 1rem var(--fontSt);
  text-align: center;
}

/*---------CONTACT FORM---------*/
.containerForm {
  padding: 50px 0px;
  display: flex;
  flex-wrap: wrap;
}

#textAreaContact {
  height: 100px;
}

#textAreaContact::-webkit-scrollbar {
  width: 7px;
}

#textAreaContact::-webkit-scrollbar-track {
  background-color: var(--gris-lg);
}

#textAreaContact::-webkit-scrollbar-thumb {
  background-color: var(--gris);
}

.DivSplit {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.Splitcontent {
  width: 100%;
}

.SplitImg {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
}

.SplitImg img {
  width: 250px;
}

/*-------TESTIMONIOS------------*/

.testimonios-inicio {
  padding: 50px 0px;
}

.testimonios {
  display: flex;
  justify-content: center;
  padding: 50px 0;
  width: 100%;
}

.testimonio {
  position: relative;
  width: 200px;
  padding: 30px 30px 100px 30px;
  margin: 20px;
  border-radius: 10px;
  background-color: var(--blanco);
  font: 1.6rem var(--fontSt);
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 5px 60px 0px;
  cursor: pointer;
}

.rate-name {
  position: absolute;
  left: 0;
  bottom: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 1.6rem var(--fontSt);
  font-weight: 600;
}

.rate-date {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  font: 1.2rem var(--fontSt);
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rate-stars {
  position: absolute;
  left: 0;
  bottom: 38px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divStars {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DivStar img {
  width: 16px;
  margin: 0 1px;
  -webkit-filter: invert(80%) sepia(100%) saturate(779%) hue-rotate(359deg)
    brightness(104%) contrast(105%);
          filter: invert(80%) sepia(100%) saturate(779%) hue-rotate(359deg)
    brightness(104%) contrast(105%);
}

.rate-coment {
  font: 1.4rem var(--fontSt);
  font-weight: 300;
}

/*CORE VALUES*/

.CoreValues {
  background-color: var(--blanco);
  margin: 25px auto 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.CoreValue {
  width: 100%;
  margin-bottom: 15px;
  padding: 20px 15px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border: 1px solid var(--gris-lg);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.Core50 {
  width: 100% !important;
}

.CoreImg {
  width: 100px;
  margin-right: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CoreImg img {
  height: 60px;
  -webkit-filter: invert(0.9);
          filter: invert(0.9);
  transition: all 0.2s ease-in-out;
}

.CoreText {
  width: 100%;
}

.CoreText h3 {
  margin: 0 0 10px 0;
  color: var(--azul-st);
  font: 1.8rem var(--fontSt);
  font-weight: 700;
}

.CoreText p {
  color: var(--negro);
  font: 1.4rem var(--fontSt);
  font-weight: 400;
}

.CoreText p span {
  font-weight: 700;
}

.CoreValue:hover {
  background: var(--azul-st);
}

.CoreValue:hover .CoreImg img {
  -webkit-filter: invert(0);
          filter: invert(0);
}

.CoreValue:hover p {
  color: #fff;
}

.CoreValue:hover h3 {
  color: var(--amarillo);
}

/*STEPS*/
.stepByStep {
  margin: 25px auto 0 auto;
  display: flex;
  flex-wrap: wrap;
}

.divStep {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  border: 1px solid var(--gris-lg);
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
  -webkit-filter: saturate(0);
          filter: saturate(0);
  transition: all 0.2s ease-in-out;
}

.divStepImg {
  width: 100%;
  height: 200px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--gris-lg);
}

.divStepImg img {
  height: 100%;
}

.divStepText {
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: flex-start;
}

.divStepText p {
  font: 1.4rem var(--fontSt);
  font-weight: 400;
}

.divStepText span {
  color: var(--azul-st);
  font: 3.8rem var(--fontSt);
  font-weight: 700;
  line-height: 3.2rem;
  margin-right: 10px;
}

.divStep:hover {
  -webkit-filter: saturate(1);
          filter: saturate(1);
}

.divStep:hover .divStepText {
  background: var(--azul-st);
}

.divStep:hover span {
  color: var(--amarillo);
}

.divStep:hover p {
  color: #fff;
}

/*INPUTS ACORDION*/

.Acordion {
  position: relative;
  margin-bottom: 15px;
  font: 1.4rem var(--fontSt);
  font-weight: 400;
}

.Acordion input {
  position: absolute;
  display: none;
}

.Acordion label {
  display: block;
  padding: 20px 0 20px 20px;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid var(--gris-lg);
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.Acordion:hover label {
  color: var(--azul-lg);
  background: var(--azul-cl);
  border: 1px solid var(--azul-lg);
}

.AcordionExpand {
  height: 0;
  overflow: hidden;
}

.Acordion input:checked ~ label {
  border-radius: 10px 10px 0px 0px;
  color: var(--azul-lg);
  background: var(--azul-cl);
  border: 1px solid var(--azul-lg);
}

.Acordion input:checked ~ .AcordionExpand {
  height: auto;
  padding: 20px;
  border-left: 1px solid var(--azul-lg);
  border-bottom: 1px solid var(--azul-lg);
  border-right: 1px solid var(--azul-lg);
  border-radius: 0px 0px 10px 10px;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .CoreValue {
    width: calc(33.3% - 20px);
    margin: 10px;
  }

  .Core50 {
    width: calc(50% - 20px) !important;
  }

  /*STEPS*/
  .divStep {
    width: calc(50% - 20px);
    margin: 0 10px 20px 10px;
  }

  /*CONTACT FORM*/
  .Splitcontent {
    width: 50%;
    padding: 0 10px;
  }

  .SplitImg {
    width: 50%;
    padding: 0 30px;
  }

  .SplitImg img {
    width: 350px;
  }
}

@media (min-width: 992px) {
  .FeesContainer {
    flex-wrap: nowrap;
  }

  .FeesDiv {
    width: calc(50% - 5px);
  }

  /*CORE VALUES*/

  .CoreValues {
    margin: 35px auto 0 auto;
  }

  .CoreValue {
    padding: 30px 20px;
  }

  .CoreImg {
    width: 100px;
    margin-right: 20px;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .CoreImg img {
    height: 80px;
  }

  .CoreText h3 {
    margin: 0 0 10px 0;
    font: 2rem var(--fontSt);
    font-weight: 700;
  }

  .CoreText p {
    font: 1.6rem var(--fontSt);
    font-weight: 400;
  }

  .MainTitle {
    margin-top: 50px;
    font: 3.5rem var(--fontSt);
    font-weight: 700;
  }

  .SectionTitle {
    font: 3.5rem var(--fontSt);
    font-weight: 700;
  }

  /*STEPS*/
  .divStep {
    width: calc(33.3% - 20px);
    margin: 0 10px 20px 10px;
  }

  .Acordion {
    position: relative;
    margin-bottom: 15px;
    font: 1.6em var(--fontSt);
    font-weight: 400;
  }
}

@media (min-width: 1200px) {
  .banner-inicio {
    padding: 100px 0;
  }
}

@media (min-width: 1370px) {
}

.ContDash {
  position: relative;
  width: 100%;
  min-height: 70vh;
  transition: all 0.4s ease;
}

.ContentDash {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  min-height: 70vh;
  padding: 80px 5px 50px 5px;
}

.ContPaginas {
  width: 100%;
  padding: 60px 0px 0px 0px;
}

.BlockScroll {
  pointer-events: none;
}

.MenuDash {
  position: fixed;
  background: #fff;
  height: 60px;
  width: 100%;
  z-index: 999;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.MenuDashCont {
  max-width: 1200px;
  padding: 0 15px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*------SALUDO----*/

.Saludo {
  padding-left: 20px;
  margin-bottom: 20px;
}

.Saludo h1 {
  margin: 0px;
  color: var(--negro);
  font: 2.8rem var(--fontSt);
  font-weight: 700;
  line-height: 2rem;
}

.Saludo span {
  font: 1.6rem var(--fontSt);
}

.Saludo p {
  margin: 20px 0px 10px 0px;
  color: var(--gris);
  font: 1.4rem var(--fontSt);
}

.dashboardCard {
  position: relative;
  padding: 20px;
  margin: 15px 15px;
  border-radius: 20px;
  background: var(--blanco);
  box-shadow: rgba(0, 0, 0, 0.14) 0px 5px 60px 0px;
  z-index: 1;
}

.CardTitulo {
  font: 1.6rem var(--fontSt);
  font-weight: 700;
  color: var(--gris);
  margin-bottom: 15px;
}

.TituloPag {
  margin: 15px 15px 30px 15px;
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.TituloPag p {
  font: 2.8rem var(--fontSt);
  color: var(--azul-st);
  font-weight: 700;
  line-height: 0;
}

.TituloPag a {
  background-color: transparent;
  height: 100%;
  align-items: center;
  margin-right: 10px;
}

.TituloPag a img {
  height: 30px;
}

/*REGISTROS VACIOS */

.itemTransferEmpty {
  margin: 0 auto;
  width: 100%;
  padding: 30px 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: var(--gris-lg);
  border: 2px dotted var(--gris);
  border-radius: 8px;
}

.itemTransferEmpty p {
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
  font: 1.6rem var(--fontSt);
  font-weight: 700;
}

.itemTransferEmpty span {
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
  font: 1.4rem var(--fontSt);
  font-weight: 400;
}

@media (min-width: 576px) {
  .ContentDash {
    padding: 80px 15px 20px 15px;
  }
}
@media (min-width: 768px) {
  .ContentDash {
    padding: 90px 20px 80px 20px;
  }
}
@media (min-width: 992px) {
  .MenuDash {
    height: 80px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .ContentDash {
    padding: 140px 30px 80px 30px;
  }

  .Saludo {
    padding-left: 10px;
  }

  .ContPaginas {
    width: 100%;
    padding: 80px 0px 0px 0px;
  }
}

@media (min-width: 1200px) {
  .MenuDash {
    height: 90px;
  }
  .ContentDash {
    padding: 150px 5px 50px 5px;
  }

  .ContPaginas {
    padding: 90px 0px 0px 0px;
  }
}

@media (min-width: 1370px) {
  .MenuDash {
    height: 100px;
  }

  .ContentDash {
    padding: 160px 0px 50px 0px;
  }

  .ContPaginas {
    padding: 100px 0px 0px 0px;
  }
}

/*!
 * Bootstrap Grid v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
/*# sourceMappingURL=bootstrap-grid.css.map */
