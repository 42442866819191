.divContainer {
  position: relative;
  width: 100%;
  min-height: 80vh;
  padding: 50px 15px 50px 15px;
  background: var(--gris-lg);
}

.divAling {
  display: flex;
  align-items: center !important;
  padding: 0px 15px 0px 15px !important;
}

/* .divContainer::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60%;
  background: rgba(0, 24, 180, 1);
  background: -moz-linear-gradient(
    top,
    rgba(0, 24, 180, 1) 0%,
    rgba(3, 71, 201, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(0, 24, 180, 1)),
    color-stop(100%, rgba(3, 71, 201, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 24, 180, 1) 0%,
    rgba(3, 71, 201, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 24, 180, 1) 0%,
    rgba(3, 71, 201, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(0, 24, 180, 1) 0%,
    rgba(3, 71, 201, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 24, 180, 1) 0%,
    rgba(3, 71, 201, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0018b4', endColorstr='#0347c9', GradientType=0 );
} */

.divSubContainer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.formData {
  position: relative;
  width: 100%;
  background: #fff;
  border-radius: 30px;
  z-index: 1;
}

/*------PROGRES-----*/

.formProgress {
  width: 100%;
  height: 120px;
  border-radius: 30px 30px 0 0;
  background: var(--azul-st);
  display: flex;
  align-items: center;
}

.formProgress ul {
  position: relative;
  width: 100%;
  padding: 50px 0 0 0 !important;
  display: flex;
  justify-content: center;
}

.formProgress ul li {
  position: relative;
  list-style: none;
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: #fff;
  font: 1.3rem var(--fontSt), sans-serif;
  font-weight: 500;
}

.formProgress ul li label {
  text-align: center;
  width: 100%;
  color: rgba(255, 255, 255, 0.5);
  font: 1rem var(--fontSt), sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}

.formProgress ul li span {
  position: absolute;
  top: -50px;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgba(255, 255, 255, 0.5);
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.formProgress ul li span img {
  height: 20px;
  filter: invert(0.6);
  opacity: 0.8;
}

.formProgress ul li::before {
  content: "";
  position: absolute;
  top: -30px;
  right: calc(-50% + 22px);
  width: calc(100% - 44px);
  height: 2px;
  background: rgba(255, 255, 255, 0.5);
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.formProgress ul li:last-child::before {
  content: "";
  width: 0;
}

.formProgress-active span,
.formProgress-current span {
  border-color: var(--amarillo) !important;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.formProgress-active label,
.formProgress-current label {
  color: var(--amarillo) !important;
}

.formProgress-current span img,
.formProgress-active span img {
  height: 20px !important;
  filter: invert(100%) sepia(100%) saturate(6647%) hue-rotate(344deg)
    brightness(95%) contrast(115%) !important;
  opacity: 1 !important;
}

.formProgress-active::before {
  content: "";
  position: absolute;
  top: -30px;
  right: calc(-50% + 22px);
  width: calc(100% - 44px);
  height: 2px;
  background: var(--amarillo) !important;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.formProgress h3 {
  width: 100%;
  color: var(--amarillo);
  font: 2rem var(--fontSt);
  font-weight: 700;
  text-align: center;
}

.formProgress h3 span {
  color: #ffffff;
}

/*---CONTENIDO----*/
.formContent {
  padding: 30px 20px;
}

.formTitle {
  margin: 0 0 20px 0;
  padding: 0px 0px 0px 0px;
}

.formTitle h1 {
  margin: 0px 0 10px 0;
  color: var(--azul-st);
  font: 2rem var(--fontSt);
  font-weight: 600;
}

.formTitle p {
  max-width: 500px;
  margin: 0 auto;
  color: var(--negro);
  font: 1.4rem var(--fontSt);
  font-weight: 400;
  text-align: center;
}

/*----PASO A PASO-----*/

.step {
  width: 100%;
  padding: 0px 0px 0px 0px;
}

.habil {
  display: block !important;
}

.step1,
.step2,
.step3,
.step4 {
  display: none;
}

/*---BUTTONS--*/
.formButtons {
  margin-top: 20px;
  display: flex;
}

.BtnsRigth {
  justify-content: flex-end;
}

.BtnsSpace {
  justify-content: space-between;
}

.divSalir {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.divSalir p {
  width: 100%;
  font: 1.4rem var(--fontSt);
  color: var(--negro);
}

.boton-text {
  margin: 10px 0;
  background: transparent;
  display: flex;
  align-items: center;
  color: var(--negro);
  font: 1.6rem var(--fontSt);
  line-height: 0;
}

/* .boton-text img {
  filter: invert(1) brightness(10);
} */

.ProcesoCompleto {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.bienvenido-title {
  width: 100%;
  color: var(--amarillo);
  font: 3rem var(--fontSt);
  font-weight: 500;
  text-align: center;
}

.text-title {
  margin: 0px 0 30px 0;
  color: var(--azul-st);
  font: 2.4rem var(--fontSt);
  font-weight: 600;
  text-align: center;
}

.buttonsInput {
  position: absolute;
  top: 10px;
  right: 12px;
  display: flex;
}

.buttonsInput button {
  height: 40px;
  width: 40px;
  padding: 10px;
  background: transparent;
}

.buttonsInput button img {
  width: 100%;
  opacity: 0.3;
}

.titleSpecial {
  font: 1.4rem var(--fontSt);
  font-weight: 500;
  color: var(--gris);
}

.textNormal {
  color: var(--negro);
  font: 1.4rem var(--fontSt);
  font-weight: 400;
  margin-bottom: 20px;
}

.Completado {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.CompletadoImg {
  height: 60px;
  width: 60px;
  padding: 12px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  border-radius: 100%;
  background-color: var(--azul-cl);
}

.CompletadoImg img {
  width: 100%;
  filter: invert(11%) sepia(100%) saturate(7331%) hue-rotate(217deg)
    brightness(98%) contrast(98%) !important;
}

.Completado p {
  width: 100%;
  margin-bottom: 15px;
  color: var(--negro);
  font: 1.4rem var(--fontSt);
  font-weight: 400;
  text-align: center;
}

/*--WELCOME--*/
.welcomeDiv {
  width: 100%;
  margin: 10px auto 10px auto;
  padding: 5px 15px 15px 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--gris-lg);
  cursor: pointer;
}

.welcomeDiv:hover {
  background: var(--azul-cl);
  border: 1px solid var(--azul-st);
}

.welcomeImg {
  width: 100px;
  height: 100px;
  padding: 10px;
}

.welcomeImg img {
  height: 100%;
  opacity: 0.8;
}

.welcomeDiv:hover .welcomeImg img {
  filter: invert(11%) sepia(100%) saturate(7331%) hue-rotate(217deg)
    brightness(98%) contrast(98%) !important;
}

.welcomeText {
  width: 100%;
  color: var(--negro);
  font: 1.4rem var(--fontSt);
  font-weight: 400;
  text-align: center;
}

.welcomeText p {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .welcomeDiv {
    padding: 20px 15px;
  }
  .welcomeImg {
    margin-right: 15px;
  }

  .welcomeText {
    width: calc(100% - 115px);
    text-align: left;
  }
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .Completado p {
    font: 1.6rem var(--fontSt);
    font-weight: 400;
  }
}

@media (min-width: 1200px) {
  .divContainer {
    padding: 70px 0px 70px 0;
  }

  .formData {
    max-width: 800px;
    min-width: 600px;
  }

  /*------PROGRES-----*/

  .formProgress ul li {
    width: 33.3%;
  }

  .formProgress ul li::before {
    top: -30px;
    right: calc(-50% + 22px);
    width: calc(100% - 44px);
  }

  /*---CONTENIDO----*/

  .formContent {
    padding: 40px;
  }

  .formProgress h3 {
    font: 2.4rem var(--fontSt);
    font-weight: 700;
  }

  .formTitle h1 {
    font: 2.4rem var(--fontSt);
    font-weight: 600;
  }
}
