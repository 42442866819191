.input-divisaDi,
.input-divisa {
  position: relative;
  padding: 10px 0px;
  margin: 5px 0px;
  overflow: hidden;
}

.input-divisa input {
  height: 40px;
  width: 100%;
  padding: 6px 12px 6px 12px;
  border-radius: 5px;
  background: #ffffff;
  color: var(--negro);
  border: solid 1px var(--gris-lg);
  box-shadow: none;
  font: 1.6rem var(--fontSt);
  font-weight: 400;
}

.input-divisa:hover input {
  border: solid 1px var(--azul-lg) !important;
}

.input-divisa label {
  position: absolute;
  top: 20px;
  left: -3px;
  margin-left: 10px;
  padding: 0px 6px;
  -webkit-transform: translate3d(0, -18px, 0);
  transform: translate3d(0, -18px, 0);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  background: #fff;
  color: var(--azul-lg);
  font: 1.3rem var(--fontSt);
  font-weight: 600;
  pointer-events: none;
  z-index: 2;
}

.input-divisa input:not(:placeholder-shown) {
  outline: none;
  box-shadow: none !important;
  border: solid 1px var(--azul-lg) !important;
  background: transparent !important;
}

.input-divisaDi input {
  height: 40px;
  width: 100%;
  padding: 6px 12px 6px 12px;
  border-radius: 5px;
  background: #f7f7f7;
  color: #4d4d4d;
  border: solid 1px var(--gris-lg);
  box-shadow: none;
  font: 1.6rem var(--fontSt);
  font-weight: 400;
}

.input-divisaDi label {
  position: absolute;
  top: 20px;
  left: -3px;
  margin-left: 10px;
  padding: 0px 6px;
  background: #f7f7f7;
  border-radius: 3px 3px 0px 0px;
  -webkit-transform: translate3d(0, -18px, 0);
  transform: translate3d(0, -18px, 0);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: var(--azul-lg);
  font: 1.3rem var(--fontSt);
  font-weight: 600;
  pointer-events: none;
  z-index: 2;
}

.input-divisaDi input:focus,
.input-divisa input:focus {
  outline: none;
  border: solid 1px var(--azul-lg);
  box-shadow: none;
}

.valorCambio {
  margin: 10px 0px 20px;
  color: var(--negro);
  font: 1.6rem var(--fontSt), sans-serif;
  font-weight: 400;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.valorCambio img {
  height: 20px;
  margin-right: 10px;
}

.CountryImg {
  position: absolute;
  top: 15px;
  right: 15px;
  height: 30px;
}

.cambio-divisas {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.cambioDivisas {
  width: 100%;
  max-width: 1000px;
  margin-right: 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.cambioReverse {
  width: 100%;
  max-width: 1000px;
  margin-right: 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column-reverse;
}

.marginZero {
  margin-right: 0px !important;
}

.reverseButton {
  width: 100%;
  margin: 0 0 5px 0;
}

.reverseButton button {
  background: var(--azul-cl);
  width: 100%;
  height: 40px;
  border: 1px solid var(--azul-lg);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reverseButton img {
  filter: invert(14%) sepia(66%) saturate(7491%) hue-rotate(216deg)
    brightness(106%) contrast(98%);
  transform: rotate(90deg);
}

.DivsaCalc {
  width: 100%;
}

.divisonWidth {
  width: 40px;
}

.DivisaDiv {
  width: 100%;
}

.noticeCovert {
  padding: 10px;
  border-radius: 8px;
  color: var(--azul-lg);
  background: var(--azul-cl);
  font: 1.3rem var(--fontSt), sans-serif;
  font-weight: 400;
  text-align: center;
}

.warning1{
  margin-top: 10px;
}

@media (min-width: 544px) {
  .cambioDivisas {
    flex-wrap: nowrap;
  }

  .cambioReverse {
    flex-wrap: nowrap;
    flex-direction: row-reverse;
  }

  .reverseButton {
    width: 40px;
    margin: 0 10px;
  }

  .reverseButton button {
    width: 40px;
  }

  .reverseButton img {
    transform: rotate(0deg);
  }
}

@media (min-width: 576px) {
  .valorCambio {
    margin: 10px 0px 20px;
    font: 1.6rem var(--fontSt), sans-serif;
    font-weight: 400;
  }
}

@media (min-width: 768px) {
  .cambio-divisas {
    align-items: center;
    flex-wrap: nowrap;
  }
  .cambioDivisas,
  .cambioReverse {
    margin-right: 15px;
  }

  .DivsaCalc {
    width: auto;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
