.input-search {
    position: relative;
    padding: 10px 0px;
    margin: 5px 0px;
}

.input-search input {
    height: 40px;
    width: 100%;
    padding: 6px 12px 6px 12px;
    border-radius: 5px;
    background: #ffffff;
    color: var(--negro);
    border: solid 1px var(--gris-lg);
    box-shadow: none;
    font: 1.6rem var(--fontSt), sans-serif;
    font-weight: 400;
}

.input-search .BorderSelec {
    border-radius: 5px 5px 0px 0px !important;
}

.input-search  input:hover{
    border: solid 1px var(--azul-lg) !important;
}

.input-search input:focus {
    outline: none;
    border: solid 1px var(--azul-lg);
    box-shadow: none;
}

.input-search label {
    position: absolute;
    top: 20px;
    left: 5px;
    margin-left: 10px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: #909090;
    font: 1.6rem var(--fontSt);
    font-weight: 400;
    pointer-events: none;
    z-index: 2;
}

.input-search:hover label,
.input-search input:focus + label {
    left: -3px;
    padding: 0px 6px;
    -webkit-transform: translate3d(0, -18px, 0);
    transform: translate3d(0, -18px, 0);
    background: #fff;
    color: var(--azul-lg);
    font: 1.3rem var(--fontSt), sans-serif;
    font-weight: 600;
}

.input-search input:not(:placeholder-shown) + label {
    left: -3px;
    padding: 0px 6px;
    -webkit-transform: translate3d(0, -18px, 0);
    transform: translate3d(0, -18px, 0);
    background: #fff;
    color: var(--azul-lg);
    font: 1.3rem var(--fontSt), sans-serif;
    font-weight: 600;
}

.input-search input:not(:placeholder-shown) {
    outline: none;
    box-shadow: none !important;
    background: transparent !important;
}

.searchedDiv {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    max-height: 120px;
    overflow-y: hidden; 
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 0px 0px 5px 5px !important;
    color: var(--negro);
    background: #fff;
    font: 1.6rem var(--fontSt), sans-serif;
    font-weight: 400;
    z-index: 9;
}

.searched {
    width: 100%;
    height: 40px;
    padding: 10px 12px;
    text-align: left;
    background: #f1f1f1;
    border-left: 1px solid var(--gris-lg);
    border-right: 1px solid var(--gris-lg);
    border-bottom: 1px solid var(--gris-lg);
    cursor: pointer;
}

.searched:hover {
    background: var(--azul-lg);
    border-left: 1px solid var(--azul-lg);
    border-right: 1px solid var(--azul-lg);
    color: #fff;
}

.buttonInput {
    position: absolute;
    top: 10px;
    right: 0px;
    padding: 0px 12px 0px 10px;
    height: 40px;
    background: transparent;
    display: flex;
    align-items: center;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.buttonInput img{
    opacity: 0.3;
    width: 24px;
}

.inputButtonDiv{
    width: 100%;
    position: relative;
    padding: 10px 0px;
    margin: 5px 0px;
    overflow: hidden;
    display: flex;
}

.inputButton{
    position: relative;
    width: 100%;
}

.inputButton input{
    width: 100%;
    position: relative;
    height: 40px;
    padding: 6px 12px 6px 12px;
    border-radius: 5px 0px 0px 5px;
    background: #ffffff;
    color: var(--negro);
    border: solid 1px var(--gris-lg);
    box-shadow: none;
    font: 1.6rem var(--fontSt);
    font-weight: 400;
}

.inputButton:hover input{
    border: solid 1px var(--azul-lg) !important;
}

.inputButton input:focus{
    outline: none;
    border: solid 1px var(--azul-lg);
    box-shadow: none;
}

.inputButton label {
    position: absolute;
    top: 10px;
    left: 5px;
    margin-left: 10px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: #909090;
    font: 1.6rem var(--fontSt);
    font-weight: 400;
    pointer-events: none;
    z-index: 2;
}

.inputButton input:focus + label{
    left: -3px;
    padding: 0px 6px;
    -webkit-transform: translate3d(0, -18px, 0);
    transform: translate3d(0, -18px, 0);
    background: #fff;
    color: var(--azul-lg);
    font: 1.3rem var(--fontSt);
    font-weight: 600;
}

.inputButton input:not(:placeholder-shown) + label {
    left: -3px;
    padding: 0px 6px;
    -webkit-transform: translate3d(0, -18px, 0);
    transform: translate3d(0, -18px, 0);
    background: #fff;
    color: var(--azul-lg);
    font: 1.3rem var(--fontSt);
    font-weight: 600;
}

.inputButton input:not(:placeholder-shown){
    outline: none;
    box-shadow: none !important;
    border: solid 1px var(--azul-lg) !important;
    background: transparent !important;
}

.inputButtonDiv button{
    width: 150px;
    padding: 6px 12px 6px 12px;
    color: var(--blanco);
    background: var(--azul-st);
    border-radius: 0px 5px 5px 0px;
    font: 1.6rem var(--fontSt);
    font-weight: 400;
}

.inputButtonDiv button:hover{
    background: var(--azul-lg);
}

.spnAlert {
    position: absolute;
    bottom: -8px;
    left: 10px;
    padding: 2px 5px;
    border-radius: 3px;
    background: #fdeeee;
    color: #d63830;
    font: 1.1rem var(--fontSt);
    font-weight: 500;
    z-index: 1;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
