.drop_zone {
  position: relative;
  width: 100%;
  padding: 25px;
  margin: 10px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px dashed;
  background: #fff;
  border-color: #d6d6d6;
  font: 1.4rem var(--fontSt);
  text-align: center;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.drop_zone_active {
  border-color: var(--azul-lg) !important;
  background-color: var(--azul-cl);
}

.drop_zone:hover {
  border-color: var(--azul-lg);
}
.drop_zone p {
  margin: 2px 0;
  color: #c7c7c7;
}

.drop_zone_active p {
  color: var(--azul-lg) !important;
}

.drop_zone_active img {
  filter: invert(14%) sepia(66%) saturate(7491%) hue-rotate(216deg)
    brightness(106%) contrast(98%) !important;
  opacity: 1 !important;
}

.drop_zone p:first-child {
  color: rgba(0, 0, 0, 0.65);
}
.drop_zone_list {
  width: 100%;
  padding: 0 3px;
  box-sizing: border-box;
}
.drop_zone_list div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px;
  font-size: 14px;
  padding: 0 3px;
  transition: all 0.1s ease;
  color: #3f4447;
}
.drop_zone_list div:hover {
  background: #e0f5ff;
}
.drop_zone_list div:hover a {
  display: block;
}
.drop_zone_list div p {
  margin: 5px;
}
.drop_zone_list div a {
  text-decoration: none;
  color: #3f4447;
  display: none;
  font-size: 16px;
  cursor: pointer;
}

.fileClick {
  border: 1px solid red;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.fileIcon {
  display: flex;
  font: 1.4rem var(--fontSt);
  font-weight: 600;
}

.fileIcon img {
  margin-right: 10px;
  opacity: 0.3;
}

.drop_hover:hover {
  border-color: var(--azul-lg);
}
.drop_out:hover {
  border-color: var(--gris);
}

.spanAlertDocument {
  position: absolute;
  bottom: -8px;
  padding: 2px 5px;
  border-radius: 3px;
  background: #fdeeee;
  color: #d63830;
  font: 1.2rem var(--fontSt), sans-serif;
  font-weight: 500;
  z-index: 1;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.dashedRed {
  background: #fdeeee;
  border: dashed 1px #d63830 !important;
}

.dashedRed p {
  color: #d63830 !important;
}

.dashedRed img {
  filter: invert(29%) sepia(23%) saturate(6993%) hue-rotate(345deg)
    brightness(89%) contrast(87%) !important;
  opacity: 1 !important;
}
