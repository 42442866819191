.disPlayCard {
  display: flex;
  align-items: center;
}

.displayName {
  margin-left: 15px !important;
}

.displayName p {
  font: 1.8rem var(--fontSt);
  font-weight: 700;
  color: #fff;
}

.displayName span {
  font: 1.4rem var(--fontSt);
  font-weight: 400;
  color: #fff;
}

.ImagenPerfil {
  position: relative;
  height: 80px;
  width: 80px;
  border-radius: 100%;
  overflow: hidden;
  cursor: pointer;
}

.ImagenPerfil img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.ImagenPerfil:hover .PerfilButton {
  opacity: 1;
}

.PerfilButton {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.PerfilButton button {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.PerfilButton button img {
  height: 30px;
  width: 30px;
  filter: invert(1) brightness(10);
}

.PerfilButton button span {
  width: 100%;
  margin-top: 5px;
  font: 1.2rem var(--fontSt);
  font-weight: 500;
  color: #fff;
}

.UserData {
  font: var(--fontSt);
}

.UserData div {
  margin: 20px 0;
}

.UserData label {
  margin-bottom: 5px;
  display: block;
  color: var(--gris);
  font-size: 1.2rem;
  font-weight: 500;
}

.UserData p {
  color: var(--negro);
  font-size: 1.8rem;
  font-weight: 400;
}

.documentDiv {
  border-radius: 30px;
  padding: 15px;
  background-color: var(--gris-lg);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 200px;
}

.documentDiv img {
  height: 50px;
  filter: invert(0.8);
}

.documentDiv label {
  width: 100%;
  font: 1.6rem var(--fontSt);
  font-weight: 500;
  text-align: center;
}

.cropProfile {
  width: 300px;
  height: 300px;
  overflow: hidden;
}

.cropperDiv {
  height: 100%;
  margin: 0 auto;
}
