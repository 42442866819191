.InicioTransferencia {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.itemTransfer {
  width: 100%;
  max-width: 340px;
  margin: 0px 0px 15px 0px;
  padding: 25px 25px;
  border-radius: 20px;
  background-color: #f0f0f0;
  cursor: pointer;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.itemTransfer:hover {
  background-color: var(--azul-st);
}

.itemTransfer a {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.numberTransfer {
  width: 100%;
  margin: 0 0 8px 0;
  color: var(--azul-st);
  font: 2.5rem var(--fontSt), sans-serif;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
}

.itemTransfer:hover .numberTransfer {
  color: var(--amarillo);
}

.dateTransfer {
  width: 100%;
  color: var(--gris);
  font: 1.4rem var(--fontSt), sans-serif;
  font-weight: 400;
  text-align: center;
  text-transform: capitalize;
}

.destinoTransfer {
  width: 100%;
  margin-bottom: 10px;
  color: var(--gris);
  font: 1.4rem var(--fontSt), sans-serif;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
}

.coinTransfer {
  margin: 10px 0px;
  color: var(--gris);
  font: 1.3rem var(--fontSt), sans-serif;
  color: var(--negro);
  font-weight: 500;
  display: flex;
  align-items: center;
}

.coinTransfer:last-child {
  margin: 10px 0px 0px 0px;
}

.itemTransfer:hover .dateTransfer,
.itemTransfer:hover .destinoTransfer,
.itemTransfer:hover .coinTransfer {
  color: var(--blanco);
}

.coinTransfer img {
  margin: 3px 8px 0px 0px;
  height: 20px;
}

.coinTransfer label {
  color: var(--gris);
  font: 1.2rem var(--fontSt), sans-serif;
  font-weight: 400;
  display: block;
}

.itemTransfer:hover .coinTransfer img {
  filter: invert(1) brightness(10);
}

@media (min-width: 576px) {
}

@media (min-width: 760px) {
  .itemTransfer {
    width: calc(50% - 20px);
    max-width: inherit;
    margin: 0px 10px 15px 10px;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 1300px) {
  .itemTransfer {
    width: calc(33.3% - 20px);
    max-width: inherit;
    margin: 0px 10px 15px 10px;
  }
}
