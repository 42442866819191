.banner-inicio {
  padding: 50px 0;
  display: flex;
  align-items: center;
  background: rgba(0, 24, 180, 1);
  background: -moz-linear-gradient(
    top,
    rgba(0, 24, 180, 1) 0%,
    rgba(3, 71, 201, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(0, 24, 180, 1)),
    color-stop(100%, rgba(3, 71, 201, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 24, 180, 1) 0%,
    rgba(3, 71, 201, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 24, 180, 1) 0%,
    rgba(3, 71, 201, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(0, 24, 180, 1) 0%,
    rgba(3, 71, 201, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 24, 180, 1) 0%,
    rgba(3, 71, 201, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0018b4', endColorstr='#0347c9', GradientType=0 );
}

.GeneralCont {
  margin: 0 auto;
  max-width: 1200px;
}

.MainTitle {
  margin: 30px 0px 20px 0px;
  color: var(--azul-st);
  font: 2.8rem var(--fontSt);
  font-weight: 700;
}

.SubTitle {
  margin: 40px 0px 10px 0px;
  font: 1.6rem var(--fontSt);
  font-weight: 700;
}

.TextGeneral {
  margin: 0px 0px 20px 0px;
  font: 1.6rem var(--fontSt);
  font-weight: 400;
  text-align: justify;
}

.ulGeneral {
  padding: 0 0 0 20px;
  margin: 0px 0px 20px 0px;
  font: 1.6rem var(--fontSt);
  font-weight: 400;
  text-align: justify;
}

.SectionTitle {
  width: 100%;
  margin: 0px 0px 15px 0px;
  color: var(--azul-st);
  font: 2.8rem var(--fontSt);
  font-weight: 700;
}

.SectionText {
  width: 100%;
  padding: 0 15px;
  margin: 0px 0px 30px 0px;
  font: 1.6rem var(--fontSt);
  font-weight: 400;
}

.TxtCenter {
  text-align: center;
}

/*--CURRENCY CONVERTER--*/

.contGeneral {
  width: 100%;
  min-height: 65vh;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px 30px 20px;
}

.contCurrency {
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px 0px 20px;
}

.containerCurrency {
  width: 100%;
  max-width: 1000px;
  padding: 20px 25px;
  margin: 0 auto;
  border-radius: 8px;
  background-color: #fff;
}

/*--FEES--*/
.Fees {
  padding: 50px 0px;
}

.FeesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.FeesDiv {
  margin: 10px 15px;
  padding: 35px;
  max-width: 300px;
  border: 1px solid var(--gris-lg);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 30px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 5px 60px 0px;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.FeesDiv:hover {
  background: var(--azul-st);
}

.FeesDiv:hover .FeeName {
  color: var(--amarillo);
}

.FeesDiv:hover .FeeValor,
.FeesDiv:hover .FeeDesc span,
.FeesDiv:hover .FeeDesc {
  color: #fff !important;
}

.FeeName {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--azul-st);
  font: 2.8rem var(--fontSt);
  font-weight: 600;
}

.FeeName img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.FeeText {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.FeeValor {
  margin-bottom: 15px;
  color: var(--negro);
  font: 2.5rem var(--fontSt);
  font-weight: 600;
}

.FeeValor span {
  font: 1.8rem var(--fontSt);
  font-weight: 400;
}

.FeeDesc {
  max-width: 210px;
  margin-bottom: 10px;
  color: var(--negro);
  font: 1.4rem var(--fontSt);
  font-weight: 400;
}

.FeeDesc:last-child {
  margin-bottom: 0px;
}

.FeeDesc span {
  font-weight: 600;
  color: var(--negro);
}

.FeeCond {
  width: 100%;
  margin-top: 10px;
  color: #fff;
  font: 1rem var(--fontSt);
  text-align: center;
}

/*---------CONTACT FORM---------*/
.containerForm {
  padding: 50px 0px;
  display: flex;
  flex-wrap: wrap;
}

#textAreaContact {
  height: 100px;
}

#textAreaContact::-webkit-scrollbar {
  width: 7px;
}

#textAreaContact::-webkit-scrollbar-track {
  background-color: var(--gris-lg);
}

#textAreaContact::-webkit-scrollbar-thumb {
  background-color: var(--gris);
}

.DivSplit {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.Splitcontent {
  width: 100%;
}

.SplitImg {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
}

.SplitImg img {
  width: 250px;
}

/*-------TESTIMONIOS------------*/

.testimonios-inicio {
  padding: 50px 0px;
}

.testimonios {
  display: flex;
  justify-content: center;
  padding: 50px 0;
  width: 100%;
}

.testimonio {
  position: relative;
  width: 200px;
  padding: 30px 30px 100px 30px;
  margin: 20px;
  border-radius: 10px;
  background-color: var(--blanco);
  font: 1.6rem var(--fontSt);
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 5px 60px 0px;
  cursor: pointer;
}

.rate-name {
  position: absolute;
  left: 0;
  bottom: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 1.6rem var(--fontSt);
  font-weight: 600;
}

.rate-date {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  font: 1.2rem var(--fontSt);
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rate-stars {
  position: absolute;
  left: 0;
  bottom: 38px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divStars {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DivStar img {
  width: 16px;
  margin: 0 1px;
  filter: invert(80%) sepia(100%) saturate(779%) hue-rotate(359deg)
    brightness(104%) contrast(105%);
}

.rate-coment {
  font: 1.4rem var(--fontSt);
  font-weight: 300;
}

/*CORE VALUES*/

.CoreValues {
  background-color: var(--blanco);
  margin: 25px auto 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.CoreValue {
  width: 100%;
  margin-bottom: 15px;
  padding: 20px 15px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border: 1px solid var(--gris-lg);
  border-radius: 8px;
  cursor: pointer;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.Core50 {
  width: 100% !important;
}

.CoreImg {
  width: 100px;
  margin-right: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CoreImg img {
  height: 60px;
  filter: invert(0.9);
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.CoreText {
  width: 100%;
}

.CoreText h3 {
  margin: 0 0 10px 0;
  color: var(--azul-st);
  font: 1.8rem var(--fontSt);
  font-weight: 700;
}

.CoreText p {
  color: var(--negro);
  font: 1.4rem var(--fontSt);
  font-weight: 400;
}

.CoreText p span {
  font-weight: 700;
}

.CoreValue:hover {
  background: var(--azul-st);
}

.CoreValue:hover .CoreImg img {
  filter: invert(0);
}

.CoreValue:hover p {
  color: #fff;
}

.CoreValue:hover h3 {
  color: var(--amarillo);
}

/*STEPS*/
.stepByStep {
  margin: 25px auto 0 auto;
  display: flex;
  flex-wrap: wrap;
}

.divStep {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  border: 1px solid var(--gris-lg);
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
  filter: saturate(0);
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.divStepImg {
  width: 100%;
  height: 200px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--gris-lg);
}

.divStepImg img {
  height: 100%;
}

.divStepText {
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: flex-start;
}

.divStepText p {
  font: 1.4rem var(--fontSt);
  font-weight: 400;
}

.divStepText span {
  color: var(--azul-st);
  font: 3.8rem var(--fontSt);
  font-weight: 700;
  line-height: 3.2rem;
  margin-right: 10px;
}

.divStep:hover {
  filter: saturate(1);
}

.divStep:hover .divStepText {
  background: var(--azul-st);
}

.divStep:hover span {
  color: var(--amarillo);
}

.divStep:hover p {
  color: #fff;
}

/*INPUTS ACORDION*/

.Acordion {
  position: relative;
  margin-bottom: 15px;
  font: 1.4rem var(--fontSt);
  font-weight: 400;
}

.Acordion input {
  position: absolute;
  display: none;
}

.Acordion label {
  display: block;
  padding: 20px 0 20px 20px;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid var(--gris-lg);
  font-weight: 500;
  cursor: pointer;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.Acordion:hover label {
  color: var(--azul-lg);
  background: var(--azul-cl);
  border: 1px solid var(--azul-lg);
}

.AcordionExpand {
  height: 0;
  overflow: hidden;
}

.Acordion input:checked ~ label {
  border-radius: 10px 10px 0px 0px;
  color: var(--azul-lg);
  background: var(--azul-cl);
  border: 1px solid var(--azul-lg);
}

.Acordion input:checked ~ .AcordionExpand {
  height: auto;
  padding: 20px;
  border-left: 1px solid var(--azul-lg);
  border-bottom: 1px solid var(--azul-lg);
  border-right: 1px solid var(--azul-lg);
  border-radius: 0px 0px 10px 10px;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .CoreValue {
    width: calc(33.3% - 20px);
    margin: 10px;
  }

  .Core50 {
    width: calc(50% - 20px) !important;
  }

  /*STEPS*/
  .divStep {
    width: calc(50% - 20px);
    margin: 0 10px 20px 10px;
  }

  /*CONTACT FORM*/
  .Splitcontent {
    width: 50%;
    padding: 0 10px;
  }

  .SplitImg {
    width: 50%;
    padding: 0 30px;
  }

  .SplitImg img {
    width: 350px;
  }
}

@media (min-width: 992px) {
  .FeesContainer {
    flex-wrap: nowrap;
  }

  .FeesDiv {
    width: calc(50% - 5px);
  }

  /*CORE VALUES*/

  .CoreValues {
    margin: 35px auto 0 auto;
  }

  .CoreValue {
    padding: 30px 20px;
  }

  .CoreImg {
    width: 100px;
    margin-right: 20px;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .CoreImg img {
    height: 80px;
  }

  .CoreText h3 {
    margin: 0 0 10px 0;
    font: 2rem var(--fontSt);
    font-weight: 700;
  }

  .CoreText p {
    font: 1.6rem var(--fontSt);
    font-weight: 400;
  }

  .MainTitle {
    margin-top: 50px;
    font: 3.5rem var(--fontSt);
    font-weight: 700;
  }

  .SectionTitle {
    font: 3.5rem var(--fontSt);
    font-weight: 700;
  }

  /*STEPS*/
  .divStep {
    width: calc(33.3% - 20px);
    margin: 0 10px 20px 10px;
  }

  .Acordion {
    position: relative;
    margin-bottom: 15px;
    font: 1.6em var(--fontSt);
    font-weight: 400;
  }
}

@media (min-width: 1200px) {
  .banner-inicio {
    padding: 100px 0;
  }
}

@media (min-width: 1370px) {
}
