.dashboard-menu_mobile {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--blanco);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  z-index: 99;
}

.dashboard-menu_mobile nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.dashboard-menu_mobile nav a {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font: 1.1rem var(--fontSt), sans-serif;
}

.dashboard-menu_mobile nav a img {
  margin-bottom: 3px;
  filter: invert(0.75);
}

.dashboard-menu_mobile nav a span {
  width: 100%;
  text-align: center;
  color: var(--gris-m);
}

.liActivo,
.liActivo span {
  color: var(--azul-lg) !important;
}

.liActivo img {
  filter: invert(11%) sepia(100%) saturate(7331%) hue-rotate(217deg)
    brightness(98%) contrast(98%) !important;
}

.mobile-plus {
  margin-top: -45px;
  padding: 15px;
  border-radius: 100%;
  background-color: var(--azul-st);
}

.mobile-plus img {
  margin: 0 !important;
  filter: invert(1) brightness(100%) !important;
}

.MenuGeneral {
  display: none;
}

.MenuCont{
  display: none;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .dashboard-menu_mobile {
    display: none;
  }
}

@media (min-width: 992px) {
  .MenuGeneral {
    display: block;
    position: fixed;
    margin-top: 80px;
    width: 100%;
    background: var(--azul-st);
    z-index: 8;
  }

  .MenuCont {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    height: 50px;
    padding: 0px 35px;
    display: flex;
    align-items: center;
  }

  .MenuCont li, .MenuCont a{
    position: relative;
    height: 50px;
    padding: 0 12px 0 12px;
    display: flex;
    align-items: center;
    color: var(--blanco);
    font: 1.4rem var(--fontSt);
    list-style: none !important;
    cursor: pointer;
  }

  .MenuCont a:last-child{
    margin-right: 0px;
  }

  .MenuCont li .menuArrow{
    width: 15px;
    margin: 0 0 0 5px !important;
    filter: invert(1) brightness(10);
  }

  .MenuCont li a, .MenuCont a {
    color: #fff;
    display: flex;
    align-items: center;
  }

  .MenuCont li img, .MenuCont a img {
    margin-right: 5px;
    filter: invert(1) brightness(10);
  }

  .MenuCont li ul{
    display: none;
    position: absolute;
    top: 36px;
    left: 0;
    padding: 0 !important;
    border-radius: 0 0 10px 10px;
    background-color: var(--blanco);
    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.02), 0 0px 20px rgba(0, 0, 0, 0.1);
  }

  .MenuCont li ul li{
    padding: 5px 10px;
    height: auto;
    margin-right: 0px;
  }

  .MenuCont li ul li:last-child{
    border-radius: 0 0 10px 10px;
  }

  .MenuCont li:hover{
    background: var(--azul-lg);
  }

  .MenuCont li:hover ul{
    display: block;
  }

  .MenuCont li ul li:hover{
    background: var(--azul-lg);
  }

  .MenuCont li ul li a{
    color: var(--negro);
  }

  .MenuCont li ul li a img {
    margin-right: 5px;
    filter: invert(0.75);
  }

  .MenuCont li ul li:hover a{
    color: var(--blanco) !important;
  }

  .MenuCont li ul li:hover a img{
    filter: invert(1) brightness(10) !important;
  }

  .subActivo{background: var(--azul-st);}

  .liActivo,
  .subActivo a{
    color: var(--amarillo) !important;
  }

  .liActivo img,
  .subActivo a img{
    filter: invert(100%) sepia(44%) saturate(1354%) hue-rotate(359deg)
      brightness(103%) contrast(107%) !important;
  }

  .liImg img{
    filter: none !important;
  }
}

@media (min-width: 1200px) {
  .MenuGeneral {
    margin-top: 90px;
  }

  .MenuCont {
    padding: 0 5px;
  }
}

@media (min-width: 1370px) {
  .MenuGeneral {
    margin-top: 100px;
  }
}
