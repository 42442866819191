.modal-general {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 40px);
  margin: 0 auto;
  max-width: 600px;
  max-height: 500px;
  padding: 20px;
  border-radius: 20px;
  background: #fff;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.modal-nuevo {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  max-width: 800px;
  height: 100%;
  background: #fff;
  z-index: 9999;
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.modal-activo {
  opacity: 1 !important;
  visibility: visible !important;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.fonder {
  position: fixed;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  z-index: 9998;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.fonder-activo {
  opacity: 0.75 !important;
  visibility: visible !important;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.cerrar-modal {
  position: absolute;
  right: 0;
  top: -25px;
  color: #fff;
  font: 1.4rem var(--fontSt);
}

.scroll-modify {
  height: 100%;
  width: 100%;
  overflow-x: auto;
}

.InnerModal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.InnerModal p {
  font: 1.6rem var(--fontSt);
  margin: 5px 0 10px 0;
  text-align: center;
}

.InnerModal h2 {
  margin: 15px 0 0 0;
  font: 2.3rem var(--fontSt);
  font-weight: 700;
  text-align: center;
  color: var(--azul-lg);
}

.InnerWrong h2 {
  color: var(--red);
}

.IconModal {
  background: var(--azul-cl);
  border-radius: 100%;
  width: 80px;
  height: 80px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.IconModal img {
  width: 100%;
  filter: invert(11%) sepia(100%) saturate(7331%) hue-rotate(217deg)
    brightness(98%) contrast(98%) !important;
}

.IconWrong {
  background: var(--red-cl);
}
.IconWrong img {
  filter: invert(30%) sepia(23%) saturate(6153%) hue-rotate(344deg)
    brightness(99%) contrast(86%) !important;
}

.modalNotice{
  opacity: 0;
}

.modalNoticeAct{
  position: fixed;
  width: 100%;
  height: 100vh;
  padding: 0 15px;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.8);
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.modalNoticeCont img{
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
}

.modalNoticeCont div{
  height: 0;
  display: flex;
  justify-content: flex-end;
}

.modalNoticeCont div img{
  margin: 15px;
  filter: invert(1) brightness(100);
  width: 25px;
  height: 25px;
  cursor: pointer;
  border-radius: 0px;
}

.modalContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  padding: 15px 20px;
  z-index: 9999;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.modalNuevo{
  z-index: 9999;
}

.modalX{
  border-radius: 20px;
  background: #fff;
  padding: 30px;
  max-width: 400px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.modalFondo {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.75;
  z-index: 9998;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.validarButton{
  display: flex;
}

.BtnCancelar{
  color: var(--gris) !important;
  background: transparent;
  border: 1px solid var(--gris);
  padding: 22px 20px;
  margin: 10px 5px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 1.5rem var(--fontSt), sans-serif;
  font-weight: 400;
  line-height: 0;
  white-space: nowrap;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.BtnCancelar:hover{
  color: var(--blanco) !important;
  background: var(--azul-st);
  border: 1px solid var(--azul-st);
}

.ButtonGreen, .ButtonBlue{
  color: var(--azul-st);
  background: var(--azul-cl);
  border: 1px solid var(--azul-st);
  padding: 22px 20px;
  margin: 10px 5px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 1.5rem var(--fontSt), sans-serif;
  font-weight: 400;
  line-height: 0;
  white-space: nowrap;
}

.ButtonGreen:hover, 
.ButtonBlue:hover{
  color: var(--blanco);
  background: var(--azul-st);
  border: 1px solid var(--azul-st);
}

.ButtonRed{
  color: var(--red);
  background: var(--red-cl);
  border: 1px solid var(--red);
  padding: 22px 20px;
  margin: 10px 5px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 1.5rem var(--fontSt), sans-serif;
  font-weight: 400;
  line-height: 0;
  white-space: nowrap;
}

.ButtonRed:hover{
  color: var(--blanco);
  background: var(--red);
  border: 1px solid var(--red);
}

@media (min-width: 576px) {
  .modal-general {
    padding: 25px;
  }

  .modalX {
    padding: 30px 25px;
  }
}

@media (min-width: 768px) {
  .modal-general {
    width: 100%;
    min-width: 300px;
    padding: 28px;
  }

  .modalX {
    width: 100%;
    min-width: 300px;
    max-width: 400px;
    padding: 30px;
  }
}

@media (min-width: 992px) {
  .modal-general {
    width: 100%;
    max-height: 600px;
    padding: 30px;
  }

  .modalX {
    width: 100%;
    padding: 30px;
  }
}

@media (min-width: 1200px) {
  .modal-general {
    padding: 35px;
  }

  .modalX {
    padding: 35px;
  }
}
