.ContLog {
  position: relative;
  width: 100%;
  background: var(--gris-lg);
}

.ContLogBd {
  min-height: 100vh;
  padding: 60px 15px 40px 15px;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.ContVerify {
  position: relative;
  padding: 160px 30px 60px 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 30px;
  overflow: hidden;
}

.ContHeads {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  background: var(--azul-st);
}

.ContVerify h3 {
  width: 100%;
  margin: 0 0 20px 0;
  color: var(--negro);
  font: 2.6rem var(--fontSt);
  font-weight: 700;
  text-align: center;
}

.ContVerify p {
  width: 100%;
  margin-bottom: 20px;
  font: 1.6rem var(--fontSt);
  font-weight: 400;
  text-align: center;
}

.ContVerify p button {
  background: transparent;
  color: var(--azul-lg);
  font: 1.6rem var(--fontSt);
}

.IconContext {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 80px;
  height: 80px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 25px 0px;
  z-index: 2;
}

.IconContext img {
  width: 100%;
}

.TextGreen {
  color: #00bb2d;
}

@media (min-width: 576px) {
  .ContLogBd {
    padding: 60px 40px 40px 40px;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 768px) {
  .ContLogBd {
    padding: 15px;
    align-items: center;
    justify-content: center;
  }

  .ContVerify {
    padding: 160px 60px 50px 60px;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
