.flexContainer {
  position: relative;
  padding: 60px 20px 40px 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  background: var(--gris-lg);
}

.divAuth {
  min-height: 600px;
  display: flex;
  flex-direction: column-reverse;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 60px 0px;
}

.divPublish {
  position: relative;
  background: var(--azul-st);
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.DivMesage {
  padding: 40px;
  z-index: 2;
}

.DivMesage p {
  text-align: center;
  color: #fff;
  font: 2rem var(--fontSt);
  font-weight: 400;
}

.DivMesage span {
  color: var(--amarillo);
  font-weight: 700;
}

.ImgLogin {
  width: 100%;
}

.fondoLogin {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.fondoLogin img {
  object-fit: cover;
  object-position: center center;
  height: 100%;
  width: 100%;
}

.inputLogin {
  width: 100%;
  padding: 40px 35px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.loginContainer {
  position: relative;
  width: 100%;
  background: var(--blanco);
}

.InnerLogin {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.InnerLogin form {
  width: 100%;
}

.text-buton {
  width: 100%;
  text-align: center;
  font: 1.5rem var(--fontSt);
  font-weight: 400;
}

.text-buton span {
  color: var(--azul-st);
  cursor: pointer;
  font-weight: 600;
}

.label-control {
  width: 100%;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: var(--blanco);
  font: 1.4rem var(--fontSt);
  font-weight: 400;
  text-align: center;
}

.error {
  color: red;
}

.TextLink {
  margin-top: 20px;
  font: 1.6rem var(--fontSt);
  font-weight: 400;
  text-align: center;
}

.TextLink a {
  color: var(--azul-lg);
  font-weight: 700;
}

.textForgot {
  text-decoration: none;
  display: block;
  padding: 10px 0 10px 0;
  text-align: center;
  color: var(--gris);
  font: 1.6rem var(--fontSt);
  font-weight: 500;
}

.IconLogin {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 60px;
  height: 60px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 25px 0px;
  z-index: 5;
}

.IconLogin a img {
  width: 100%;
}

@media (min-width: 576px) {
  .divPublish {
    width: 450px;
    padding: 20px;
  }

  .DivMesage {
    padding: 50px;
  }

  .inputLogin {
    width: 450px;
    padding: 60px 70px;
  }
}

@media (min-width: 768px) {
  .divPublish {
    width: 500px;
    padding: 20px;
  }

  .inputLogin {
    width: 500px;
    padding: 60px 70px;
  }
}

@media (min-width: 992px) {
  .flexContainer {
    padding: 60px 20px 40px 20px;
    align-items: center;
  }

  .divAuth {
    flex-direction: row;
  }

  .divPublish {
    width: 400px;
    padding: 20px;
  }

  .inputLogin {
    width: 500px;
    padding: 20px 80px;
  }
}

@media (min-width: 1200px) {
  .divPublish {
    width: 500px;
    padding: 20px;
  }

  .inputLogin {
    width: 600px;
    padding: 20px 100px;
  }

  .DivMesage {
    padding: 60px;
  }
}
