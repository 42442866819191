.rating {
  display: flex;
  flex-flow: row-reverse;
  align-items: center;
  justify-content: center;
}

.rating > label:before {
  content: url("../img/icons/star-1.svg");
  height: 20px;
  width: 20px;
  margin: 5px;
  display: inline-block;
  cursor: pointer;
  filter: invert(100%);
}

.rating > input {
  display: none;
}

.rating > input:checked ~ label,
.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  filter: invert(15%) sepia(100%) saturate(779%) hue-rotate(359deg)
    brightness(104%) contrast(105%);
}

.rating > input:checked + label:hover,
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label,
.rating > input:checked ~ label:hover ~ label {
  filter: invert(15%) sepia(100%) saturate(779%) hue-rotate(359deg)
    brightness(104%) contrast(105%);
}
