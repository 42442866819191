.header-table {
  display: none;
}

.item-table {
  width: 100%;
  padding: 20px 20px;
  margin: 15px 0px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  background-color: #f5f5f5;
  color: inherit;
  font: 1.2rem var(--fontSt);
  cursor: pointer;
}

.item-table:last-child {
  margin: 10px 0px 0px 0px !important;
}

.item-table:hover {
  background-color: #f4f8ff;
}

.item-table div {
  width: 50%;
  margin: 5px 0px;
}

.item-completo {
  width: 100% !important;
}

.label-mobile {
  display: block;
  color: #acacac;
  font: 1rem var(--fontSt);
  font-weight: 600;
}

.label-green {
  color: green;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .label-mobile {
    display: none;
  }

  .header-table {
    width: 100%;
    padding: 20px 20px;
    margin: 10px 0px 0px 0px;
    display: flex;
    border-radius: 10px 10px 0 0;
    background-color: #f2f2f2;
    color: #acacac;
    font: 1.4rem "Inter", sans-serif;
    font-weight: 300;
  }

  .item-table {
    width: 100%;
    padding: 20px 20px;
    margin: 0px 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 0px;
    border: solid #f2f2f2;
    border-width: 1px 1px 0px 1px;
    background-color: var(--blanco);
    font: 1.4rem "Inter", sans-serif;
  }

  .item-table:last-child {
    margin: 0px 0px 0px 0px !important;
    border-radius: 0px 0px 10px 10px;
    border-width: 1px 1px 1px 1px !important;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
