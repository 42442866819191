.item-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
  border-radius: 8px;
}

.item-contact:last-child {
  margin: 0px 0px 0px 0px;
}

.item-contact:hover {
  background-color: #f0f0f0;
}

.item-contact__info {
  display: flex;
  align-items: center;
}

.item-contact__info p {
  margin-left: 15px;
  font: 1.6rem var(--fontSt);
  color: var(--negro);
}

.item-contact__info span {
  font: 1.4rem var(--fontSt);
  color: var(--gris);
}

.item-contact__photo {
  position: relative;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  overflow: hidden;
}

.item-contact__photo::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 100%;
  box-shadow: 0px 0px 0px 2px var(--blanco) inset;
  border: 2px solid var(--azul-st);
  bottom: 0;
  left: 0;
}

.item-contact__photo img {
  height: 100%;
  object-fit: contain;
  object-position: center;
}

@media (min-width: 576px) {
  .item-contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 20px;
    border-radius: 8px;
  }

  .item-contact:last-child {
    margin: 0px 0px 0px 0px;
  }

  .item-contact:hover {
    background-color: #f0f0f0;
  }

  .item-contact__info {
    display: flex;
    align-items: center;
  }

  .item-contact__info p {
    margin-left: 20px;
    font: 1.5rem var(--fontSt);
    color: var(--negro);
  }

  .item-contact__info span {
    font: 1.2rem var(--fontSt);
    color: #c1c9d6;
  }

  .item-contact__photo {
    position: relative;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    overflow: hidden;
  }

  .item-contact__photo::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 100%;
    box-shadow: 0px 0px 0px 2px var(--blanco) inset;
    border: 2px solid var(--azul-st);
    bottom: 0;
    left: 0;
  }

  .item-contact__photo img {
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
