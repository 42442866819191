.Selec {
  position: relative;
  padding: 0px;
  margin: 0 !important;
}

.Selec_select {
  position: relative;
  height: 40px;
  width: 100%;
  padding: 6px 12px 6px 12px;
  margin: 0 !important;
  border-radius: 5px;
  background: var(--blanco);
  border: solid 1px var(--gris-lg);
  font: 1.5rem var(--fontSt), sans-serif;
  font-weight: 400;
  line-height: 25px;
  cursor: pointer;
}

.Selec_select:hover {
  border: solid 1px var(--azul-lg);
}

.Selec_Open {
  border-radius: 5px 5px 0px 0px !important;
}

.Selec_blue {
  border: solid 1px var(--azul-lg);
}

.Label_in {
  position: absolute;
  top: 10px;
  left: 5px;
  margin-left: 10px;
  color: #909090;
  font: 1.5rem var(--fontSt), sans-serif;
  font-weight: 400;
  pointer-events: none;
  -moz-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.Label_out {
  position: absolute;
  top: 9px;
  left: 6px;
  padding: 0px 6px;
  margin: 0 !important;
  -webkit-transform: translate3d(0, -18px, 0);
  transform: translate3d(0, -18px, 0);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  background: #fff;
  color: var(--azul-lg);
  font: 1.3rem var(--fontSt), sans-serif;
  font-weight: 600;
  z-index: 9;
}

.Selec_opciones {
  position: absolute;
  left: 0px;
  top: 39px;
  width: 100%;
  height: auto;
  max-height: 160px;
  margin: 0 !important;
  border-radius: 0px 0px 5px 5px;
  background-color: #fff;
  border-top: solid 1px #dedede;
  border-left: solid 1px var(--azul-lg);
  border-right: solid 1px var(--azul-lg);
  border-bottom: solid 1px var(--azul-lg);
  box-sizing: border-box;
  overflow-y: auto;
  z-index: 999;
}

.Selec_opciones::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
} 

.Selec_opciones::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(to left, #fff, #e4e4e4);
  border: 1px solid #aaa;
}

.Selec_opciones::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.Selec_opciones::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0,0,0,0.1);
  border: 1px solid #ccc;
}

.Selec_opciones::-webkit-scrollbar-thumb:active {
  background: linear-gradient(to left, #22ADD4, #1E98BA);
}

.Select_opcion {
  padding: 10px 12px;
  margin: 0;
  margin: 0 !important;
  border-bottom: solid 1px #dedede;
  font: 1.5rem var(--fontSt), sans-serif;
  box-sizing: border-box;
  cursor: pointer;
}

.Select_opcion:last-child {
  border-bottom: none;
}

.Select_opcion:hover {
  background-color: var(--azul-lg);
  color: #fff;
}

.arrowSelect {
  position: absolute;
  top: 10px;
  right: 14px;
  width: 20px;
  opacity: 0.3;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
}
.arrowRotate {
  transform: rotate(180deg);
}

.selectButton {
  padding: 5px 12px;
}

.selectButton p {
  font: 1.5rem var(--fontSt), sans-serif;
  text-align: center;
  margin-top: 5px;
}

.DobleSelect {
  position: relative;
  width: 100%;
  display: flex;
}

.DobleSelect .Selec {
  width: 40%;
}

.DobleSelect .Selec:last-child {
  width: 60%;
}

.DobleSelect .Selec .Selec_select {
  border-radius: 5px 0 0 5px !important;
  border-left: solid 1px var(--azul-lg) !important;
  border-top: solid 1px var(--azul-lg) !important;
  border-bottom: solid 1px var(--azul-lg) !important;
  border-right: none;
  font: 1.4rem var(--fontSt), sans-serif !important;
  line-height: 25px !important;
}

.DobleSelect .arrowSelect {
  top: 12px !important;
  right: 6px !important;
  width: 18px !important;
}

.DobleSelect .Selec .Selec_Open {
  border-radius: 5px 0 0 0px !important;
  border-right: solid 1px var(--gris) !important;
}

.DobleSelect .Selec:last-child .Selec_select {
  border-radius: 0 5px 5px 0 !important;
  border-right: solid 1px var(--azul-lg) !important;
  border-top: solid 1px var(--azul-lg) !important;
  border-bottom: solid 1px var(--azul-lg) !important;
}

.DobleSelect .Selec:last-child .Selec_Open {
  border-radius: 0 5px 0px 0 !important;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
  .DobleSelect .Selec .Selec_select {
    font: 1.5rem var(--fontSt), sans-serif !important;
    line-height: 25px !important;
  }
}
