.user-profile {
  position: relative;
  height: 60px;
  padding: 0 0 0 10px;
  display: flex;
  align-items: center;
}

.user-profile-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.photoName {
  display: flex;
  align-items: center;
}

.user-profile__photo {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-color: var(--gris-lg);
  overflow: hidden;
}

.user-profile__photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.user-profile-name {
  display: none;
  margin-left: 10px;
}

.user-profile-name p {
  font: 1.6rem var(--fontSt);
  font-weight: 600;
}

.user-profile-name span {
  font: 1.4rem var(--fontSt);
  font-weight: 300;
}

.boton-icono {
  display: none;
  background-color: transparent;
  padding: 10px;
}

.dropdown-perfil {
  position: absolute;
  top: 60px;
  left: 0;
  height: 0;
  display: flex;
  flex-wrap: wrap;
  background: var(--blanco);
  border-radius: 0px 0px 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 2px 7px 8px;
  overflow: hidden;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.dropdown-perfil a,
.dropdown-perfil button {
  width: 100%;
  padding: 10px 20px;
  height: 50px;
  margin: 0;
  display: flex;
  align-items: center;
  background: transparent;
  color: var(--negro);
  font: 1.5rem var(--fontSt);
  text-align: left;
}

.dropdown-perfil a img,
.dropdown-perfil button img {
  height: 20px;
  width: auto;
  margin-right: 10px;
  filter: invert(0.6);
}

.dropdown-perfil a:hover,
.dropdown-perfil button:hover {
  color: var(--blanco);
  background: var(--azul-lg);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.dropdown-perfil a:hover img,
.dropdown-perfil button:hover img {
  filter: invert(1) brightness(100%);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.dropdown-active {
  height: auto !important;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  z-index: 999;
}

.dropLiA{
  color: var(--blanco) !important;
  background: var(--azul-lg) !important;
}

.dropLiA img{
  filter: invert(1) brightness(100%) !important;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .user-profile-name {
    display: block;
  }

  .boton-icono {
    display: block;
  }

  .user-profile__photo {
    height: 45px;
    width: 45px;
  }

  .user-profile {
    height: 80px;
  }

  .dropdown-perfil {
    top: 80px;
  }
}

@media (min-width: 1200px) {
  .user-profile {
    height: 90px;
  }
  .dropdown-perfil {
    top: 90px;
  }
}

@media (min-width: 1370px) {
  .user-profile {
    height: 100px;
  }

  .dropdown-perfil {
    top: 100px;
  }
}
