.MenuLateral {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.botonMenu {
  width: 50px;
  padding: 10px 0px 10px 15px;
  height: 100%;
}

.botonMenu img {
  height: 100%;
  width: 100%;
  opacity: 0.5;
}

.NoneMenu {
  display: none;
}

.MenuLatCont {
  position: absolute;
  left: 0;
  top: 0;
  width: 280px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  z-index: 2;
}

.cardProfile {
  padding: 30px 0;
  border-radius: 0 0 15px 0;
  background-color: var(--azul-st);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.cardProfile p {
  margin: 10px 0 0px 0;
  color: #fff;
  font: 1.6rem var(--fontSt);
  font-weight: 500;
}

.cardProfile span {
  color: #fff;
  font: 1.2rem var(--fontSt);
  font-weight: 400;
}

.cardProfileImg {
  height: 80px;
  width: 80px;
  border-radius: 100%;
  border: 2px solid #fff;
  overflow: hidden;
  background: #fff;
}

.cardLogo {
  padding: 10px;
}

.cardProfileImg img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.cardMenus {
  overflow-y: auto;
}

#lineTop {
  border-top: 1px solid var(--gris-lg);
}

.cardMenu {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 20px 25px;
}

.cardMenus li, 
.cardMenu ul li{
  list-style: none !important;
}

.cardMenu li{
  padding-left: 0 !important;
  margin: 0px 0;
  padding: 10px 0;
  background: transparent;
  color: var(--negro);
  font: 1.5rem var(--fontSt);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.cardMenu li img{
  height: 28px;
  margin-right: 10px;
  opacity: 0.8;
}

.cardMenu li ul{
  height: auto;
  width: 100%;
  padding: 0 0 0 25px;
  margin: 10px 0 0 0;
}

.menuLArrow{
  width: 15px;
  margin: 0 0 0 5px !important;
}

.cardMenu a,
.cardMenu button {
  margin: 0px 0;
  display: flex;
  align-items: center;
  background: transparent;
  color: var(--negro);
  font: 1.5rem var(--fontSt);
}

.cardMenu button {
  padding: 10px 0;
}

.cardMenu a img,
.cardMenu button img {
  height: 28px;
  margin-right: 10px;
  opacity: 0.8;
  filter: none;
}

.cardMenuAustrac a{
  padding: 20px 20px 20px 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  text-align: center;
  text-transform: uppercase;
  color:#1a1a1a;
  font: 1.2rem var(--fontSt);
  font-weight: 500;
}

.cardMenuAustrac img{
  height: 40px;
}

.backgroundBlack {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.homeLActive {
  font-weight: 700 !important;
  color: var(--azul-lg) !important;
}

.homeLActive img{
  filter: invert(11%) sepia(100%) saturate(7331%) hue-rotate(217deg) brightness(98%) contrast(98%)!important;
}

.subLUn img{
  filter: none !important;
}

@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
  .botonMenu {
    display: none;
  }
}
@media (min-width: 1200px) {
}
@media (min-width: 1370px) {
}
