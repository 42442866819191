.TableFooter {
  margin-top: 20px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.TableFooterNormal {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paginacion {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.pagNext,
.pagPrev {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e8f7ff;

  padding: 5px;
}
.pagPrev {
  border-radius: 5px 0 0 5px;
}
.pagNext {
  border-radius: 0 5px 5px 0px;
}
.pagPrev img,
.pagNext img {
  filter: invert(14%) sepia(66%) saturate(7491%) hue-rotate(216deg)
    brightness(106%) contrast(98%);
  width: 20px;
}

.pagNumber {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gris-lg);
  color: var(--gris);
  font: 1.4rem var(--fontSt);
  font-weight: 400;
}

.pagNumber:hover {
  background-color: #e8f7ff;
  color: var(--azul-lg);
}

.pagActive {
  background-color: #e8f7ff;
  color: var(--azul-lg);
  border-top: 1px solid var(--azul-lg) !important;
  border-left: 1px solid var(--azul-lg) !important;
  border-right: 1px solid var(--azul-lg) !important;
  border-bottom: 1px solid var(--azul-lg) !important;
}

.pagDisabled {
  background: var(--gris-lg);
  color: var(--gris);
  pointer-events: none;
}

.pagDisabled img {
  filter: none;
  opacity: 0.09;
}

.pagBlock {
  display: none;
  pointer-events: none;
}

.TableHeader {
  margin-bottom: 20px;
}

.Btns-top {
  display: flex;
  justify-content: right;
}

.Btns-Export {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.BtnExport {
  height: 40px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  color: var(--gris);
  font: 1.4rem var(--fontSt);
  font-weight: 500;
  background: var(--gris-lg);
}

.BtnExport img {
  width: 20px;
  margin-right: 10px;
  filter: invert(64%) sepia(11%) saturate(439%) hue-rotate(181deg)
    brightness(95%) contrast(86%);
}

.BtnExport:hover img {
  filter: invert(1) brightness(100);
}

.BtnPdf {
  margin-left: 15px;
}
.BtnExcel:hover {
  background-color: #0e9b2c;
  color: #fff;
}
.BtnPdf:hover {
  background-color: #ff3131;
  color: #fff;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .TableFooter {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .Btns-Export {
    margin-top: 0px;
  }
}
