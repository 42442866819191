.RateContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.inputRate {
  width: 100%;
}

.inputTasa {
  width: 100%;
}

.totalManual {
  width: 100%;
  background: var(--verde);
  color: var(--blanco);
  border-radius: 8px;
  padding: 10px;
  margin-top: 5px;
  text-align: center;
  font: 1.6rem var(--fontSt) !important;
  font-weight: 500 !important;
}

.RateOperation {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.RateOperation div {
  margin: 0 10px;
}

.RateOperation div span {
  font-weight: 400 !important;
}

.RateOperation div p,
.RateOperation div p span {
  font: 1.6rem var(--fontSt) !important;
  font-weight: 500 !important;
}

.totalRate {
  width: 100%;
  background: var(--azul-cl);
  color: var(--azul-lg);
  border-radius: 8px;
  padding: 10px;
  margin-top: 5px;
  text-align: center;
  font: 1.6rem var(--fontSt) !important;
  font-weight: 500 !important;
}

.igualRate {
  display: none;
}

.botonesRate,
.botonesTasa {
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
.botonesRate button,
.botonesTasa button {
  margin: 0 4px;
}

.botonesUltra{
  width: auto;
}

/*TABLA CONFIG*/

.tablaConfig{
  font: 1.4rem var(--fontSt) !important;
  font-weight: 500 !important;
}

.tablaConfigRow{
  border: 1px solid var(--gris);
  border-radius: 6px;
  overflow: hidden;
  display: flex;
}

.DesConfig{
  padding: 25px;
  border-right: 1px solid var(--gris);
}

.botonesConfig{
  display: flex;
}

.botonesConfig button{
  height: 100%;
  width: 100px;
  font: 1.4rem var(--fontSt) !important;
}

.ConfigCheck{
  background: var(--azul-st);
  color: #fff;
}

@media (min-width: 576px) {
  .RateContainer {
    align-items: center;
  }
  .inputTasa {
    margin-right: 10px;
    width: 30%;
  }
  .inputRate {
    width: 20%;
  }
  .igualRate {
    display: flex;
  }
  .RateOperation {
    width: 50%;
    margin-bottom: 0px;
  }

  .totalRate,
  .totalManual {
    width: 30%;
    margin-top: 0px;
  }

  .botonesRate {
    margin-top: 0;
  }

  .botonesTasa {
    margin-top: 0;
    width: auto;
  }

  .tablaConfigRow{
    width: max-content;
  }
}

@media (min-width: 768px) {
  .inputTasa {
    width: 20%;
  }
  .inputRate {
    width: 12%;
  }
  .RateOperation {
    width: 38%;
  }

  .totalRate,
  .totalManual {
    width: 18%;
  }

  .botonesRate {
    width: 32%;
  }
}

@media (min-width: 992px) {
  .inputTasa {
    width: 150px;
  }
  .inputRate {
    width: 100px;
    margin-right: 10px;
  }
  .RateOperation,
  .totalRate,
  .totalManual {
    margin-right: 10px;
    width: auto;
  }

  .botonesRate {
    width: auto;
  }
}

@media (min-width: 1200px) {
}
