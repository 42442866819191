.input-phone,
.SelectAndInput {
  position: relative;
  padding: 10px 0px;
  margin: 5px 0px;
}

.SelectInput input,
.inputPhone input {
  height: 40px;
  width: 100%;
  padding: 6px 12px 6px 79px;
  border-radius: 5px;
  background: #ffffff;
  color: var(--negro);
  border: solid 1px var(--gris-lg);
  box-shadow: none;
  font: 1.6rem var(--fontSt), sans-serif;
  font-weight: 400;
}

.BorderSelec {
  border-radius: 5px 5px 5px 0px !important;
}

.inputPhone input:hover,
.SelectInput input:hover {
  border: solid 1px var(--azul-lg) !important;
}

.inputPhone input:focus,
.SelectInput input:focus {
  outline: none;
  border: solid 1px var(--azul-lg);
  box-shadow: none;
}

.input-phone label,
.SelectInput label {
  position: absolute;
  top: 20px;
  left: -3px;
  margin-left: 10px;
  padding: 0px 6px;
  -webkit-transform: translate3d(0, -18px, 0);
  transform: translate3d(0, -18px, 0);
  background: #fff;
  color: #c7c7c7;
  font: 1.3rem var(--fontSt), sans-serif;
  font-weight: 600;
  pointer-events: none;
  z-index: 5;
}

.input-phone:hover label,
.SelectInput:hover label .input-phone input:focus + label,
.SelectInput input:focus + label {
  color: var(--azul-lg) !important;
}

.inputPhone input:not(:placeholder-shown) + label,
.SelectInput input:not(:placeholder-shown) + label {
  left: -3px;
  padding: 0px 6px;
  -webkit-transform: translate3d(0, -18px, 0);
  transform: translate3d(0, -18px, 0);
  background: #fff;
  color: var(--azul-lg);
  font: 1.3rem var(--fontSt), sans-serif;
  font-weight: 600;
}

.inputPhone input:not(:placeholder-shown),
.SelectInput input:not(:placeholder-shown) {
  outline: none;
  box-shadow: none !important;
  background: transparent !important;
}

.redPhoneWarn label {
  color: var(--red) !important;
}

.inputOpcion,
.inputCode {
  position: absolute;
  top: 0;
  margin-top: 10px;
  height: 40px;
  width: 67px;
  display: flex;
  align-items: center;
  border-radius: 5px 0 0 5px;
  z-index: 3;
}

.inputCode input {
  position: absolute;
  left: 1px;
  width: 67px;
  height: 40px;
  padding: 0px 12px 0px 20px;
  background: transparent;
  color: var(--negro);
  border: none;
  font: 1.6rem var(--fontSt), sans-serif;
  font-weight: 400;
}

.inputOpcionDiv {
  position: absolute;
  left: 0px;
  width: 67px;
  height: 30px;
  padding: 0px 12px 0px 12px;
  background: transparent;
  color: var(--negro);
  border: none;
  font: 1.6rem var(--fontSt), sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  border-right: solid 1px var(--gris-lg);
}

.inputArrow {
  position: absolute;
  top: 6px;
  right: 8px;
  width: 20px;
  opacity: 0.3;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
}
.inputArrowRo {
  transform: rotate(180deg);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.inputCode::before {
  content: "";
  position: absolute;
  right: 0;
  height: 30px;
  width: 1px;
  background: var(--gris-lg);
}

.inputCode button {
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font: 1.6rem var(--fontSt), sans-serif;
}

.input-phone button img {
  height: 20px;
  margin-right: 5px;
}

.selectCodes {
  position: absolute;
  top: 40px;
  left: 0;
  width: 67px;
  max-height: 120px;
  background: #fff;
  border-radius: 0px 0px 5px 5px;
  box-sizing: border-box;
  font: 1.6rem var(--fontSt), sans-serif;
  font-weight: 400;
  overflow: hidden;
  z-index: 9;
}

.selectCode:last-child {
  border-radius: 0 0 5px 5px;
}

.selectCodesnull {
  height: 0;
  width: 0;
  overflow: hidden;
  border: 0px;
}

.selectCode {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid var(--gris-lg);
  border-right: 1px solid var(--gris-lg);
  border-bottom: 1px solid var(--gris-lg);
  cursor: pointer;
}

.selectCode:hover {
  background: var(--azul-lg);
  border-left: 1px solid var(--azul-lg);
  border-right: 1px solid var(--azul-lg);
  color: #fff;
}

.selectCode img {
  height: 20px;
  margin-right: 5px;
}

.redSpan {
  color: var(--red) !important;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
