.noSee {
  display: none;
}

/*----CARDS BUTTON-----*/

.CardButtonsDiv {
  margin: 0px 0px 15px 0px;
  border-radius: 5px;
  border: 1px solid var(--gris);
  overflow: hidden;
  box-sizing: unset;
}

.ErrorButton {
  border: 1px solid var(--red);
}

.titleCardButton {
  width: 100%;
  padding: 10px 0;
  background: var(--azul-cl);
  color: var(--azul-st);
  font: 1.4rem var(--fontSt);
  font-weight: 700;
  text-align: center;
}

.cards-buttons {
  display: flex;
}

.card-fee {
  width: 50%;
  padding: 20px 15px;
  background: #fff;
  cursor: pointer;
  text-align: center;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.card-fee label {
  display: block;
  color: var(--azul-st);
  font: 2rem var(--fontSt);
  font-weight: 700;
}

.card-fee span {
  margin-bottom: 10px;
  display: block;
  color: var(--negro);
  font: 1.4rem var(--fontSt);
  font-weight: 700;
}

.card-fee p {
  color: var(--gris);
  font: 1.2rem var(--fontSt);
  font-weight: 400;
}

.card-fee:hover {
  background: var(--azul-lg);
}
.card-fee:hover label {
  color: var(--amarillo);
}
.card-fee:hover p,
.card-fee:hover span {
  color: #fff;
}

.cardActive {
  background: var(--azul-st) !important;
}
.cardActive label {
  color: var(--amarillo) !important;
}
.cardActive p,
.cardActive span {
  color: #fff !important;
}

.cardsOne{
  width: 100% !important;
}

.spnTached{
  text-decoration: line-through;
}

.cardUnabled{
  position: relative;
  padding: 20px 15px !important;
  background: #f7f7f7 !important;
  cursor: not-allowed !important;
  overflow: hidden;
}

.cardUnabled::before{
  content: 'No Disponible';
  position: absolute;
  padding: 5px 0px;
  right: -36%;
  top: 20px;
  width: 100%;
  background: #4d4d4d;
  color: #fff;
  transform: rotate(45deg);
  text-align: center;
  font: 1rem var(--fontSt);
}

.cardUnabled label,
.cardUnabled span,
.cardUnabled:hover label,
.cardUnabled:hover p,
.cardUnabled:hover span {color: #4d4d4d;}

.cardUnabled p {color:#4d4d4d;}
.cardUnabled:hover {background: #f7f7f7;}
.cardUnabled:hover::before{
  background: var(--red) !important;
}

/*----RESUMEN-----*/

.containerResumen {
  position: fixed;
  width: 100%;
  height: 183px;
  left: 0px;
  bottom: 0px;
  padding: 20px 15px 5px 15px;
  background: #ffffff;
  box-shadow: 0 -10px 8px rgba(0, 0, 0, 0.07);
  z-index: 2;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.resumenActivo {
  height: auto !important;
  padding: 20px 15px;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.containerResumen::after {
  content: "";
  position: absolute;
  height: 44px;
  width: 44px;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  border-radius: 100%;
  background: #fff;
  box-shadow: 0 -10px 8px rgba(0, 0, 0, 0.07);
}

.containerResumen::before {
  content: "";
  position: absolute;
  height: 20px;
  width: 100px;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  background: #fff;
}

.botonResumen {
  position: absolute;
  top: -12px;
  height: 20px;
  left: 50%;
  transform: translateX(-50%) rotate(0deg);
  z-index: 1;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.rotateArrow {
  transform: translateX(-50%) rotate(180deg);
}

.nueva-resumen {
  width: 100%;
  margin: 0px;
  padding: 30px;
  border-radius: 10px;
  background: #f5f5f5;
  color: var(--negro);
}

.nueva-resumen p {
  font: 1.8rem var(--fontSt);
  font-weight: 700;
}

.DatosIndividuales {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  font: 1.35rem var(--fontSt);
  font-weight: 500;
}

.DatosIndividuales:last-child {
  margin: 0;
}

.DatosPromo{
  display: flex;
  width: 40%;
  flex-wrap: wrap;
  justify-content: right;
}

.DatosPromo span:first-child{
  color: red !important;
  font: 1.2rem var(--fontSt);
  text-decoration: line-through;
}

.DatosPromo span:last-child{
  font-weight:700;
  width: 100%;
  text-align: right;
}

.span-rigth {
  text-align: right;
}

.DatosIndividuales label {
  display: block;
  font: 1.2rem var(--fontSt);
  font-weight: 400;
}

.valorRecibido {
  font-weight: 700;
}

.valorNegativo {
  color: #ea2a34;
  font-weight: 700;
}

.TotalPagar {
  font-weight: 700;
}

.totales {
  position: relative;
  margin: 0px 0px 15px 0px;
  padding: 15px 20px;
  border-radius: 10px;
  background-color: var(--azul-cl);
  color: var(--azul-st);
  font: 1.6rem var(--fontSt);
  font-weight: 700;
}

.totales span {
  display: block;
}

.totales span:first-child {
  font: 1.4rem var(--fontSt);
  font-weight: 500;
}

.negativo {
  color: #ea2a34 !important;
  background-color: #fdeeee;
}

.valorMinimo{
  position: absolute;
  bottom: 5px;
  right: 15px;
  color: #ea2a34 !important;
  font: 1.2rem var(--fontSt);
  font-weight: 500;
}

.Cuentas {
  width: 100%;
  margin: 10px 0 0 0;
  padding: 30px;
  border-radius: 10px;
  background-color: var(--azul-cl);
  color: var(--azul-st);
}

.Cuentas p {
  font: 1.4rem var(--fontSt);
  font-weight: 500;
}

.Cuentas p span {
  margin-right: 5px;
  font-weight: 700;
}

.CuentasBanco {
  font: 2rem var(--fontSt) !important;
  font-weight: 700 !important;
}
.CuentasTipo {
  margin-bottom: 15px;
  font: 1.6rem var(--fontSt) !important;
  font-weight: 500 !important;
}

/*RECOMENDACIONES*/

.Recomendacion{
  width: 100%;
  margin: 0px;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 236, 0, .15);
  font: 1.4rem var(--fontSt);
  font-weight: 400;
  cursor: pointer;
}

.Recomendacion img{
  margin-right: 10px;
  width: 30px;
}

.Recomendacion span{
  font: 1.6rem var(--fontSt);
  font-weight: 700;
}

.Recomendacion p{
  margin-top: 5px;
}

.Recomendacion:hover{
  background: rgba(255, 236, 0, .6);
}

/*REFERIDO*/

.DivReferido{
  width: 100%;
  margin-top: 15px;
  padding: 30px 20px 20px 20px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  font: 1.4rem var(--fontSt);
  font-weight: 400;
}

.DivReferido p{
  margin-bottom: 10px;
}

.checkCode{
  position: absolute;
  height: 20px;
  width: 20px;
  top: 8px;
  right: 15px;
  filter: invert(55%) sepia(93%) saturate(411%) hue-rotate(82deg) brightness(85%) contrast(97%);
  pointer-events: none;
  z-index: 1;
}

.inputDisabled{
  position: relative;
  height: 40px;
  width: 100%;
  padding: 6px 12px 6px 12px;
  margin: 15px 0px;
  border-radius: 5px;
  background: #f7f7f7;
  color: #0e0e0e !important;
  border: solid 1px var(--gris-lg);
  font: 1.6rem var(--fontSt);
  font-weight: 400;
  display: flex;
  align-items: center;
}

.inputDisabled label{
  position: absolute;
  top: 10px;
  left: -3px;
  margin-left: 10px;
  padding: 0px 6px;
  background: #f7f7f7;
  border-radius: 3px 3px 0px 0px;
  -webkit-transform: translate3d(0, -18px, 0);
  transform: translate3d(0, -18px, 0);
  color: var(--gris);
  font: 1.3rem var(--fontSt);
  font-weight: 600;
  pointer-events: none;
  z-index: 2;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.listaAzul{
  color: var(--azul-lg) !important;
}


@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .containerResumen {
    position: relative;
    height: auto;
    bottom: inherit;
    padding: 0px;
    background: transparent;
    box-shadow: none;
  }

  .resumenActivo {
    padding: 0px;
  }

  .containerResumen::after,
  .containerResumen::before {
    display: none;
  }

  .botonResumen {
    display: none;
  }

  .totales {
    margin: 10px 0;
    padding: 20px 30px;
  }
}

@media (min-width: 992px) {
  .CardButtonsDiv:last-child {
    margin: 0px 0px 0px 0px;
  }
}

@media (min-width: 1200px) {
}
