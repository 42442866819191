@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@200;400;500;700;900&display=swap");

/* @font-face {
  font-family: "Barlow";
  src: url("./fonts/Barlow-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: "black";
}

@font-face {
  font-family: "Barlow";
  src: url("./fonts/Barlow-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: "bold";
}

@font-face {
  font-family: "Barlow";
  src: url("./fonts/Barlow-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: "light";
}

@font-face {
  font-family: "Barlow";
  src: url("./fonts/Barlow-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: "medium";
}

@font-face {
  font-family: "Barlow";
  src: url("./fonts/Barlow-Regular.ttf") format("truetype");
  font-weight: 400;
} */

html {
  height: 100%;
  font-size: 62.5%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--fontSt);
}

button {
  cursor: pointer;
  border: none;
}

button:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

a,
a:hover,
a:visited {
  text-decoration: none !important;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

p {
  padding: 0;
  margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/*--LOADING--*/
.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.pageLoader {
  height: 100vh !important;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid var(--azul-lg);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/*WHATSAPP*/

.whatsapp {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 44px;
  padding: 10px 10px 10px 16px;
  margin: 0px 0px 70px 0px;
  border-radius: 0 22px 22px 0;
  background-color: #00bb2d;
  z-index: 99;
}

.whatsapp img {
  width: 100%;
  height: 100%;
  filter: invert(1);
}

/*VIDEOS*/

.video-responsive{
  height: 0;
  width: 100%;
  max-width: 1000px;
  overflow: hidden;
  padding-bottom: 280px;
  padding-top: 30px;
  margin: 5px auto 5px auto;
  position: relative;
}

.video-responsive iframe, 
.video-responsive object, 
.video-responsive embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/*AUSTRAC*/

.austrac{
  display: none;
}

@media (min-width: 768px) {
  /*WHATSAPP*/
  .whatsapp {
    left: inherit;
    right: 0;
    width: 50px;
    height: 50px;
    padding: 12px;
    margin: 0px 20px 20px 0;
    border-radius: 25px;
  }

  /*VIDEOS*/
  .video-responsive{
    padding-bottom: 300px;
  }

}


@media (min-width: 992px) {

  .video-responsive{
    margin: 24px auto 20px auto;
    padding-bottom: 500px;
  }

  .austrac{
    position: fixed;
    bottom: 140px;
    left: -110px;
    width: 270px;
    padding: 10px 20px 10px 20px;
    border-radius: 0 0px 5px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 30px 0px;
    transform: rotate(-90deg);
    background-color: #FFFFFF;
    color: #2e2e2e;
    font: 0.9rem var(--fontSt);
    font-weight: 700;
    text-transform: uppercase;
    z-index: 99;
  }

  .austrac img{
    height: 32px;
    margin-left: 20px;
  }
  
}
